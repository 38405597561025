import './NotFound.css';
 
function NotFound() {
  return (
    <div className='not-found'>
       404
    </div>
  );
}
 
export default NotFound