import { Link } from 'react-router-dom';
import './StoryCard.css';
import {
  READER_MAIN_LINK,
  CATALOG_PAGE_STORIES_LINK,
} from '../../../../../assets/utils/constants';
import { useImageLoader } from '../../../../../assets/hooks/useImageLoader';

function StoryCard({ data, onClose }) {
  const imgUrl = data?.meta?.cover?.urls?.orig;
  const { isImgLoading } = useImageLoader(imgUrl);

  return (
    <Link
      to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${data?._id}`}
      className="story-card"
      onClick={onClose}
      state={{ disableScrollToTop: false }}
    >
      <img
        className={`story-card__image ${isImgLoading ? 'skeleton-loader' : ''}`}
        src={imgUrl}
        alt={data?.meta?.cover?.description ?? data?.title}
        loading="lazy"
      />
      <div className="story-card__info">
        <p className="story-card__subtitle">
          {data?.author?.personal_data?.name}
        </p>
        <p className="story-card__title">{data?.title}</p>
      </div>
    </Link>
  );
}

export default StoryCard;
