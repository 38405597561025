import { useContext } from 'react';
import { TranslationContext } from '../contexts/translationContext';

export default function useParseApiError() {
  const { apiErrors } = useContext(TranslationContext);

  function parseApiError(error) {
    if (!error || !error.statusCode) {
      return apiErrors.unknownError;
    }

    switch (error.statusCode) {
      case 400:
        return apiErrors.invalidRequest;
      case 401:
        return apiErrors.unauthorized;
      case 403:
        return apiErrors.forbidden;
      case 404:
        return apiErrors.notFound;
      case 409:
        return apiErrors.conflict;
      case 429:
        return apiErrors.tooManyRequests;
      case 500:
        return apiErrors.serverError;
      case 502:
        return apiErrors.gatewayError;
      case 503:
        return apiErrors.serviceUnavailable;
      case 504:
        return apiErrors.requestTimeout;

      default:
        // Generic error messages for different ranges of status codes
        if (error.statusCode >= 400 && error.statusCode < 500) {
          return apiErrors.clientError;
        }
        if (error.statusCode >= 500) {
          return apiErrors.serverError;
        }
        return apiErrors.unknownError;
    }
  }

  return { parseApiError };
}
