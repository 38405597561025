import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import './ContentTypesNav.css';
import { TAP_BTN_SCALE } from '../../../assets/utils/constants';
import { DataContext } from '../../../assets/contexts/dataContext';

function ContentTypesNav({ data, baseLink, setDataType }) {
  const { lang } = useContext(DataContext);

  return (
    <nav className="content-types-nav">
      <ul className="content-types-nav__list">
        {data.map((item) => (
          <motion.li
            className="content-types-nav__item"
            key={item.label[lang]}
            whileHover={{ opacity: 0.75 }}
            whileTap={{ scale: TAP_BTN_SCALE }}
            onClick={() => setDataType(item.type)}
          >
            <NavLink
              className={({ isActive }) =>
                `content-types-nav__link ${
                  isActive ? 'content-types-nav__link_active' : ''
                }`
              }
              to={`${baseLink}/${item.path}`}
              state={{ disableScrollToTop: false }}
            >
              {item.label[lang]}
            </NavLink>
          </motion.li>
        ))}
      </ul>
    </nav>
  );
}

export default ContentTypesNav;
