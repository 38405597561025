import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { EyeIcon } from '../../../assets/icons/icons';
import AuthButton from '../AuthButton/AuthButton';
import './AuthFormWrapper.css';
import {
  AUTH_LOGIN_TYPE,
  AUTH_RECOVERY_LINK,
  AUTH_RECOVERY_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_TYPE,
  AUTH_SIGNUP_TYPE,
  BASE_TIMING_FUNCTION,
  CONFIRM_EMAIL_POPUP_TYPE,
  POPUP_TRANSITION_DURATION,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import google from '../../../assets/icons/google-icon.png';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function AuthFormWrapper({
  children,
  title,
  subtitle,
  id,
  onSubmit,
  buttonText,
  linkText,
  link,
  isFormValid,
  isPreloader,
  error,
  showError,
  setIsPreloader,
  redirectUrl,
  step,
  successText,
}) {
  const {
    auth: {
      recoveryPassword: { redirectText },
    },
  } = useContext(TranslationContext);
  const location = useLocation();
  const { state, pathname } = location;
  const background = state?.background
    ? state.background
    : { ...location, pathname: '/' };

  return (
    <AnimatePresence mode="popLayout">
      <motion.div
        className="auth-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={pathname}
        transition={{
          duration: POPUP_TRANSITION_DURATION,
          ease: BASE_TIMING_FUNCTION,
        }}
      >
        {title && (
          <div className="auth-wrapper__heading">
            <div
              className={`auth-wrapper__title-block ${
                id === CONFIRM_EMAIL_POPUP_TYPE
                  ? 'auth-wrapper__title-block_align_center'
                  : ''
              }`}
            >
              <div
                className={`auth-wrapper__logo ${
                  id === AUTH_SIGNUP_ONE_MORE_STEP_TYPE
                    ? 'auth-wrapper__logo_type_google'
                    : ''
                }`}
              >
                {id === AUTH_SIGNUP_ONE_MORE_STEP_TYPE ? (
                  <img
                    className="auth-wrapper__google-icon"
                    src={google}
                    alt=""
                  />
                ) : (
                  <EyeIcon
                    mainClassName="auth-wrapper__logo-icon"
                    fillClassName="auth-wrapper__logo-icon-fill"
                  />
                )}
              </div>
              <h3
                className={`auth-wrapper__title ${
                  id === AUTH_SIGNUP_ONE_MORE_STEP_TYPE
                    ? 'auth-wrapper__title_size_small'
                    : ''
                }`}
              >
                {title}
              </h3>
            </div>
            {subtitle && (
              <p
                className={`auth-wrapper__subtitle ${
                  id === CONFIRM_EMAIL_POPUP_TYPE
                    ? 'auth-wrapper__subtitle_size_small'
                    : ''
                }`}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </div>
        )}

        <AnimatePresence mode="wait" initial={false}>
          {children && (id !== AUTH_RECOVERY_TYPE || step === 1) && (
            <motion.form
              className="auth-wrapper__form"
              id={id}
              onSubmit={onSubmit}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={id}
            >
              {children}
            </motion.form>
          )}

          {step === 2 && (
            <motion.p
              className="auth-wrapper__subtitle auth-wrapper__subtitle_size_small"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={`${id}-success-text`}
            >
              {successText}
            </motion.p>
          )}
        </AnimatePresence>

        <ErrorMessage error={error} isApiError />

        <AuthButton
          label={buttonText}
          formId={id}
          onClick={onSubmit}
          isDisabled={!isFormValid}
          isPreloader={isPreloader}
          isTypeSubmit={id !== CONFIRM_EMAIL_POPUP_TYPE}
          isColorsInverted={id === CONFIRM_EMAIL_POPUP_TYPE}
        />

        {id === AUTH_LOGIN_TYPE && (
          <Link
            className="auth-wrapper__link"
            to={AUTH_RECOVERY_LINK}
            state={{ background }}
          >
            <motion.span
              className="auth-wrapper__link-text"
              whileHover={{ opacity: 0.8 }}
              whileTap={{ scale: TAP_BTN_SCALE }}
            >
              {redirectText}
            </motion.span>
          </Link>
        )}

        {link && (
          <Link className="auth-wrapper__link" to={link} state={{ background }}>
            <motion.span
              className="auth-wrapper__link-text"
              whileHover={{ opacity: 0.8 }}
              whileTap={{ scale: TAP_BTN_SCALE }}
            >
              {linkText}
            </motion.span>
          </Link>
        )}

        {(id === AUTH_LOGIN_TYPE || id === AUTH_SIGNUP_TYPE) && (
          <GoogleLoginButton
            setIsPreloader={setIsPreloader}
            showError={showError}
            redirectUrl={redirectUrl}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default AuthFormWrapper;
