import { useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import validator from 'validator';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { UserContext } from '../../../assets/contexts/userContext';
import AuthFormWrapper from '../AuthFormWrapper/AuthFormWrapper';
import './Register.css';
import AuthInput from '../AuthInput/AuthInput';
import {
  AGE_VERIFIED,
  AUTH_LOGIN_LINK,
  AUTH_POPUP_TYPE,
  AUTH_SIGNUP_TYPE,
  AUTH_SIGNUP_LINK,
  PASSWORD_MIN_LENGTH,
  PREFERENCES_POPUP_TYPE,
  PASSWORD_MAX_LENGTH,
} from '../../../assets/utils/constants';
import { DateIcon } from '../../../assets/icons/icons';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import { PopupContext } from '../../../assets/contexts/popupContex';
import mainApi from '../../../assets/api/MainApi';

const initialValues = {
  userName: '',
  date: '',
  email: '',
  password: '',
};

const initialValuesValidity = {
  userName: { validState: false, errorMessage: '' },
  date: { validState: false, errorMessage: '' },
  email: { validState: false, errorMessage: '' },
  password: { validState: false, errorMessage: '' },
};

function Register() {
  const {
    auth: {
      register: { title, buttonText, linkText },
    },
    labels: { name, dateOfBirth, email, password },
    errors: {
      invalidEmail,
      passwordTooShort,
      passwordTooLong,
      fieldRequired,
      invalidDateValue,
      invalidDateMin,
      invalidDateMax,
    },
    apiErrors: { emailAlreadyExists },
  } = useContext(TranslationContext);
  const { setUser } = useContext(UserContext);
  const { handlePopupClose, handlePopupOpen } = useContext(PopupContext);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const date_now = moment();
  const max_date = moment().subtract(18, 'years');
  const min_date = moment('1900-01-01', 'YYYY-MM-DD');
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    setIsFormValid(
      Object.values(valuesValidity).every(({ validState }) => validState)
    );
  }, [valuesValidity]);

  function handleChange(e) {
    const { name, value, name_key } = e.target;

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity({
            ...valuesValidity,
            [name]: { validState: false, errorMessage: '' },
          });
        }

        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity({
              ...valuesValidity,
              [name]: { validState: true, errorMessage: '' },
            });
          } else {
            setValuesValidity({
              ...valuesValidity,
              [name]: {
                validState: false,
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : invalidEmail,
              },
            });
          }
        }

        setValues({ ...values, [name]: value });
        break;

      case 'password':
        if (!value) {
          setValuesValidity({
            ...valuesValidity,
            [name]: { validState: false, errorMessage: fieldRequired },
          });
        }
        if (value.length < PASSWORD_MIN_LENGTH) {
          setValuesValidity({
            ...valuesValidity,
            [name]: {
              validState: false,
              errorMessage: passwordTooShort,
            },
          });
        } else if (value.length > PASSWORD_MAX_LENGTH) {
          setValuesValidity({
            ...valuesValidity,
            [name]: {
              validState: false,
              errorMessage: passwordTooLong,
            },
          });
        } else {
          setValuesValidity({
            ...valuesValidity,
            [name]: {
              validState: true,
              errorMessage: '',
            },
          });
        }
        setValues({ ...values, [name]: value });
        break;

      case 'date':
        let dateValue = value.replace(/\D/g, '');
        let formattedDateValue = '';

        if (!dateValue) {
          setValues((prevValue) => ({
            ...prevValue,
            [name]: '',
          }));
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: fieldRequired,
              validState: false,
            },
          }));
        } else {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: '',
              validState: false,
            },
          }));

          formattedDateValue += dateValue.substring(0, 2);
          if (dateValue.length >= 3) {
            formattedDateValue += '.' + dateValue.substring(2, 4);
          }
          if (dateValue.length >= 5) {
            formattedDateValue += '.' + dateValue.substring(4, 8);
          }
          if (dateValue.length >= 8) {
            const selected_date = moment(formattedDateValue, 'DD.MM.YYYY');

            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: selected_date.isValid()
                ? selected_date.diff(date_now, 'seconds') >= 10
                  ? {
                      errorMessage: invalidDateValue,
                      validState: false,
                    }
                  : selected_date.diff(max_date, 'seconds') <= 10
                  ? selected_date.diff(min_date, 'seconds') >= 10
                    ? {
                        errorMessage: '',
                        validState: true,
                      }
                    : {
                        errorMessage: invalidDateMin,
                        validState: false,
                      }
                  : {
                      errorMessage: invalidDateMax,
                      validState: false,
                    }
                : {
                    errorMessage: invalidDateValue,
                    validState: false,
                  },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: false,
              },
            }));
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedDateValue,
          }));
        }
        break;

      case 'mobile-date':
        const selected_date = moment(value);

        setValues((prevValue) => ({
          ...prevValue,
          [name_key]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name_key]: selected_date.isValid()
            ? selected_date.diff(date_now, 'seconds') >= 10
              ? {
                  errorMessage: invalidDateValue,
                  validState: false,
                }
              : selected_date.diff(max_date, 'seconds') <= 10
              ? selected_date.diff(min_date, 'seconds') >= 10
                ? {
                    errorMessage: '',
                    validState: true,
                  }
                : {
                    errorMessage: invalidDateMin,
                    validState: false,
                  }
              : {
                  errorMessage: invalidDateMax,
                  validState: false,
                }
            : {
                errorMessage: invalidDateValue,
                validState: false,
              },
        }));
        break;

      default:
        setValues({ ...values, [name]: value });
        setValuesValidity({
          ...valuesValidity,
          [name]: {
            validState: Boolean(value),
            errorMessage: Boolean(value) ? '' : fieldRequired,
          },
        });
        break;
    }
  }

  function signup(token) {
    mainApi
      .signup({
        email: values.email,
        password: values.password,
        name: values.userName,
        date_of_birth: moment(values.date, 'DD.MM.YYYY').isValid()
          ? moment(values.date, 'DD.MM.YYYY').format('YYYY-MM-DD')
          : values.date,
        csrf_cookie_value: token?.csrf_token || null,
      })
      .then((res) => {
        setUser(res);
        localStorage.setItem(AGE_VERIFIED, true);
        handlePopupClose(AUTH_POPUP_TYPE);
        handlePopupOpen(PREFERENCES_POPUP_TYPE);
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 409:
            showError(emailAlreadyExists);
            break;

          default:
            showError(parseApiError(err));
            break;
        }
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsPreloader(true);
    mainApi
      .generateCSRF()
      .then((token) => {
        signup(token);
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 404:
            signup();
            break;

          default:
            showError(parseApiError(err));
            setIsPreloader(false);
        }
      });
  }

  return (
    <AuthFormWrapper
      title={title}
      buttonText={buttonText}
      linkText={linkText}
      id={AUTH_SIGNUP_TYPE}
      onSubmit={handleSubmit}
      link={AUTH_LOGIN_LINK}
      isFormValid={isFormValid}
      isPreloader={isPreloader}
      error={error}
      showError={showError}
      setIsPreloader={setIsPreloader}
      redirectUrl={AUTH_SIGNUP_LINK}
    >
      <AuthInput
        label={name}
        name="userName"
        autoComplete="name"
        value={values.userName}
        onChange={handleChange}
        error={valuesValidity.userName}
      />
      {isMobileDevice ? (
        <AuthInput
          type="date"
          label={dateOfBirth}
          name="date"
          autoComplete="bday"
          value={values.date}
          max={moment().format('YYYY-MM-DD')}
          onChange={(e) => {
            handleChange({
              target: {
                value: e.target.value,
                name: 'mobile-date',
                name_key: 'date',
              },
            });
          }}
          icon={DateIcon}
          error={valuesValidity.date}
        />
      ) : (
        <AuthInput
          label={dateOfBirth}
          name="date"
          inputMode="numeric"
          autoComplete="bday"
          value={values.date}
          onChange={handleChange}
          error={valuesValidity.date}
          icon={DateIcon}
        />
      )}
      <AuthInput
        type="email"
        label={email}
        name="email"
        inputMode="email"
        autoComplete="email"
        value={values.email}
        onChange={handleChange}
        error={valuesValidity.email}
      />
      <AuthInput
        type="password"
        label={password}
        name="password"
        autoComplete="new-password"
        value={values.password}
        onChange={handleChange}
        error={valuesValidity.password}
        isPassword
      />
    </AuthFormWrapper>
  );
}

export default Register;
