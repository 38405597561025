import { useContext, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CatalogPageTemplate from '../CatalogPageTemplate/CatalogPageTemplate';
import './StoriesPage.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  CATALOG_DESKTOP_CARDS_NUMBER,
  CATALOG_MOBILE_CARDS_NUMBER,
  CATALOG_TABLET_CARDS_NUMBER,
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  CATEGORIES_TYPE,
  TAGS_TYPE,
  STORIES_TYPE,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
  DATA_SLUG_TYPE_KEY,
} from '../../../assets/utils/constants';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import GalleryCard from '../../MainPage/SwiperGallery/GalleryCard/GalleryCard';
import { DataContext } from '../../../assets/contexts/dataContext';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import mainApi from '../../../assets/api/MainApi';

function StoriesPage({ noParams = false }) {
  const { slug } = useParams();
  const { pathname } = useLocation();
  const isStoriesBySlug = pathname.includes(STORIES_TYPE);
  const isStoriesByCategory = pathname.includes(CATEGORIES_TYPE);
  const isStoriesByTag = pathname.includes(TAGS_TYPE);

  const {
    storiesPage: { titleAll: titleStories, noStories, stories, category, tag },
  } = useContext(TranslationContext);
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const { parseApiError } = useParseApiError();

  const [books, setBooks] = useState(null);
  const [block, setBlock] = useState(null);
  const [title, setTitle] = useState(noParams ? titleStories : '');
  const [hasMore, setHasMore] = useState(false);
  const [apiError, setApiError] = useState(undefined);
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const limit =
    width > DESKTOP_BREAKPOINT
      ? CATALOG_DESKTOP_CARDS_NUMBER
      : width > MOBILE_BREAKPOINT
      ? CATALOG_TABLET_CARDS_NUMBER
      : CATALOG_MOBILE_CARDS_NUMBER;

  useEffect(() => {
    if (!slug || isStoriesBySlug) return;

    if (isStoriesByCategory) {
      getExactCategory();
    } else if (isStoriesByTag) {
      getExactTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, pathname]);

  function getExactCategory() {
    mainApi
      .getExactCategory({ key: DATA_SLUG_TYPE_KEY, value: slug })
      .then((res) => {
        setBlock(res);
        setTitle(`${res.title?.[lang]} ${category} ${stories}`);
      })
      .catch((err) => {
        setApiError(parseApiError(err));
      });
  }

  function getExactTag() {
    mainApi
      .getExactTag({ key: DATA_SLUG_TYPE_KEY, value: slug })
      .then((res) => {
        setBlock(res);
        setTitle(`${res.slug} ${tag} ${stories}`);
      })
      .catch((err) => {
        setApiError(parseApiError(err));
      });
  }

  function getExactStories(preloaderType) {
    const isGetMore = preloaderType === PRELOADER_MORE_TYPE;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .getMainPageExact({
        key: DATA_SLUG_TYPE_KEY,
        value: slug,
        'pagination.cursor':
          isGetMore && block?.pagination?.cursor
            ? block.pagination.cursor
            : null,
        'pagination.data': { skip: books?.length ?? 0, limit },
        input_args:
          isGetMore && block?.pagination?.input_args
            ? block.pagination.input_args
            : null,
      })
      .then((res) => {
        setBlock(res.block);
        setTitle(
          res.block?.frontend_data?.title?.[lang].replace(
            /%v/,
            res.block?.input_args ? '' : ''
          )
        );
        setBooks((prev) => (isGetMore ? prev.concat(res.data) : res.data));
        setHasMore(res.is_more);
      })
      .catch((err) => {
        setApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  function getStories(preloaderType) {
    const last_id = books?.[books.length - 1]?._id;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .getAllStories({
        limit,
        last_id,
        category_id: isStoriesByCategory ? block?._id : null,
        tag: isStoriesByTag ? block?.slug : null,
      })
      .then((res) => {
        setBooks((prev) =>
          preloaderType === PRELOADER_MORE_TYPE
            ? prev.concat(res.data)
            : res.data
        );
        setHasMore(res.is_more);
      })
      .catch((err) => {
        setApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  return (
    <CatalogPageTemplate
      title={title}
      data={books}
      getData={isStoriesBySlug && !noParams ? getExactStories : getStories}
      noDataText={noStories}
      isPreloader={isPreloader}
      hasMore={hasMore}
      apiError={apiError}
      isSmallCards={block?.frontend_data?.is_top}
      hasAllData={isStoriesBySlug || block}
      isStoriesPage
    >
      {books?.map((card, i) => (
        <GalleryCard
          key={card._id}
          card={card}
          index={i}
          isSmallCards={block?.frontend_data?.is_top}
          isTop={block?.frontend_data?.is_top}
          isGrid
        />
      ))}
    </CatalogPageTemplate>
  );
}

export default StoriesPage;
