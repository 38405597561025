import React, { useRef, useEffect, useState, useContext } from 'react';
import {
  TOP_CATEGORIES_TYPE,
  MOBILE_BREAKPOINT,
  TOP_STORIES_TYPE,
  TAGS_TYPE,
} from '../../../../assets/utils/constants';
import Category from '../Category/Category';
import Story from '../Story/Story';
import Tag from '../Tag/Tag';
import './PopularSearchList.css';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { DataContext } from '../../../../assets/contexts/dataContext';

function PopularSearchList({ data, type, onClose }) {
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const isMobileWidth = width <= MOBILE_BREAKPOINT;
  const listRef = useRef(null);
  const [visibleData, setVisibleData] = useState([]);

  useEffect(() => {
    const lines = Array.from({ length: isMobileWidth ? 2 : 1 }, (_, i) => i);
    if (data?.length === 0 || !listRef?.current) return;

    if (type === TOP_STORIES_TYPE) {
      const containerWidth = listRef.current.clientWidth;
      const columns = containerWidth ? Math.floor(containerWidth / 220) : 0;
      setVisibleData(
        width > 540 ? [data.slice(0, columns)] : [data.slice(0, 3)]
      );
    } else {
      const padding = isMobileWidth ? (type === TAGS_TYPE ? 15 : 22) : 22;

      const updateVisibleLinks = () => {
        const dataToShow = [];
        let list = [...data];

        lines.forEach(() => {
          if (listRef?.current) {
            const maxAllowedWidth = listRef.current.offsetWidth;
            if (maxAllowedWidth <= 0) return;
            let totalWidth = 0;
            const listToShow = [];

            list.forEach((item) => {
              const itemElement = document.createElement('div');
              itemElement.className =
                type === TAGS_TYPE
                  ? 'search-dropdown-tag__text'
                  : 'search-dropdown-category__text';
              itemElement.style.visibility = 'hidden';
              itemElement.style.position = 'absolute';
              itemElement.innerText =
                type === TAGS_TYPE ? item.slug : item.title?.[lang];
              document.body.appendChild(itemElement);
              const itemWidth = itemElement.offsetWidth + padding;
              document.body.removeChild(itemElement);

              if (totalWidth + itemWidth <= maxAllowedWidth) {
                listToShow.push(item);
                totalWidth += itemWidth;
              }
            });

            dataToShow.push(listToShow);
            list = list.filter((item) =>
              listToShow.every((c) => c._id !== item._id)
            );
          }
        });

        setVisibleData(dataToShow);
      };

      updateVisibleLinks();

      window.addEventListener('resize', updateVisibleLinks);
      return () => window.removeEventListener('resize', updateVisibleLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width]);

  return (
    <div
      className={`popular-search-list ${
        type === TAGS_TYPE ? 'popular-search-list_type_tag' : ''
      }`}
      ref={listRef}
    >
      {visibleData?.map(
        (data, i) =>
          data?.length > 0 && (
            <ul
              className={`popular-search-list__container ${
                type === TAGS_TYPE
                  ? 'popular-search-list__container_type_tag'
                  : type === TOP_STORIES_TYPE
                  ? 'popular-search-list__container_type_story'
                  : ''
              }`}
              key={i}
            >
              {data?.map((item) => (
                <React.Fragment key={item._id}>
                  {type === TAGS_TYPE && (
                    <Tag data={item} onClose={onClose} isLink />
                  )}
                  {type === TOP_CATEGORIES_TYPE && (
                    <Category data={item} onClose={onClose} isLink />
                  )}
                  {type === TOP_STORIES_TYPE && (
                    <Story data={item} onClose={onClose} isLink />
                  )}
                </React.Fragment>
              ))}
            </ul>
          )
      )}
    </div>
  );
}

export default PopularSearchList;
