import { useContext } from 'react';
import './Promo.css';
import card1 from '../../../assets/images/promo/card1.png';
import card2 from '../../../assets/images/promo/card2.png';
import card3 from '../../../assets/images/promo/card3.png';
import AiForm from './AiForm/AiForm';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const images = [card1, card2, card3];

function Promo() {
  const {
    mainPage: {
      promo: { title, cards },
    },
  } = useContext(TranslationContext);

  return (
    <section className="promo">
      <div className="promo__container">
        <div className="promo__text-block">
          <h2
            className="promo__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <ul className="promo__cards">
            {cards.map((card, i) => (
              <li className="promo__card" key={card.title}>
                <img className="promo__card-img" src={images[i]} alt="" />
                <div className="promo__text-box">
                  <p
                    className="promo__card-title"
                    dangerouslySetInnerHTML={{ __html: card.title }}
                  />
                  <p
                    className="promo__card-text"
                    dangerouslySetInnerHTML={{ __html: card.text }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="promo__form-block">
          <div className="promo__form-container">
            <AiForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Promo;
