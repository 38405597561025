import { useContext } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Popup from '../Popup/Popup';
import './Auth.css';
import {
  AUTH_LOGIN_TYPE,
  AUTH_POPUP_TYPE,
  AUTH_RECOVERY_TYPE,
  AUTH_RESET_PASSWORD_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_TYPE,
  AUTH_SIGNUP_TYPE,
} from '../../assets/utils/constants';
import Register from './Register/Register';
import Login from './Login/Login';
import RegisterOneMoreStep from './RegisterOneMoreStep/RegisterOneMoreStep';
import { PopupContext } from '../../assets/contexts/popupContex';
import RecoveryPassword from './RecoveryPassword/RecoveryPassword';
import ResetPassword from './ResetPassword/ResetPassword';

function Auth() {
  const location = useLocation();
  const { logout } = useContext(PopupContext);

  function handleLogoutOnClose() {
    if (location.pathname.includes(`${AUTH_SIGNUP_ONE_MORE_STEP_TYPE}`)) {
      logout();
    }
  }

  return (
    <Popup
      popupName={AUTH_POPUP_TYPE}
      onClose={handleLogoutOnClose}
      withCopyright
    >
      <Routes location={location}>
        <Route index element={<Register />} />
        <Route path={`/${AUTH_SIGNUP_TYPE}`} element={<Register />} />
        <Route
          path={`/${AUTH_SIGNUP_ONE_MORE_STEP_TYPE}`}
          element={<RegisterOneMoreStep />}
        />
        <Route path={`/${AUTH_LOGIN_TYPE}`} element={<Login />} />
        <Route path={`/${AUTH_RECOVERY_TYPE}`} element={<RecoveryPassword />} />
        <Route
          path={`/${AUTH_RESET_PASSWORD_TYPE}`}
          element={<ResetPassword />}
        />
      </Routes>
    </Popup>
  );
}

export default Auth;
