import { useContext, useState, useEffect } from 'react';
import validator from 'validator';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { UserContext } from '../../../assets/contexts/userContext';
import AuthFormWrapper from '../AuthFormWrapper/AuthFormWrapper';
import './Login.css';
import AuthInput from '../AuthInput/AuthInput';
import {
  AUTH_LOGIN_TYPE,
  AUTH_POPUP_TYPE,
  AUTH_SIGNUP_LINK,
  AUTH_LOGIN_LINK,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
} from '../../../assets/utils/constants';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import { PopupContext } from '../../../assets/contexts/popupContex';
import mainApi from '../../../assets/api/MainApi';

const initialValues = {
  email: '',
  password: '',
};

const initialValuesValidity = {
  email: { validState: false, errorMessage: '' },
  password: { validState: false, errorMessage: '' },
};

function Login() {
  const {
    auth: {
      login: { title, buttonText, linkText },
    },
    labels: { email, password },
    errors: { invalidEmail, passwordTooShort, passwordTooLong, fieldRequired },
    apiErrors: { wrongEmailOrPassword },
  } = useContext(TranslationContext);
  const { setUser } = useContext(UserContext);
  const { handlePopupClose } = useContext(PopupContext);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    setIsFormValid(
      Object.values(valuesValidity).every(({ validState }) => validState)
    );
  }, [valuesValidity]);

  function handleChange(e) {
    const { name, value } = e.target;

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity({
            ...valuesValidity,
            [name]: { validState: false, errorMessage: '' },
          });
        }

        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity({
              ...valuesValidity,
              [name]: { validState: true, errorMessage: '' },
            });
          } else {
            setValuesValidity({
              ...valuesValidity,
              [name]: {
                validState: false,
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : invalidEmail,
              },
            });
          }
        }

        setValues({ ...values, [name]: value });
        break;

      case 'password':
        setValues({ ...values, [name]: value });
        if (!value) {
          setValuesValidity({
            ...valuesValidity,
            [name]: { validState: false, errorMessage: fieldRequired },
          });
        }
        if (value.length < PASSWORD_MIN_LENGTH) {
          setValuesValidity({
            ...valuesValidity,
            [name]: {
              validState: false,
              errorMessage: passwordTooShort,
            },
          });
        } else if (value.length > PASSWORD_MAX_LENGTH) {
          setValuesValidity({
            ...valuesValidity,
            [name]: {
              validState: false,
              errorMessage: passwordTooLong,
            },
          });
        } else {
          setValuesValidity({
            ...valuesValidity,
            [name]: {
              validState: true,
              errorMessage: '',
            },
          });
        }
        break;

      default:
        setValues({ ...values, [name]: value });
        setValuesValidity({
          ...valuesValidity,
          [name]: { validState: Boolean(value), errorMessage: '' },
        });
        break;
    }
  }

  function login(token) {
    mainApi
      .login({
        email: values.email,
        password: values.password,
        csrf_cookie_value: token?.csrf_token || null,
      })
      .then((res) => {
        setUser(res);
        handlePopupClose(AUTH_POPUP_TYPE, true, true);
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 401:
            showError(wrongEmailOrPassword);
            break;

          default:
            showError(parseApiError(err));
            break;
        }
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsPreloader(true);
    mainApi
      .generateCSRF()
      .then((token) => {
        login(token);
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 404:
            login();
            break;

          default:
            showError(parseApiError(err));
            setIsPreloader(false);
        }
      });
  }

  return (
    <AuthFormWrapper
      title={title}
      buttonText={buttonText}
      linkText={linkText}
      id={AUTH_LOGIN_TYPE}
      onSubmit={handleSubmit}
      link={AUTH_SIGNUP_LINK}
      isFormValid={isFormValid}
      isPreloader={isPreloader}
      setIsPreloader={setIsPreloader}
      error={error}
      showError={showError}
      redirectUrl={AUTH_LOGIN_LINK}
    >
      <AuthInput
        type="email"
        label={email}
        name="email"
        inputMode="email"
        autoComplete="email"
        value={values.email}
        onChange={handleChange}
        error={valuesValidity.email}
      />
      <AuthInput
        type="password"
        label={password}
        name="password"
        autoComplete="current-password"
        value={values.password}
        onChange={handleChange}
        error={valuesValidity.password}
        isPassword
      />
    </AuthFormWrapper>
  );
}

export default Login;
