import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HeartIcon, CommentIcon } from '../../../assets/icons/icons';
import Pagination from '../../Pagination/Pagination';
import './ReaderControls.css';
import {
  AUTH_LOGIN_LINK,
  AUTH_POPUP_TYPE,
  BASE_TIMING_FUNCTION,
  COMMENTS_POPUP_TYPE,
  TAP_BTN_SCALE,
  AI_LINK,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
  CONFIRM_POPUP_TYPE,
} from '../../../assets/utils/constants';
import { useScrollPosition } from '../../../assets/hooks/useScrollPosition';
import { PopupContext } from '../../../assets/contexts/popupContex';
import { UserContext } from '../../../assets/contexts/userContext';

const hoverBtnAnimation = { scale: 1.03 };
const tapBtnAnimation = { scale: TAP_BTN_SCALE };

function ReaderControls({
  isLiked,
  onLikeClick,
  isCommentsAvailable,
  ...props
}) {
  const { user } = useContext(UserContext);
  const { isPopupOpen, handlePopupOpen } = useContext(PopupContext);
  const { isPageBottom } = useScrollPosition(
    Object.values(isPopupOpen).some(Boolean)
  );
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);

  const handleCommentsBtnClick = () => {
    if (!user) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
    } else if (!user?.reg_data?.stages?.is_email_verified) {
      handlePopupOpen(CONFIRM_POPUP_TYPE);
    } else if (!user?.reg_data?.stages?.is_all_auth_data_provided) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_SIGNUP_ONE_MORE_STEP_LINK);
    } else {
      handlePopupOpen(COMMENTS_POPUP_TYPE);
    }
  };

  return (
    <div className="reader-controls">
      <Pagination {...props} />

      {!isAiStories && (
        <div className="reader-controls__btns">
          <motion.button
            className="reader-controls__btn"
            type="button"
            onClick={onLikeClick}
            whileHover={hoverBtnAnimation}
            whileTap={tapBtnAnimation}
            animate={{
              backgroundColor: isLiked
                ? 'var(--main-active-primary)'
                : 'var(--neutral-bg-primary)',
            }}
            transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
          >
            <HeartIcon
              mainClassName="reader-controls__heart-icon"
              fillClassName={`reader-controls__icon-fill ${
                isLiked ? 'reader-controls__icon-fill_active' : ''
              }`}
            />
          </motion.button>

          {isCommentsAvailable && (
            <motion.button
              className="reader-controls__btn"
              type="button"
              onClick={handleCommentsBtnClick}
              whileHover={hoverBtnAnimation}
              whileTap={tapBtnAnimation}
            >
              <CommentIcon
                mainClassName="reader-controls__comment-icon"
                fillClassName="reader-controls__icon-fill"
              />
            </motion.button>
          )}
        </div>
      )}

      <motion.div
        className="reader-controls__gradient"
        animate={{ opacity: isPageBottom ? 0 : 1 }}
      />
    </div>
  );
}

export default ReaderControls;
