import { useEffect, useState } from 'react';

export const useScrollPosition = (isPopupOpen) => {
  const [isPageBottom, setIsPageBottom] = useState(false);
  const [isPageTop, setIsPageTop] = useState(false);

  useEffect(() => {
    if (isPopupOpen) return;

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // Check if we're at the bottom
      // -10px threshold
      setIsPageBottom(windowHeight + scrollTop >= documentHeight - 10);

      // -50px threshold
      setIsPageTop(scrollTop <= 50);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPopupOpen]);

  return { isPageBottom, isPageTop };
};
