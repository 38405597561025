import './AvatarBox.css';
import { useImageLoader } from '../../assets/hooks/useImageLoader';

function AvatarBox({ data = {}, className }) {
  const { avatar, default_avatar, name, username } = data;
  const image = avatar?.urls?.orig ?? default_avatar?.urls?.orig;
  const alt = name ?? username;
  const { isImgLoading } = useImageLoader(image);

  return (
    <div
      className={`avatar ${isImgLoading ? 'skeleton-loader' : ''} ${
        className ?? ''
      }`}
    >
      {image && (
        <img className="avatar__img" src={image} alt={alt} loading="lazy" />
      )}
    </div>
  );
}

export default AvatarBox;
