import { useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import AuthInput from '../../../Auth/AuthInput/AuthInput';
import './PasswordForm.css';
import {
  MESSAGE_POPUP_TYPE,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import AuthButton from '../../../Auth/AuthButton/AuthButton';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../../assets/hooks/useParseApiError';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import mainApi from '../../../../assets/api/MainApi';
import SuccessMessage from '../../../SuccessMessage/SuccessMessage';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import MessagePopup from '../../../MessagePopup/MessagePopup';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PopupContext } from '../../../../assets/contexts/popupContex';

const initialValues = {
  currentPassword: '',
  newPassword: '',
};

const initialValuesValidity = {
  currentPassword: { validState: false, errorMessage: '' },
  newPassword: { validState: false, errorMessage: '' },
};

function PasswordForm() {
  const {
    labels: { currentPassword, newPassword, changePassword },
    errors: {
      passwordTooShort,
      passwordTooLong,
      fieldRequired,
      wrongCurrentPassword,
      passwordNotSet,
    },
    successMessages: { passwordChangeSuccess },
    auth: {
      recoveryPassword: { successText: resetPasswordText },
      resetPassword: { title: resetPasswordTitle },
    },
  } = useContext(TranslationContext);
  const { user, setUser } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isPreloader, setIsPreloader] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, showError] = useAutoDismissError();
  const [resetPasswordError, showResetPasswordError] = useAutoDismissError();
  const [success, showSuccess] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    setIsFormValid(
      Object.values(valuesValidity).every(({ validState }) => validState)
    );
  }, [valuesValidity]);

  function handleChange(e) {
    const { name, value } = e.target;
    setValues((prevValue) => ({ ...prevValue, [name]: value }));

    if (!value) {
      setValuesValidity((prevValue) => ({
        ...prevValue,
        [name]: {
          validState: false,
          errorMessage: fieldRequired,
        },
      }));
    }

    if (value.length < PASSWORD_MIN_LENGTH) {
      setValuesValidity({
        ...valuesValidity,
        [name]: {
          validState: false,
          errorMessage: passwordTooShort,
        },
      });
    } else if (value.length > PASSWORD_MAX_LENGTH) {
      setValuesValidity({
        ...valuesValidity,
        [name]: {
          validState: false,
          errorMessage: passwordTooLong,
        },
      });
    } else {
      setValuesValidity({
        ...valuesValidity,
        [name]: {
          validState: true,
          errorMessage: '',
        },
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsPreloader((prev) => ({ ...prev, updatePassword: true }));
    mainApi
      .updatePassword({
        current_password: values.currentPassword,
        new_password: values.newPassword,
      })
      .then(() => {
        showSuccess(passwordChangeSuccess);
        setValues(initialValues);
        setValuesValidity(initialValuesValidity);
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 400 && err.detail === 'Wrong current password':
            showError(wrongCurrentPassword);
            break;

          case 400 && err.detail === 'Password is not set':
            showError(passwordNotSet);
            break;

          default:
            showError(parseApiError(err));
            break;
        }
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, updatePassword: false }));
      });
  }

  function resetPassword() {
    setIsPreloader((prev) => ({ ...prev, resetPassword: true }));
    mainApi
      .resetPasswordWithAuth()
      .then((res) => {
        setUser(res);
        handlePopupOpen(MESSAGE_POPUP_TYPE);
      })
      .catch((err) => {
        showResetPasswordError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, resetPassword: false }));
      });
  }

  return (
    <>
      <form
        className="password-form"
        id="password-form"
        onSubmit={handleSubmit}
      >
        {user?.reg_data?.is_password_set && (
          <>
            <AuthInput
              type="password"
              label={currentPassword}
              name="currentPassword"
              autoComplete="current-password"
              value={values.currentPassword}
              onChange={handleChange}
              error={valuesValidity.currentPassword}
              isPassword
            />

            <AuthInput
              type="password"
              label={newPassword}
              name="newPassword"
              autoComplete="new-password"
              value={values.newPassword}
              onChange={handleChange}
              error={valuesValidity.newPassword}
              isPassword
            />

            <ErrorMessage error={error} isApiError />
            <SuccessMessage message={success} />

            <AuthButton
              label={changePassword}
              isPreloader={isPreloader.updatePassword}
              isDisabled={!isFormValid}
              onClick={handleSubmit}
              formId="password-form"
              isTypeSubmit
            />
          </>
        )}

        <motion.button
          className="password-form__button"
          type="button"
          onClick={resetPassword}
          initial={{ backgroundColor: 'var(--website-background-theme)' }}
          whileHover={{ backgroundColor: 'var(--tag-color-primary)' }}
          whileTap={{ scale: TAP_BTN_SCALE }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <AnimatePresence mode="wait" initial={false}>
            <motion.p
              className="password-form__button-text"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={isPreloader.resetPassword ? 'preloader' : 'button'}
            >
              {isPreloader.resetPassword ? (
                <MiniPreloader />
              ) : (
                resetPasswordTitle
              )}
            </motion.p>
          </AnimatePresence>
        </motion.button>

        <ErrorMessage error={resetPasswordError} isApiError />
      </form>

      <MessagePopup title={resetPasswordTitle} text={resetPasswordText} />
    </>
  );
}

export default PasswordForm;
