import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import './GoogleLoginButton.css';
import { UserContext } from '../../../assets/contexts/userContext';
import {
  AI_LINK,
  CATALOG_PAGE_MAIN_LINK,
  OAUTH_GOOGLE_TYPE,
  AUTH_POPUP_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
} from '../../../assets/utils/constants';
import mainApi from '../../../assets/api/MainApi';
import { PopupContext } from '../../../assets/contexts/popupContex';
import useParseApiError from '../../../assets/hooks/useParseApiError';

function GoogleLoginButton({ setIsPreloader, showError, redirectUrl }) {
  const { setUser } = useContext(UserContext);
  const { handlePopupClose } = useContext(PopupContext);
  const { parseApiError } = useParseApiError();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const background = state?.background
    ? state.background
    : { ...location, pathname: '/' };
  const isAiStories = state?.background?.pathname
    .replace('/', '')
    .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
    .startsWith(AI_LINK);

  function oauthRegister({ credentialResponse, token }) {
    mainApi
      .oauthRegister({
        credential: credentialResponse.credential,
        type: OAUTH_GOOGLE_TYPE,
        csrf_cookie_value: token?.csrf_token || null,
      })
      .then((res) => {
        setUser(res);
        if (res.reg_data.stages.is_all_auth_data_provided) {
          handlePopupClose(AUTH_POPUP_TYPE, true, true);
        } else {
          navigate(AUTH_SIGNUP_ONE_MORE_STEP_LINK, {
            state: { background },
          });
        }
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  const handleSuccess = (credentialResponse) => {
    setIsPreloader(true);
    mainApi
      .generateCSRF()
      .then((token) => {
        oauthRegister({ credentialResponse, token });
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 404:
            oauthRegister({ credentialResponse });
            break;

          default:
            showError(parseApiError(err));
            setIsPreloader(false);
        }
      });
  };

  const handleError = (error) => {
    showError(parseApiError(error));
    handlePopupClose(AUTH_POPUP_TYPE, true, false);
  };

  return (
    <div className="google-login-button">
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
        shape="pill"
        ux_mode="popup"
        theme={isAiStories ? 'filled_blue' : 'outline'}
        login_uri={`${window.location.origin}${redirectUrl}`}
        useOneTap
      />
    </div>
  );
}

export default GoogleLoginButton;
