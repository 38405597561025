import { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import './GalleryCard.css';
import { FillBookmarkIcon } from '../../../../assets/icons/icons';
import { UserContext } from '../../../../assets/contexts/userContext';
import {
  MOBILE_BREAKPOINT,
  READER_MAIN_LINK,
  TABLET_BREAKPOINT,
  AI_LINK,
  BASE_TIMING_FUNCTION,
  CREATOR_LINK,
  TAP_BTN_SCALE,
  AUTH_POPUP_TYPE,
  AUTH_LOGIN_LINK,
  STORIES_API_TYPE,
  CONFIRM_POPUP_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
  CATALOG_PAGE_STORIES_LINK,
  CATALOG_PAGE_MAIN_LINK,
} from '../../../../assets/utils/constants';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import AuthorInfo from '../../AuthorsCards/AuthorInfo/AuthorInfo';
import { PopupContext } from '../../../../assets/contexts/popupContex';
import mainApi from '../../../../assets/api/MainApi';
import { useImageLoader } from '../../../../assets/hooks/useImageLoader';

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function GalleryCard({
  card: {
    _id,
    title,
    meta,
    author,
    stats,
    is_favourite,
    price,
    is_payable,
    utc_datetime,
  },
  index,
  isAi = false,
  isCreatorPage = false,
  isTop = false,
  isSmallCards = false,
  isFavorites = false,
  isGrid = false,
}) {
  const {
    mainPage: {
      gallery: { free },
    },
    aiStories: {
      aiBlock: { caption },
    },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const { width } = useWindowSize();
  const [isLiked, setIsLiked] = useState(user ? is_favourite : false);
  const [visibleTags, setVisibleTags] = useState([]);
  const [storyStats, setStoryStats] = useState(stats);

  const imgUrl = meta?.cover?.urls?.orig;
  const { isImgLoading } = useImageLoader(imgUrl);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const isAiStoriesPage =
    pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK) ||
    state?.background?.pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK);

  const isMobileWidth = width <= MOBILE_BREAKPOINT;
  const isTabletWidth = width <= TABLET_BREAKPOINT;
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  const thirdLineRef = useRef(null);
  const refs = [firstLineRef, secondLineRef, thirdLineRef];
  const visibleRefs = isMobileWidth ? refs : refs.slice(0, 2);

  useEffect(() => {
    if (!meta?.tags || meta?.tags?.length === 0) return;
    const paddings = isTabletWidth ? 9 * 2 : 12 * 2;

    const updateVisibleTags = () => {
      const tagsListToShow = [];
      let data = [...meta?.tags];

      visibleRefs.forEach((ref, i) => {
        if (ref?.current) {
          const maxAllowedWidth = ref.current.offsetWidth;
          if (maxAllowedWidth <= 0) return;
          let currentLineWidth = 0;
          let isLastLine = i === visibleRefs.length - 1;
          const tagsToShow = [];

          data.forEach((tag) => {
            const tagElement = document.createElement('p');
            tagElement.className = 'gallery-card__tag-text';
            tagElement.style.visibility = 'hidden';
            tagElement.style.position = 'absolute';
            tagElement.innerText = `#${tag}`;
            document.body.appendChild(tagElement);
            const tagWidth = tagElement.offsetWidth + paddings;
            document.body.removeChild(tagElement);

            if (currentLineWidth + tagWidth <= maxAllowedWidth) {
              tagsToShow.push({ tag, width: 'unset' });
              currentLineWidth += tagWidth;
            } else {
              const width = maxAllowedWidth - currentLineWidth;
              if (width > paddings + 10 && isLastLine) {
                tagsToShow.push({ tag, width });
              }
              return;
            }
          });

          tagsListToShow.push(tagsToShow);
          data = data.filter((tag) => tagsToShow.every((t) => t.tag !== tag));
        }
      });

      setVisibleTags(tagsListToShow);
    };

    updateVisibleTags();
    window.addEventListener('resize', updateVisibleTags);

    return () => window.removeEventListener('resize', updateVisibleTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, width]);

  function handleLikeClick(evt) {
    evt.preventDefault();
    if (!user) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
    } else if (!user?.reg_data?.stages?.is_email_verified) {
      handlePopupOpen(CONFIRM_POPUP_TYPE);
    } else if (!user?.reg_data?.stages?.is_all_auth_data_provided) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_SIGNUP_ONE_MORE_STEP_LINK);
    } else {
      mainApi
        .setLike({ _id, content_type: STORIES_API_TYPE, value: !isLiked })
        .then(() => {
          setIsLiked(!isLiked);
          setStoryStats((prev) => ({
            ...prev,
            likes: !isLiked ? Number(prev.likes) + 1 : Number(prev.likes) - 1,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function navigateToCreator(evt) {
    evt.preventDefault();
    navigate(`/${CREATOR_LINK}/${author?._id}`);
  }

  return (
    <Link
      className={`gallery-card ${
        isSmallCards ? 'gallery-card_size_small' : ''
      } ${isGrid ? 'gallery-card_type_grid' : ''}`}
      to={`${
        isAi ? `/${AI_LINK}` : `/${CATALOG_PAGE_STORIES_LINK}`
      }/${READER_MAIN_LINK}/${_id}`}
      state={{ disableScrollToTop: false }}
    >
      <motion.div
        className={`gallery-card__container ${
          isSmallCards ? 'gallery-card__container_size_small' : ''
        } ${isAi ? 'gallery-card__container_type_ai' : ''}`}
        initial={{
          borderColor: isAi
            ? 'var(--neutral-bg-opacity-tertiary)'
            : 'var(--neutral-border-primary)',
        }}
        whileHover={{
          borderColor: isAi
            ? meta?.cover?.color
            : isTop || isFavorites
            ? 'var(--main-active-primary)'
            : 'var(--second-active-primary)',
        }}
        transition={{ duration: 0.3, ease: BASE_TIMING_FUNCTION }}
      >
        <div
          className={`gallery-card__image-box ${
            isSmallCards ? 'gallery-card__image-box_size_small' : ''
          }`}
        >
          <div className="gallery-card__image-wrapper">
            <img
              className={`gallery-card__image ${
                isImgLoading
                  ? `skeleton-loader ${
                      isAiStoriesPage ? 'skeleton-loader_theme_dark' : ''
                    }`
                  : ''
              }`}
              src={imgUrl}
              alt={meta?.cover?.description ?? title}
              loading="lazy"
            />
          </div>

          {isTop && (
            <div className="gallery-card__number-box">
              <p className="gallery-card__number">{index + 1}</p>
            </div>
          )}

          {!price && !isAi && (
            <div className="gallery-card__free-box">
              <p className="gallery-card__free-text">{free}</p>
            </div>
          )}
        </div>

        <div
          className={`gallery-card__content ${
            isSmallCards ? 'gallery-card__content_size_small' : ''
          }`}
        >
          <div className="gallery-card__content-heading">
            {isAi || isCreatorPage ? (
              <p
                className={`gallery-card__subtitle ${
                  isSmallCards ? 'gallery-card__subtitle_color_neutral' : ''
                } ${isAi ? 'gallery-card__subtitle_color_ai' : ''}`}
              >
                {isAi ? caption : author?.personal_data?.name}
              </p>
            ) : (
              <button
                className="gallery-card__link"
                type="button"
                onClick={navigateToCreator}
              >
                <motion.p
                  className={`gallery-card__subtitle ${
                    isSmallCards ? 'gallery-card__subtitle_color_neutral' : ''
                  }`}
                  whileHover={{ color: 'var(--main-active-primary)' }}
                  whileTap={btnTapAnimation}
                  transition={{ duration: 0.3, ease: BASE_TIMING_FUNCTION }}
                >
                  {author?.personal_data?.name}
                </motion.p>
              </button>
            )}

            {isAi ? (
              <p className="gallery-card__date">
                {moment(utc_datetime).format('DD.MM.YYYY')}
              </p>
            ) : (
              <button
                className="gallery-card__save-button"
                type="button"
                onClick={handleLikeClick}
              >
                <FillBookmarkIcon
                  mainClassName="gallery-card__save-icon"
                  fillClassName={`gallery-card__save-icon-fill ${
                    isLiked ? 'gallery-card__save-icon-fill_active' : ''
                  }`}
                />
              </button>
            )}
          </div>

          <h6
            className={`gallery-card__title ${
              isSmallCards ? 'gallery-card__title_size_small' : ''
            }`}
          >
            {title}
          </h6>

          {!isAi &&
            (isSmallCards ? (
              <AuthorInfo
                className="gallery-card__info-block"
                stats={storyStats}
                isSwiperCard
              />
            ) : (
              <div className="gallery-card__tags-box">
                {visibleRefs.map((ref, i) => (
                  <ul className="gallery-card__tags-list" ref={ref} key={i}>
                    {visibleTags[i]?.length > 0 &&
                      visibleTags[i].map((tag) => (
                        <li
                          className="gallery-card__tag"
                          key={tag.tag}
                          style={{ width: tag.width }}
                        >
                          <p className="gallery-card__tag-text">
                            <span className="gallery-card__tag-text_type_color">
                              #
                            </span>
                            {tag.tag}
                          </p>
                        </li>
                      ))}
                  </ul>
                ))}
              </div>
            ))}
        </div>

        {isAi && (
          <div
            className="gallery-card__bg"
            style={{ backgroundColor: meta?.cover?.color }}
          />
        )}
      </motion.div>
    </Link>
  );
}

export default GalleryCard;
