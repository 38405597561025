import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMENTS_POPUP_TYPE,
  COMMENTS_DATA_LIMIT,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
  AI_LINK,
} from '../../../assets/utils/constants';
import Popup from '../../Popup/Popup';
import './CommentsDropdown.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import CommentsList from './CommentsList/CommentsList';
import AvatarBox from '../../AvatarBox/AvatarBox';
import { UserContext } from '../../../assets/contexts/userContext';
import CommentForm from './CommentForm/CommentForm';
import mainApi from '../../../assets/api/MainApi';

function CommentsDropdown({ story_id }) {
  const {
    reader: {
      comments: { title, addComment },
    },
  } = useContext(TranslationContext);
  const { user, isUserLoading } = useContext(UserContext);
  const contentRef = useRef(null);
  const [data, setData] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [newCommentIds, setNewCommentIds] = useState([]);
  const [isInputOpen, setIsInputOpen] = useState({});
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);

  useEffect(() => {
    if (isUserLoading || !story_id || isAiStories) return;
    getData({ preloaderType: PRELOADER_MAIN_TYPE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoading, story_id, isAiStories]);

  function getData({ reply_to, preloaderType }) {
    if (isAiStories) return;
    const last_id = data?.[data.length - 1]?._id || null;
    const child_comments = reply_to
      ? data?.find((item) => item._id === reply_to)?.child_comments?.data
      : null;
    const child_last_id = reply_to
      ? child_comments?.[child_comments?.length - 1]?._id
      : null;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .getAllComments({
        _id: story_id,
        limit: COMMENTS_DATA_LIMIT,
        last_id: reply_to ? child_last_id : last_id,
        reply_to,
      })
      .then((res) => {
        setData((prev) =>
          preloaderType.includes(PRELOADER_MORE_TYPE)
            ? reply_to
              ? prev.map((item) =>
                  item._id === reply_to
                    ? {
                        ...item,
                        child_comments: {
                          data: item.child_comments.data.concat(res.data),
                          is_more: res.is_more,
                        },
                      }
                    : item
                )
              : prev.concat(res.data)
            : res.data
        );
        setHasMore(res.is_more);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  function handleInputOpen(id) {
    setIsInputOpen((prev) => ({ ...prev, [id]: true }));
  }

  function handleInputClose(id) {
    setTimeout(() => setIsInputOpen((prev) => ({ ...prev, [id]: false })), 100);
    handleInputFocus(id);
  }

  function handleInputFocus(id) {
    const input = Object.keys(isInputOpen)
      ?.filter((key) => key !== id)
      ?.reverse()
      ?.find((key) => isInputOpen[key]);
    if (input) document.getElementById(`comment-${input}`)?.focus();
  }

  function closeAllInputs() {
    setIsInputOpen({});
  }

  function scrollToComment(id) {
    const comment = document.getElementById(id);
    if (!comment) return;

    const rect = comment.getBoundingClientRect();
    const isInView =
      rect.top >= 0 && rect.bottom <= contentRef.current.clientHeight;

    if (!isInView) {
      comment.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }

  return (
    <Popup
      popupName={COMMENTS_POPUP_TYPE}
      dropdown
      slideFromRight
      fixed
      withCloseBtn
      stopPropagation={Object.values(isInputOpen).some(Boolean)}
      onClose={closeAllInputs}
    >
      <div className="comments-dropdown">
        <h6 className="comments-dropdown__title">{title}</h6>
        <div className="comments-dropdown__content" ref={contentRef}>
          {isPreloader[PRELOADER_MAIN_TYPE] ? (
            <MiniPreloader />
          ) : (
            <CommentsList
              story_id={story_id}
              data={data}
              setComments={setData}
              scrollToComment={scrollToComment}
              isInputOpen={isInputOpen}
              handleInputOpen={handleInputOpen}
              handleInputClose={handleInputClose}
              hasMore={hasMore}
              getData={getData}
              isPreloader={isPreloader}
              newCommentIds={newCommentIds}
              setNewCommentIds={setNewCommentIds}
            />
          )}
        </div>
        <div className="comments-dropdown__input-block">
          <AvatarBox
            className="comments-dropdown__avatar"
            data={user?.personal_data}
          />
          <CommentForm
            story_id={story_id}
            placeholder={addComment}
            setComments={setData}
            scrollToComment={scrollToComment}
            setNewCommentIds={setNewCommentIds}
          />
        </div>
      </div>
    </Popup>
  );
}

export default CommentsDropdown;
