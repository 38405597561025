import { useContext } from 'react';
import moment from 'moment-timezone';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import {
  OpenEyeIcon,
  HeartIcon,
  GemsLogo,
} from '../../../../assets/icons/icons';
import { formatNumber } from '../../../../assets/utils/utils';
import './AuthorInfo.css';

function AuthorInfo({
  className,
  stats,
  is_free,
  price,
  utc_datetime,
  isBig = false,
  isMedium = false,
  isSwiperCard = false,
  withPrice = false,
  withDate = false,
}) {
  const {
    reader: { free, gems },
  } = useContext(TranslationContext);
  const iconWrapperClassName = `author-info__icon-wrapper ${
    isBig ? 'author-info__icon-wrapper_size_big' : ''
  } ${isSwiperCard ? 'author-info__icon-wrapper_size_swiper' : ''}`;
  const textClassName = `author-info__text ${
    isBig ? 'author-info__text_size_big' : ''
  } ${isMedium ? 'author-info__text_size_medium' : ''} ${
    isSwiperCard ? 'author-info__text_size_swiper' : ''
  }`;

  return (
    <div
      className={`author-info ${className ?? ''} ${
        isSwiperCard ? 'author-info_size_swiper' : ''
      }`}
    >
      {((withPrice && price !== null) || is_free) && (
        <>
          {is_free ? (
            <p className={textClassName}>{free}</p>
          ) : (
            <div className="author-info__box">
              <div className={iconWrapperClassName}>
                <GemsLogo
                  mainClassName="author-info__gems-icon"
                  strokeClassName="author-info__gems-icon-stroke"
                />
              </div>
              <p className={textClassName}>
                {price} {gems}
              </p>
            </div>
          )}

          <span className={`${textClassName} author-info__text_weight_thin`}>
            •
          </span>
        </>
      )}

      <div className="author-info__box">
        <div className={iconWrapperClassName}>
          <OpenEyeIcon
            mainClassName="author-info__eye-icon"
            fillClassName="author-info__icon-fill"
          />
        </div>
        {stats?.views && (
          <p className={textClassName}>{formatNumber(stats.views)}</p>
        )}
      </div>

      <span className={`${textClassName} author-info__text_weight_thin`}>
        •
      </span>

      <div className="author-info__box">
        <div className={iconWrapperClassName}>
          <HeartIcon
            mainClassName="author-info__heart-icon"
            fillClassName="author-info__icon-fill"
          />
        </div>
        {stats?.likes && (
          <p className={textClassName}>{formatNumber(stats.likes)}</p>
        )}
      </div>

      {withDate && (
        <>
          <span className={`${textClassName} author-info__text_weight_thin`}>
            •
          </span>

          <p className={textClassName}>
            {moment(utc_datetime).format('DD.MM.YYYY')}
          </p>
        </>
      )}
    </div>
  );
}

export default AuthorInfo;
