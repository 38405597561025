import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './Catalog.css';
import {
  STORIES_TYPE,
  CREATORS_TYPE,
  CATEGORIES_TYPE,
  TAGS_TYPE,
  AI_TYPE,
  CREATOR_TYPE,
  CATEGORY_TYPE,
  TAG_TYPE,
} from '../../assets/utils/constants';
import StoriesPage from './StoriesPage/StoriesPage';
import CreatorsPage from './CreatorsPage/CreatorsPage';
import CategoriesPage from './CategoriesPage/CategoriesPage';
import TagsPage from './TagsPage/TagsPage';
import AiPage from './AiPage/AiPage';
import Creator from '../Creator/Creator';

function Catalog() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route index element={<Navigate to={STORIES_TYPE} />} />
      <Route path={`/${STORIES_TYPE}`} element={<StoriesPage noParams />} />
      <Route path={`/${STORIES_TYPE}/:slug`} element={<StoriesPage />} />
      <Route path={`/${CREATORS_TYPE}`} element={<CreatorsPage />} />
      <Route
        path={`/${CREATORS_TYPE}/${CREATOR_TYPE}/:creator_id`}
        element={<Creator />}
      />
      <Route path={`/${CATEGORIES_TYPE}`} element={<CategoriesPage />} />
      <Route
        path={`/${CATEGORIES_TYPE}/${CATEGORY_TYPE}/:slug`}
        element={<StoriesPage />}
      />
      <Route path={`/${TAGS_TYPE}`} element={<TagsPage />} />
      <Route
        path={`/${TAGS_TYPE}/${TAG_TYPE}/:slug`}
        element={<StoriesPage />}
      />
      <Route path={`/${AI_TYPE}`} element={<AiPage />} />
    </Routes>
  );
}

export default Catalog;
