import { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import useWindowSize from '../../assets/hooks/useWindowSize';
import {
  MOBILE_BREAKPOINT,
  USER_CREATOR_TYPE,
  MY_STORIES_LINK,
  CREATOR_STUDIO_LINK,
  BECOME_CREATOR_LINK,
  DESK_FAVORITES_TYPE,
  BASE_TIMING_FUNCTION,
  CONTENT_TRANSITION_DURATION,
  PROFILE_NAV_LIST,
  DESK_PROFILE_TYPE,
  DESK_HISTORY_TYPE,
} from '../../assets/utils/constants';
import './Desk.css';
import DeskNav from './DeskNav/DeskNav';
import { UserContext } from '../../assets/contexts/userContext';
import { TranslationContext } from '../../assets/contexts/translationContext';
import Favorites from './Favorites/Favorites';
import Profile from './Profile/Profile';
import History from './History/History';
import { DataContext } from '../../assets/contexts/dataContext';

const transition = {
  duration: CONTENT_TRANSITION_DURATION,
  ease: BASE_TIMING_FUNCTION,
};

const transitionVariants = {
  initial: { opacity: 0, transition },
  animate: { opacity: 1, transition },
  exit: { opacity: 0, transition },
};

function Desk() {
  const { user } = useContext(UserContext);
  const personal_data = user?.personal_data;
  const userType = user?.reg_data?.role?.name;
  const {
    labels: { creatorUserType },
  } = useContext(TranslationContext);
  const { lang } = useContext(DataContext);
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const { width } = useWindowSize();
  const [title, setTitle] = useState('');
  const [userTypeLinksList, setUserTypeLinksList] = useState([]);

  useEffect(() => {
    if (!userType) return;

    setUserTypeLinksList(
      userType === USER_CREATOR_TYPE
        ? [MY_STORIES_LINK, CREATOR_STUDIO_LINK]
        : [BECOME_CREATOR_LINK]
    );
  }, [userType]);

  useEffect(() => {
    const list = [...PROFILE_NAV_LIST, ...userTypeLinksList];
    const link = list.find((link) => link.path.includes(path));
    setTitle(link?.label?.[lang]);
  }, [userTypeLinksList, path, lang]);

  return (
    <motion.section
      className="desk"
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="desk__container">
        <div className="desk__sidebar">
          <div className="desk__heading">
            {width <= MOBILE_BREAKPOINT && (
              <AnimatePresence mode="popLayout" initial={false}>
                <motion.h1
                  className="desk__title"
                  key={title}
                  variants={transitionVariants}
                >
                  {title}
                </motion.h1>
              </AnimatePresence>
            )}

            <div className="desk__user-info">
              <h2 className="desk__user-name">
                {personal_data?.username || personal_data?.name || ''}
              </h2>
              {userType === USER_CREATOR_TYPE && (
                <div className="desk__user-type-wrapper">
                  <p className="desk__user-type">{creatorUserType}</p>
                </div>
              )}
            </div>
          </div>

          {width > MOBILE_BREAKPOINT && (
            <DeskNav linksList={userTypeLinksList} />
          )}
        </div>

        <AnimatePresence mode="wait" initial={false}>
          {user && (
            <motion.div
              className="desk__content"
              key={path}
              variants={transitionVariants}
            >
              <Routes location={location} key={location.pathname}>
                <Route index element={<Navigate to={DESK_FAVORITES_TYPE} />} />
                <Route
                  path={`/${DESK_FAVORITES_TYPE}/*`}
                  element={<Favorites />}
                />
                <Route path={`/${DESK_PROFILE_TYPE}/*`} element={<Profile />} />
                <Route path={`/${DESK_HISTORY_TYPE}/*`} element={<History />} />
              </Routes>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.section>
  );
}

export default Desk;
