import { useContext, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './CommentsList.css';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import CommentItem from '../CommentItem/CommentItem';
import {
  PRELOADER_MORE_TYPE,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';

function CommentsList({
  data,
  nested = false,
  branchId,
  hasMore,
  getData,
  isPreloader,
  ...props
}) {
  const {
    reader: {
      comments: { noComments },
    },
    labels: { loadMoreReply },
  } = useContext(TranslationContext);
  const moreBtnRef = useRef(null);
  const preloaderType = `${PRELOADER_MORE_TYPE}${nested ? `-${branchId}` : ''}`;

  useEffect(() => {
    if (nested) return;

    // Настраиваем Intersection Observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore) {
          getData({
            // reply_to: nested ? branchId : undefined,
            preloaderType,
          });
        }
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    const moreBtnRefCurrent = moreBtnRef?.current;
    if (moreBtnRefCurrent) {
      observer.observe(moreBtnRefCurrent);
      return () => observer.unobserve(moreBtnRefCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, data]);

  return (
    <div className="comments">
      {data?.length > 0 ? (
        <ul
          className={`comments__list ${nested ? 'comments__list_nested' : ''}`}
        >
          {data.map((item) => (
            <CommentItem
              key={item._id}
              {...item}
              nested={nested}
              branchId={nested ? branchId : item._id}
              comments={data}
              getData={getData}
              isPreloader={isPreloader}
              {...props}
            />
          ))}
        </ul>
      ) : (
        <p className="comments__text">{noComments}</p>
      )}

      <AnimatePresence mode="wait" initial={false}>
        {hasMore && (
          <motion.button
            ref={moreBtnRef}
            className={`comments__more-button ${
              nested ? 'comments__more-button_type_reply' : ''
            }`}
            type="button"
            onClick={
              nested
                ? () => getData({ reply_to: branchId, preloaderType })
                : undefined
            }
            initial={{
              opacity: 0,
              backgroundColor: 'var(--website-background-theme)',
            }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            whileHover={{
              backgroundColor: 'var(--comments-color-primary)',
            }}
            whileTap={{ scale: TAP_BTN_SCALE }}
            style={{
              opacity: isPreloader[preloaderType] || nested ? 1 : 0,
              pointerEvents: isPreloader[preloaderType] ? 'none' : 'auto',
              userSelect: isPreloader[preloaderType] ? 'none' : 'auto',
            }}
          >
            {isPreloader[preloaderType] &&
            preloaderType !== PRELOADER_MORE_TYPE ? (
              <MiniPreloader />
            ) : (
              loadMoreReply
            )}
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
}

export default CommentsList;
