import { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PopupContext } from '../../../assets/contexts/popupContex';
import './DesktopPopup.css';
import { CloseIcon } from '../../../assets/icons/icons';
import {
  BASE_TIMING_FUNCTION,
  POPUP_TRANSITION_DURATION,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';

const defaultTransition = {
  duration: POPUP_TRANSITION_DURATION,
  ease: BASE_TIMING_FUNCTION,
};

const popupVariants = {
  hidden: {
    opacity: 0,
    transition: defaultTransition,
  },
  visible: {
    opacity: 1,
    transition: defaultTransition,
  },
};

function DesktopPopup({ children, modal, popupName, fullScreen, onClose }) {
  const { isPopupOpen: isOpen } = useContext(PopupContext);

  return (
    <AnimatePresence>
      {isOpen[popupName] && (
        <motion.div
          className={`desktop-popup ${
            isOpen[popupName] ? 'desktop-popup_visible' : ''
          }`}
          key={popupName}
          variants={popupVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          layout
        >
          <motion.div
            className={`desktop-popup__container ${
              fullScreen ? 'desktop-popup__container_type_full-screen' : ''
            }`}
            variants={popupVariants}
            layout
          >
            {!modal && !fullScreen && (
              <motion.button
                className="desktop-popup__close-button"
                type="button"
                onClick={onClose}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: TAP_BTN_SCALE }}
              >
                <CloseIcon
                  mainClassName="desktop-popup__close-icon"
                  fillClassName="desktop-popup__close-icon-fill"
                />
              </motion.button>
            )}
            {children}
          </motion.div>

          <motion.div
            className={`desktop-popup__overlay ${
              modal ? 'desktop-popup__overlay_type_modal' : ''
            }`}
            onClick={!modal ? onClose : undefined}
            variants={popupVariants}
            layout
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default DesktopPopup;
