import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DOTS, usePagination } from '../../../assets/hooks/usePagination';
import { CloseIcon } from '../../../assets/icons/icons';
import {
  BASE_TIMING_FUNCTION,
  POPUP_TRANSITION_DURATION,
  TAP_BTN_SCALE,
  AI_LINK,
} from '../../../assets/utils/constants';
import './PaginationBar.css';
import useWindowSize from '../../../assets/hooks/useWindowSize';

const barVariants = {
  visible: {
    clipPath: 'inset(0% 0% 0% 0% round 60px)',
    opacity: 1,
    visibility: 'visible',
    transition: {
      duration: POPUP_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
  hidden: {
    clipPath: 'inset(0% 100% -0% 0% round 60px)',
    opacity: 0,
    visibility: 'hidden',
    transition: {
      duration: POPUP_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

const hoverBtnAnimation = { scale: 1.03 };
const tapBtnAnimation = { scale: TAP_BTN_SCALE };

function PaginationBar({
  isOpen,
  totalCount,
  currentPage,
  onPageChange,
  onClose,
  setPaginationRange,
}) {
  const { width } = useWindowSize();
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: width > 400 ? 1 : 0,
    containerWidth,
    minPaginationBtnWidth: 44,
  });
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);

  useEffect(() => {
    if (paginationRange?.length > 0) setPaginationRange(paginationRange);
  }, [paginationRange, setPaginationRange]);

  useEffect(() => {
    if (containerRef?.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, [containerRef, width]);

  if (currentPage === 0 || paginationRange.length < 2) return null;

  return (
    <motion.div
      className={`pagination-bar ${
        isAiStories ? 'pagination-bar_theme_dark' : ''
      }`}
      variants={barVariants}
      initial="hidden"
      animate={isOpen ? 'visible' : 'hidden'}
    >
      <ul className="pagination-bar__list" ref={containerRef}>
        {paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return (
              <li className="pagination-bar__item" key={i}>
                <p className="pagination-bar__text pagination-bar__text_type_dots">
                  &#8230;
                </p>
              </li>
            );
          }

          const isCurrentPage = pageNumber === currentPage;
          return (
            <li className="pagination-bar__item" key={i}>
              <motion.button
                className="pagination-bar__btn pagination-bar__text"
                type="button"
                onClick={() =>
                  !isCurrentPage ? onPageChange(pageNumber) : undefined
                }
                style={{
                  fontWeight: isCurrentPage ? '700' : '400',
                  pointerEvents: isCurrentPage ? 'none' : 'auto',
                  userSelect: isCurrentPage ? 'none' : 'auto',
                }}
                whileHover={{ backgroundColor: 'var(--neutral-bg-primary)' }}
                whileTap={tapBtnAnimation}
                animate={{
                  backgroundColor: isCurrentPage
                    ? 'var(--neutral-active-theme)'
                    : isAiStories
                    ? 'var(--pagination-neutral-primary)'
                    : 'var(--website-background)',
                  color: isCurrentPage
                    ? 'var(--neutral-contrast-active-theme)'
                    : 'var(--pagination-text-color)',
                }}
                transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
                disabled={isCurrentPage}
              >
                {pageNumber}
              </motion.button>
            </li>
          );
        })}
      </ul>

      <motion.button
        className="pagination-bar__btn pagination-bar__btn_type_close"
        type="button"
        onClick={onClose}
        whileHover={hoverBtnAnimation}
        whileTap={tapBtnAnimation}
      >
        <CloseIcon
          mainClassName="pagination-bar__close-icon"
          fillClassName="pagination-bar__close-icon-fill"
        />
      </motion.button>
    </motion.div>
  );
}

export default PaginationBar;
