import { useState, useEffect } from 'react';

export const useImageLoader = (imageUrl) => {
  const [isImgLoading, setIsImgLoading] = useState(true);

  useEffect(() => {
    if (imageUrl) loadImage(imageUrl);
  }, [imageUrl]);

  const loadImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setIsImgLoading(false);
    };
    img.onerror = (err) => {
      console.log('img error:', err);
    };
  };

  return { isImgLoading };
};
