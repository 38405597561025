import { QuoteIcon } from '../../../../../assets/icons/icons';
import { renderStoryChildren } from '../../../../../assets/utils/utils';
import LineDivider from '../../../../LineDivider/LineDivider';
import './QuoteElement.css';

function QuoteElement({ data }) {
  return (
    <div className="quote-element">
      <div className="quote-element__top-line">
        <QuoteIcon
          mainClassName="quote-element__icon"
          fillClassName="quote-element__icon-fill"
        />
        <LineDivider noCenterRhomb noAnimation />
      </div>
      <p className="quote-element__text">{renderStoryChildren(data)}</p>
      <div className="quote-element__bottom-line">
        <LineDivider noCenterRhomb noAnimation />
      </div>
    </div>
  );
}

export default QuoteElement;
