import { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { RhombIcon } from '../../assets/icons/icons';
import './LineDivider.css';
import {
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
} from '../../assets/utils/constants';

export const lineVariants = {
  hidden: { width: 0 },
  visible: {
    width: '100%',
    transition: {
      duration: BASE_TRANSITION_DURATION * 2,
      ease: BASE_TIMING_FUNCTION,
      delay: 2,
    },
  },
};

export const lineVariantsNoAnimation = {
  hidden: { width: '100%' },
  visible: { width: '100%' },
};

export default function LineDivider({
  isSmallRhomb,
  noCenterRhomb,
  noAnimation,
  lineColor = 'var(--neutral-line-primary-theme)',
  rhombColor = 'var(--neutral-line-primary-theme)',
}) {
  const lineRef = useRef(null);
  const lineControls = useAnimation();
  const lineInView = useInView(lineRef, { amount: 1, once: true });

  useEffect(() => {
    if (lineInView) lineControls.start('visible');
  }, [lineInView, lineControls]);

  return (
    <div
      className="line-divider"
      ref={lineRef}
      style={{ '--line-color': lineColor, '--rhomb-color': rhombColor }}
    >
      {!noCenterRhomb && (
        <RhombIcon
          mainClassName={`line-divider__main-icon ${
            isSmallRhomb ? 'line-divider__main-icon_size_small' : ''
          }`}
          fillClassName="line-divider__main-icon-fill"
        />
      )}
      <motion.div
        className="line-divider__line"
        initial="hidden"
        animate={lineControls}
        variants={noAnimation ? lineVariantsNoAnimation : lineVariants}
      >
        <RhombIcon
          mainClassName="line-divider__line-icon line-divider__line-icon_position_left"
          fillClassName="line-divider__line-icon-fill"
        />
        <RhombIcon
          mainClassName="line-divider__line-icon line-divider__line-icon_position_right"
          fillClassName="line-divider__line-icon-fill"
        />
      </motion.div>
    </div>
  );
}
