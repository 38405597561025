import { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { EyeIcon, FillBookmarkIcon } from '../../../assets/icons/icons';
import './ReaderHeading.css';
import {
  TAP_BTN_SCALE,
  CREATOR_LINK,
  AI_LINK,
  CATALOG_PAGE_TAGS_LINK,
  TAG_TYPE,
} from '../../../assets/utils/constants';
import LineDivider from '../../LineDivider/LineDivider';
import AvatarBox from '../../AvatarBox/AvatarBox';
import AuthorInfo from '../../MainPage/AuthorsCards/AuthorInfo/AuthorInfo';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { useImageLoader } from '../../../assets/hooks/useImageLoader';

function ReaderHeading({
  meta,
  title,
  author,
  stats,
  price,
  is_payable,
  utc_datetime,
  isFirstPage,
  isLiked,
  onLikeClick,
}) {
  const {
    aiStories: {
      aiBlock: { caption },
    },
  } = useContext(TranslationContext);

  const [isCollapsed, setIsCollapsed] = useState(!isFirstPage);
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);

  const imgUrl = meta?.cover?.urls?.orig;
  const { isImgLoading } = useImageLoader(imgUrl);

  useEffect(() => {
    setIsCollapsed(!isFirstPage);
  }, [isFirstPage]);

  function toggleCollapsedState() {
    setIsCollapsed((prev) => !prev);
  }

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      <motion.div
        className={`reader-heading ${
          isCollapsed ? 'reader-heading_size_small' : ''
        } ${isAiStories ? 'reader-heading_type_ai' : ''}`}
        onClick={!isFirstPage ? toggleCollapsedState : undefined}
        style={{ cursor: !isFirstPage ? 'pointer' : 'default' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={isCollapsed ? 'collapsed' : 'expanded'}
      >
        <div
          className={`reader-heading__image-wrapper ${
            isCollapsed ? 'reader-heading__image-wrapper_size_small' : ''
          }`}
        >
          <img
            className={`reader-heading__image ${
              isCollapsed ? 'reader-heading__image_size_small' : ''
            } ${isImgLoading ? 'skeleton-loader' : ''}`}
            src={imgUrl}
            alt={meta?.cover?.description ?? title}
            loading="lazy"
          />
        </div>

        <div
          className={`reader-heading__title-block ${
            isCollapsed ? 'reader-heading__title-block_size_small' : ''
          }`}
        >
          {isAiStories ? (
            <div className="reader-heading__author-block">
              {!isCollapsed && (
                <div className="reader-heading__logo-box">
                  <EyeIcon
                    mainClassName="reader-heading__logo"
                    fillClassName="reader-heading__logo-fill"
                  />
                </div>
              )}
              <span
                className={`reader-heading__author-text ${
                  isCollapsed ? 'reader-heading__author-text_size_small' : ''
                }`}
              >
                {caption}
              </span>
            </div>
          ) : (
            <Link
              to={`/${CREATOR_LINK}/${author?._id}`}
              className="reader-heading__author-link"
              onClick={(evt) => evt.stopPropagation()}
              state={{ disableScrollToTop: false }}
            >
              <motion.div
                className="reader-heading__author-block"
                initial={{ color: 'var(--second-active-primary)' }}
                whileHover={{ color: 'var(--main-active-primary)' }}
                whileTap={{ scale: TAP_BTN_SCALE }}
              >
                {!isCollapsed && (
                  <AvatarBox
                    className="reader-heading__author-avatar"
                    data={author?.personal_data}
                  />
                )}
                <span
                  className={`reader-heading__author-text ${
                    isCollapsed ? 'reader-heading__author-text_size_small' : ''
                  }`}
                >
                  {author?.personal_data?.name}
                </span>
              </motion.div>
            </Link>
          )}

          <h2
            className={`reader-heading__title ${
              isCollapsed ? 'reader-heading__title_size_small' : ''
            }`}
          >
            {title}
          </h2>
        </div>

        <div
          className={`reader-heading__info-block ${
            isCollapsed ? 'reader-heading__info-block_size_small' : ''
          } ${isAiStories ? 'reader-heading__info-block_type_ai' : ''}`}
        >
          <div className="reader-heading__info">
            <motion.button
              className={`reader-heading__save-btn ${
                isCollapsed ? 'reader-heading__save-btn_size_small' : ''
              } ${isLiked ? 'reader-heading__save-btn_active' : ''}`}
              type="button"
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: TAP_BTN_SCALE }}
              onClick={onLikeClick}
            >
              <FillBookmarkIcon
                mainClassName={`reader-heading__save-icon ${
                  isCollapsed ? 'reader-heading__save-icon_size_small' : ''
                }`}
                fillClassName={`reader-heading__save-icon-fill ${
                  isLiked ? 'reader-heading__save-icon-fill_active' : ''
                }`}
              />
            </motion.button>

            {!isCollapsed && (
              <AuthorInfo
                is_free={!price}
                price={price}
                stats={stats}
                utc_datetime={utc_datetime}
                isBig
                withPrice={!isAiStories}
                withDate
              />
            )}
          </div>

          {!isCollapsed && !isAiStories && (
            <ul className="reader-heading__tags">
              {meta?.tags?.map((tag) => (
                <motion.li
                  className="reader-heading__tag"
                  key={tag}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: TAP_BTN_SCALE }}
                >
                  <Link
                    className="reader-heading__tag-link"
                    to={`/${CATALOG_PAGE_TAGS_LINK}/${TAG_TYPE}/${tag}`}
                    state={{ disableScrollToTop: false }}
                  >
                    <p className="reader-heading__tag-text">
                      <span className="reader-heading__tag-text_type_color">
                        #
                      </span>
                      {tag}
                    </p>
                  </Link>
                </motion.li>
              ))}
            </ul>
          )}
        </div>

        {isCollapsed && (
          <div className="reader-heading__divider">
            <LineDivider noCenterRhomb noAnimation />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default ReaderHeading;
