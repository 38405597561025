import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

export default function useCycle(options) {
  const { width } = useWindowSize();
  const [index, setIndex] = useState(0);
  const [currentOption, setCurrentOption] = useState(options[index]);

  useEffect(() => {
    setCurrentOption(options[index]);
  }, [options, index]);

  useEffect(() => {
    setIndex(0);
  }, [width]);

  function cycle(i) {
    const newIndex = index < options.length - 1 ? index + 1 : 0;
    setIndex(i !== undefined ? i : newIndex);
  }
  return [currentOption, cycle];
}
