import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Creator.css';
import {
  TAP_BTN_SCALE,
  CREATOR_LINK,
} from '../../../../assets/utils/constants';
import AvatarBox from '../../../AvatarBox/AvatarBox';

const CreatorLink = ({ data, onClose }) => {
  return (
    <Link
      to={`/${CREATOR_LINK}/${data?._id}`}
      className="search-dropdown-creator"
      onClick={onClose}
      state={{ disableScrollToTop: false }}
    >
      <motion.div
        className="search-dropdown-creator__container"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
      >
        <AvatarBox
          data={data?.personal_data}
          className="search-dropdown-creator__image"
        />
        <p className="search-dropdown-creator__title">
          {data?.personal_data?.name}
        </p>
      </motion.div>
    </Link>
  );
};

const CreatorBox = ({ data }) => {
  return (
    <div className="search-dropdown-creator">
      <div className="search-dropdown-creator__container">
        <AvatarBox
          data={data?.personal_data}
          className="search-dropdown-creator__image"
        />
        <p className="search-dropdown-creator__title">
          {data?.personal_data?.name}
        </p>
      </div>
    </div>
  );
};

function Creator({ isLink, ...props }) {
  return isLink ? <CreatorLink {...props} /> : <CreatorBox {...props} />;
}

export default Creator;
