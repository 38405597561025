import { useContext, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import mainApi from '../../../assets/api/MainApi';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import {
  AUTH_LOGIN_LINK,
  AUTH_RESET_PASSWORD_TYPE,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  RESET_PASSWORD_TOKEN_KEY,
} from '../../../assets/utils/constants';
import AuthFormWrapper from '../AuthFormWrapper/AuthFormWrapper';
import AuthInput from '../AuthInput/AuthInput';
import './ResetPassword.css';

const initialValues = {
  password: '',
};

const initialValuesValidity = {
  password: { validState: false, errorMessage: '' },
};

function ResetPassword() {
  const {
    auth: {
      resetPassword: { title, buttonText },
    },
    labels: { newPassword },
    errors: { passwordTooShort, passwordTooLong },
  } = useContext(TranslationContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState('');
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    const token = searchParams.get(RESET_PASSWORD_TOKEN_KEY);
    if (token) {
      setToken(token);
    } else {
      navigate(AUTH_LOGIN_LINK);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsFormValid(
      Object.values(valuesValidity).every(({ validState }) => validState)
    );
  }, [valuesValidity]);

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    if (value.length < PASSWORD_MIN_LENGTH) {
      setValuesValidity({
        ...valuesValidity,
        [name]: {
          validState: false,
          errorMessage: passwordTooShort,
        },
      });
    } else if (value.length > PASSWORD_MAX_LENGTH) {
      setValuesValidity({
        ...valuesValidity,
        [name]: {
          validState: false,
          errorMessage: passwordTooLong,
        },
      });
    } else {
      setValuesValidity({
        ...valuesValidity,
        [name]: {
          validState: true,
          errorMessage: '',
        },
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsPreloader(true);
    mainApi
      .changePassword({
        password: values.password,
        token,
      })
      .then(() => {
        navigate(AUTH_LOGIN_LINK);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  return (
    <AuthFormWrapper
      title={title}
      buttonText={buttonText}
      id={AUTH_RESET_PASSWORD_TYPE}
      onSubmit={handleSubmit}
      isFormValid={isFormValid}
      isPreloader={isPreloader}
      error={error}
    >
      <AuthInput
        type="password"
        label={newPassword}
        name="password"
        autoComplete="new-password"
        value={values.password}
        onChange={handleChange}
        error={valuesValidity.password}
        isPassword
      />
    </AuthFormWrapper>
  );
}

export default ResetPassword;
