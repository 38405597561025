import React from 'react';
import LineDivider from '../../../LineDivider/LineDivider';
import './ContentBlock.css';
import {
  DIALOGUE_LEFT_BLOCK,
  DIALOGUE_RIGHT_BLOCK,
  HEADING_BLOCK_1,
  HEADING_BLOCK_2,
  HEADING_BLOCK_3,
  PARAGRAPH_BLOCK,
  QUOTE_BLOCK,
} from '../../../../assets/utils/constants';
import TextElement from './TextElement/TextElement';
import QuoteElement from './QuoteElement/QuoteElement';
import DialogueTextElement from './DialogueTextElement/DialogueTextElement';

function ContentBlock({ elem, isFirstBlock }) {
  return (
    <div className="content-block">
      {(elem.block_type.includes(HEADING_BLOCK_1) || isFirstBlock) && (
        <div className="content-block__line">
          <LineDivider noCenterRhomb noAnimation />
        </div>
      )}

      {(elem.block_type.includes(HEADING_BLOCK_1) ||
        elem.block_type.includes(HEADING_BLOCK_2) ||
        elem.block_type.includes(HEADING_BLOCK_3) ||
        elem.block_type === PARAGRAPH_BLOCK) && <TextElement elem={elem} />}

      {elem.block_type === QUOTE_BLOCK && <QuoteElement data={elem.data} />}

      {(elem.block_type === DIALOGUE_LEFT_BLOCK ||
        elem.block_type === DIALOGUE_RIGHT_BLOCK) && (
        <DialogueTextElement elem={elem} />
      )}
    </div>
  );
}

export default ContentBlock;
