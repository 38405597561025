import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Autoplay } from 'swiper/modules';
import Banner from './Banner/Banner';
import './BannersList.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import { EyeIcon } from '../../../assets/icons/icons';
import { BANNER_AUTOPLAY_DELAY } from '../../../assets/utils/constants';

function BannersList({ data }) {
  const progressCircle = useRef(null);

  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle?.current?.style?.setProperty('--progress', 1 - progress);
  };

  return (
    <section className="banners-list">
      <Swiper
        className="banners-list__swiper"
        slidesPerView={1}
        loop={data?.length > 1}
        longSwipes={false}
        modules={[Mousewheel, Autoplay]}
        mousewheel={{ forceToAxis: true }}
        autoplay={{ delay: BANNER_AUTOPLAY_DELAY, disableOnInteraction: false }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
      >
        {data?.map((banner) => (
          <SwiperSlide className="banners-list__slide" key={banner.elem?._id}>
            <Banner {...banner} />
          </SwiperSlide>
        ))}

        <div className="banners-list__progress-wrapper">
          <div className="banners-list__slide-progress">
            <EyeIcon
              mainClassName="banners-list__logo"
              fillClassName="banners-list__logo-fill"
            />

            {data?.length > 1 && (
              <svg
                className="banners-list__autoplay-progress"
                viewBox="0 0 88 88"
                ref={progressCircle}
              >
                <circle cx="44" cy="44" r="40" />
              </svg>
            )}
          </div>
        </div>
      </Swiper>
    </section>
  );
}

export default BannersList;
