import { motion } from 'framer-motion';
import './AuthButton.css';
import { TAP_BTN_SCALE } from '../../../assets/utils/constants';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function AuthButton({
  label,
  formId,
  onClick,
  isTypeSubmit,
  isDisabled,
  isPreloader,
  isMobileSmall,
  isColorsInverted,
  isColorContrast,
}) {
  return (
    <motion.button
      className={`auth-button ${
        isTypeSubmit || isColorsInverted ? 'auth-button_type_submit' : ''
      } ${isMobileSmall ? 'auth-button_mobile_small' : ''} ${
        isColorContrast ? 'auth-button_color_contrast' : ''
      }`}
      type={isTypeSubmit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={isDisabled}
      form={formId}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: TAP_BTN_SCALE }}
      initial={{ opacity: 0.5 }}
      animate={{ opacity: isDisabled ? 0.5 : 1 }}
      style={{
        pointerEvents: isDisabled || isPreloader ? 'none' : 'auto',
        userSelect: isDisabled || isPreloader ? 'none' : 'auto',
      }}
    >
      {isPreloader ? (
        <MiniPreloader isLinkColor={isTypeSubmit || isColorsInverted} />
      ) : (
        label
      )}
    </motion.button>
  );
}

export default AuthButton;
