import { useContext } from 'react';
import { PopupContext } from '../../assets/contexts/popupContex';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { MOBILE_BREAKPOINT } from '../../assets/utils/constants';
import DesktopPopup from './DesktopPopup/DesktopPopup';
import Dropdown from './Dropdown/Dropdown';
import MobilePopup from './MobilePopup/MobilePopup';
import './Popup.css';
import useEscapeKey from '../../assets/hooks/useEscapeKey';

function Popup({ dropdown, onClose, stopPropagation = false, ...props }) {
  const { popupName, modal } = props;
  const { isPopupOpen: isOpen, handlePopupClose } = useContext(PopupContext);
  const { width } = useWindowSize();
  useEscapeKey(handleClose, stopPropagation);

  function handleClose() {
    if (isOpen[popupName] && !modal) {
      if (onClose) onClose();
      setTimeout(() => handlePopupClose(popupName), 100);
    }
  }

  return width > MOBILE_BREAKPOINT ? (
    dropdown ? (
      <Dropdown onClose={handleClose} {...props} />
    ) : (
      <DesktopPopup onClose={handleClose} {...props} />
    )
  ) : (
    <MobilePopup onClose={handleClose} {...props} />
  );
}

export default Popup;
