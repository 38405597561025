import { useState, useContext } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  BurgerIcon,
  EyeIcon,
  PenIcon,
  PlusInCircleIcon,
  SearchIcon,
  StrokeBookmarkIcon,
  TriangleArrowIcon,
} from '../../assets/icons/icons';
import {
  AUTH_LOGIN_LINK,
  AUTH_POPUP_TYPE,
  BASE_TIMING_FUNCTION,
  CATALOG_DROPDOWN_TYPE,
  DESK_FAVORITES_LINK,
  HEADER_NAV_LIST,
  MOBILE_BREAKPOINT,
  PROFILE_DROPDOWN_TYPE,
  SEARCH_DROPDOWN_TYPE,
  TAP_BTN_SCALE,
  USER_CREATOR_TYPE,
  AI_LINK,
  CREATOR_STUDIO_URL,
  CATALOG_PAGE_MAIN_LINK,
  TOOLTIP_TYPE,
} from '../../assets/utils/constants';
import './Header.css';
import MobileMenu from './MobileMenu/MobileMenu';
import useScrollDirection from '../../assets/hooks/useScrollDirection';
import { UserContext } from '../../assets/contexts/userContext';
import { PopupContext } from '../../assets/contexts/popupContex';
import { TranslationContext } from '../../assets/contexts/translationContext';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import CatalogDropdown from './CatalogDropdown/CatalogDropdown';
import useWindowSize from '../../assets/hooks/useWindowSize';
import usePreventScroll from '../../assets/hooks/usePreventScroll';
import SearchDropdown from './SearchDropdown/SearchDropdown';
import AvatarBox from '../AvatarBox/AvatarBox';
import gems from '../../assets/icons/gems-icon.png';
import { useScrollPosition } from '../../assets/hooks/useScrollPosition';
import { DataContext } from '../../assets/contexts/dataContext';

const btnHoverAnimation = {
  opacity: 0.8,
};

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function Header() {
  const {
    auth: { login },
    services: { catalog, balance },
  } = useContext(TranslationContext);
  const { width } = useWindowSize();
  const { user } = useContext(UserContext);
  const { lang } = useContext(DataContext);
  const { isPopupOpen, handlePopupOpen } = useContext(PopupContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isSomePopupOpen = Object.keys(isPopupOpen)
    .filter((popup) =>
      width > MOBILE_BREAKPOINT ? !popup.includes(TOOLTIP_TYPE) : popup
    )
    ?.some((popup) => isPopupOpen[popup]);
  const isTooltipOpen = Object.keys(isPopupOpen)
    .filter((popup) => popup.includes(TOOLTIP_TYPE))
    ?.some((popup) => isPopupOpen[popup]);
  const { isPageTop } = useScrollPosition(isSomePopupOpen);
  const scrollDirection = useScrollDirection(isSomePopupOpen);
  usePreventScroll(isMobileMenuOpen);

  const { state, pathname } = useLocation();
  const isAiStories =
    pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK) ||
    state?.background?.pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK);

  const openMobileMenu = () => {
    setIsMobileMenuOpen(true);
  };

  const handleCatalogBtnClick = () => {
    handlePopupOpen(CATALOG_DROPDOWN_TYPE);
  };

  const handleSearchBtnClick = () => {
    handlePopupOpen(SEARCH_DROPDOWN_TYPE);
  };

  const handleLoginBtnClick = () => {
    handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
  };

  const handleProfileBtnClick = () => {
    handlePopupOpen(PROFILE_DROPDOWN_TYPE);
  };

  return (
    <header
      className={`header ${
        scrollDirection === 'down' ? 'header_hide' : 'header_show'
      } ${
        isTooltipOpen && width <= MOBILE_BREAKPOINT
          ? 'header_z-index_popup'
          : ''
      } ${isAiStories ? 'header_theme_dark' : ''} ${
        !isPageTop ? 'header_type_blur' : ''
      }`}
    >
      <div className="header__container">
        <div className="header__content-block">
          <Link
            className="header__icon-link"
            to="/"
            state={{ disableScrollToTop: false }}
          >
            <motion.div
              className="header__icon-btn header__icon-btn_type_logo"
              whileHover={btnHoverAnimation}
              whileTap={btnTapAnimation}
            >
              <EyeIcon
                mainClassName="header__logo-icon"
                fillClassName="header__logo-icon-fill"
              />
            </motion.div>
          </Link>

          <motion.button
            className="header__icon-btn header__icon-btn_type_burger"
            type="button"
            whileTap={btnTapAnimation}
            onClick={openMobileMenu}
          >
            <BurgerIcon
              mainClassName="header__burger-icon"
              fillClassName="header__icon-fill"
            />
          </motion.button>

          <div className="header__catalog-box">
            <motion.button
              className={`header__btn header__btn_catalog ${
                isPopupOpen[CATALOG_DROPDOWN_TYPE] ? 'header__btn_active' : ''
              }`}
              type="button"
              whileHover={btnHoverAnimation}
              whileTap={btnTapAnimation}
              onClick={handleCatalogBtnClick}
            >
              {catalog}
              <motion.div
                className="header__arrow-box"
                animate={{
                  rotate: isPopupOpen[CATALOG_DROPDOWN_TYPE] ? 180 : 0,
                }}
                transition={{ duration: 0.3, ease: BASE_TIMING_FUNCTION }}
              >
                <TriangleArrowIcon
                  mainClassName="header__arrow"
                  fillClassName={`header__arrow-fill ${
                    isPopupOpen[CATALOG_DROPDOWN_TYPE]
                      ? 'header__arrow-fill_active'
                      : ''
                  }`}
                />
              </motion.div>
            </motion.button>
            {width > MOBILE_BREAKPOINT && <CatalogDropdown />}
          </div>

          <nav className="header__nav">
            <ul className="header__nav-list">
              {HEADER_NAV_LIST.map((item) => (
                <li className="header__nav-item" key={item.label[lang]}>
                  <NavLink
                    className={({ isActive }) =>
                      `header__nav-link ${
                        isActive ||
                        state?.background?.pathname.startsWith(`/${item.path}`)
                          ? 'header__nav-link_active'
                          : ''
                      }`
                    }
                    to={item.path}
                    state={{ disableScrollToTop: false }}
                  >
                    <motion.div
                      className="header__btn"
                      whileHover={btnHoverAnimation}
                      whileTap={btnTapAnimation}
                    >
                      {item.label[lang]}
                    </motion.div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          <div className="header__search-box">
            <motion.button
              className="header__icon-btn header__icon-btn_type_search"
              type="button"
              whileHover={btnHoverAnimation}
              whileTap={btnTapAnimation}
              onClick={handleSearchBtnClick}
            >
              <SearchIcon
                mainClassName="header__search-icon"
                fillClassName="header__icon-fill"
              />
            </motion.button>
            <SearchDropdown />
          </div>
        </div>

        <AnimatePresence mode="wait" initial={false}>
          {user ? (
            <motion.div
              className="header__content-block"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="user"
            >
              <div className="header__balance-block">
                <p className="header__balance-label">{balance}:</p>
                <div className="header__balance-box">
                  <img className="header__balance-icon" src={gems} alt="" />
                  <p className="header__balance-amount">{user?.balance}</p>
                </div>
                {/* <motion.button
                    className="header__add-btn"
                    type="button"
                    whileHover={{ scale: 1.05 }}
                    whileTap={btnTapAnimation}
                  >
                    <PlusInCircleIcon
                      mainClassName="header__add-icon"
                      fillClassName="header__add-icon-fill"
                      strokeClassName="header__add-icon-stroke"
                    />
                  </motion.button> */}
              </div>

              {user?.reg_data?.role?.name === USER_CREATOR_TYPE && (
                <a className="header__icon-link" href={CREATOR_STUDIO_URL}>
                  <motion.div
                    className="header__icon-btn header__icon-btn_type_creator"
                    whileHover={btnHoverAnimation}
                    whileTap={btnTapAnimation}
                  >
                    <PenIcon
                      mainClassName="header__pen-icon"
                      fillClassName="header__pen-icon-fill"
                    />
                  </motion.div>
                </a>
              )}

              <NavLink
                className={({ isActive }) =>
                  `header__icon-link ${
                    isActive ? 'header__icon-link_active' : ''
                  }`
                }
                to={DESK_FAVORITES_LINK}
                state={{ disableScrollToTop: false }}
              >
                <motion.div
                  className={`header__icon-btn header__icon-btn_type_favorites ${
                    isAiStories ? 'header__icon-btn_theme_dark' : ''
                  }`}
                  whileHover={btnHoverAnimation}
                  whileTap={btnTapAnimation}
                >
                  <StrokeBookmarkIcon
                    mainClassName="header__bookmark-icon"
                    fillClassName="header__icon-fill"
                  />
                </motion.div>
              </NavLink>

              <div className="header__avatar-box">
                <motion.button
                  className="header__avatar-btn"
                  type="button"
                  whileHover={btnHoverAnimation}
                  whileTap={btnTapAnimation}
                  onClick={handleProfileBtnClick}
                >
                  <AvatarBox data={user?.personal_data} />
                </motion.button>
                <ProfileDropdown />
              </div>
            </motion.div>
          ) : (
            <motion.button
              className="header__btn header__btn_inverted"
              type="button"
              whileHover={btnHoverAnimation}
              whileTap={btnTapAnimation}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="login"
              onClick={handleLoginBtnClick}
            >
              {login.title}
            </motion.button>
          )}
        </AnimatePresence>
      </div>

      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
    </header>
  );
}

export default Header;
