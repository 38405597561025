import { Swiper } from 'swiper/react';
import { Navigation, Mousewheel, FreeMode } from 'swiper/modules';
import { AngleArrowIcon } from '../../../../assets/icons/icons';
import './SwiperWrapper.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import {
  CONTAINER_MAX_WIDTH,
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../../../../assets/utils/constants';
import useWindowSize from '../../../../assets/hooks/useWindowSize';

function SwiperWrapper({
  children,
  _id,
  spaceBetweenOptions,
  gradient,
  isReader,
  isSmallCards,
  isContinueBlock,
}) {
  const { width } = useWindowSize();
  const swiperPadding = (width - CONTAINER_MAX_WIDTH) / 2;

  return (
    <div
      className={`swiper-block ${isReader ? 'swiper-block_type_reader' : ''}`}
      style={{
        '--swiper-padding-horizontal':
          swiperPadding > 32 ? `${swiperPadding}px` : '32px',
        '--cards-gap': `${spaceBetweenOptions.default}px`,
      }}
    >
      {width > MOBILE_BREAKPOINT && (
        <Swiper
          className={`swiper-block__swiper ${
            isReader ? 'swiper-block__swiper_type_reader' : ''
          }`}
          slidesPerView="auto"
          spaceBetween={spaceBetweenOptions.default}
          modules={[Mousewheel, Navigation, FreeMode]}
          mousewheel={{ forceToAxis: true }}
          navigation={{
            nextEl: `.swiper-block__button-next-${_id}`,
            prevEl: `.swiper-block__button-prev-${_id}`,
            disabledClass: 'swiper-block__nav-button_disabled',
          }}
          freeMode={{ enabled: true }}
          breakpoints={{
            [DESKTOP_BREAKPOINT + 1]: {
              spaceBetween: spaceBetweenOptions[DESKTOP_BREAKPOINT + 1],
            },
            [TABLET_BREAKPOINT + 1]: {
              spaceBetween: spaceBetweenOptions[TABLET_BREAKPOINT + 1],
            },
            [MOBILE_BREAKPOINT + 1]: {
              spaceBetween: spaceBetweenOptions[MOBILE_BREAKPOINT + 1],
            },
          }}
        >
          {children}
        </Swiper>
      )}

      {width <= MOBILE_BREAKPOINT && (
        <div
          className={`swiper-block__cards ${
            isSmallCards ? 'swiper-block__cards_type_small' : ''
          } ${isContinueBlock ? 'swiper-block__cards_type_continue' : ''}`}
        >
          {children}
        </div>
      )}

      <button
        className={`swiper-block__nav-button swiper-block__nav-button_type_prev swiper-block__button-prev-${_id} ${
          isReader ? 'swiper-block__nav-button_type_reader-prev' : ''
        }`}
        type="button"
        disabled
      >
        <AngleArrowIcon
          mainClassName="swiper-block__nav-icon"
          fillClassName="swiper-block__nav-icon-fill"
        />
      </button>
      <button
        className={`swiper-block__nav-button swiper-block__nav-button_type_next swiper-block__button-next-${_id} ${
          isReader ? 'swiper-block__nav-button_type_reader-next' : ''
        }`}
        type="button"
      >
        <AngleArrowIcon
          mainClassName="swiper-block__nav-icon"
          fillClassName="swiper-block__nav-icon-fill"
        />
      </button>

      {gradient && (
        <>
          <img
            className="swiper-block__gradient swiper-block__gradient_position_left"
            src={gradient}
            alt=""
          />
          <img
            className="swiper-block__gradient swiper-block__gradient_position_right"
            src={gradient}
            alt=""
          />
        </>
      )}
    </div>
  );
}

export default SwiperWrapper;
