import { useContext } from 'react';
import './Profile.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import ProfileForm from './ProfileForm/ProfileForm';
import { PopupContext } from '../../../assets/contexts/popupContex';
import PreferencesList from '../../Auth/PreferencesPopup/PreferencesList/PreferencesList';
import { UserContext } from '../../../assets/contexts/userContext';
import AuthButton from '../../Auth/AuthButton/AuthButton';
import { PREFERENCES_POPUP_TYPE } from '../../../assets/utils/constants';
import AccountsList from './AccountsList/AccountsList';
import PasswordForm from './PasswordForm/PasswordForm';
import ConfirmEmailBlock from '../ConfirmEmailBlock/ConfirmEmailBlock';

function Profile() {
  const {
    desk: {
      profile: {
        details,
        accounts,
        preferences,
        changePreferences,
        password,
        notSetPasswordDetails,
        notSetPasswordOauth,
      },
    },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const is_password_set = user?.reg_data?.is_password_set;
  const is_email_verified = user?.reg_data?.stages?.is_email_verified;

  return (
    <div className="profile">
      {user && (
        <>
          {!is_email_verified && <ConfirmEmailBlock />}

          <div className="profile__block">
            <h3 className="profile__title">{details}</h3>
            {!is_password_set && (
              <p className="profile__text profile__text_type_color">
                {notSetPasswordDetails}
              </p>
            )}
            <ProfileForm />
          </div>

          <div className="profile__block">
            <h3 className="profile__title">{password}</h3>
            <PasswordForm />
          </div>

          <div className="profile__block">
            <h3 className="profile__title">{preferences}</h3>
            {user?.personal_data?.category_ids?.length > 0 && (
              <PreferencesList data={user.personal_data.category_ids} isList />
            )}
            <div className="profile__btn-container">
              <AuthButton
                label={changePreferences}
                onClick={() => handlePopupOpen(PREFERENCES_POPUP_TYPE)}
              />
            </div>
          </div>

          <div className="profile__block">
            <h3 className="profile__title">{accounts}</h3>
            {!is_password_set && (
              <p className="profile__text profile__text_type_color">
                {notSetPasswordOauth}
              </p>
            )}
            <AccountsList />
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;
