import { useContext, useState } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import Popup from '../../Popup/Popup';
import AuthFormWrapper from '../AuthFormWrapper/AuthFormWrapper';
import './CheckCodePopup.css';
import AuthInput from '../AuthInput/AuthInput';
import { VERIFICATION_CODE_LENGTH } from '../../../assets/utils/constants';

function CheckCodePopup({
  popupName,
  onSubmit,
  apiError,
  isPreloader,
  value,
  setValue,
  onClose,
}) {
  const {
    checkCodePopup: { title, text, btnText },
    labels: { code },
    errors: { invalidCode, fieldRequired },
  } = useContext(TranslationContext);
  const [valueValidity, setValueValidity] = useState({
    validState: false,
    errorMessage: '',
  });

  function handleChange(e) {
    const value = e.target.value;
    const formattedValue = value.replace(/\D/g, '');
    const isValid = formattedValue.length === VERIFICATION_CODE_LENGTH;

    setValue(formattedValue);
    if (!formattedValue) {
      setValueValidity({
        validState: false,
        errorMessage: fieldRequired,
      });
    } else {
      setValueValidity({
        validState: isValid,
        errorMessage: isValid ? '' : invalidCode,
      });
    }
  }

  return (
    <Popup popupName={popupName} onClose={onClose} withCopyright>
      <AuthFormWrapper
        title={title}
        subtitle={text}
        buttonText={btnText}
        id={popupName}
        onSubmit={onSubmit}
        isFormValid={valueValidity.validState}
        isPreloader={isPreloader}
        error={apiError}
      >
        <AuthInput
          label={code}
          name="code"
          inputMode="numeric"
          autoComplete="off"
          value={value}
          onChange={handleChange}
          error={valueValidity}
        />
      </AuthFormWrapper>
    </Popup>
  );
}

export default CheckCodePopup;
