import { useRef, useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { motion, AnimatePresence } from 'framer-motion';
import {
  AUTH_LOGIN_LINK,
  AUTH_POPUP_TYPE,
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
  TAP_BTN_SCALE,
  AI_LINK,
  READER_MAIN_LINK,
} from '../../../../assets/utils/constants';
import './AiForm.css';
import { TopArrowIcon, EyeIcon } from '../../../../assets/icons/icons';
import ChatBubbleLoader from './ChatBubbleLoader/ChatBubbleLoader';
import mainApi from '../../../../assets/api/MainApi';
import tgFormApi from '../../../../assets/api/TgFormApi';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { parseStoryHTML } from '../../../../assets/utils/utils';
import { PopupContext } from '../../../../assets/contexts/popupContex';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import { UserContext } from '../../../../assets/contexts/userContext';
import AvatarBox from '../../../AvatarBox/AvatarBox';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.04, // Delay between each character
    },
  },
};

const instructionsVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05, // Delay between each character
    },
  },
};

const storyContainerVariants = {
  hidden: { opacity: 0, height: '0' },
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: {
        duration: BASE_TRANSITION_DURATION * 2,
        ease: BASE_TIMING_FUNCTION,
        type: 'tween',
      },
      staggerChildren: 0.02, // Delay between each character
    },
  },
};

const characterVariants = {
  hidden: { opacity: 0, y: 10, visibility: 'hidden' },
  visible: { opacity: 1, y: 0, visibility: 'visible' },
};

const contentVariants = {
  hidden: { opacity: 0, visibility: 'hidden' },
  visible: { opacity: 1, visibility: 'visible' },
};

export default function AiForm() {
  const {
    mainPage: {
      promo: {
        aiForm: { textList: textListPromo, readMore },
      },
    },
    aiStories: {
      aiBlock: {
        aiForm: {
          title: titleAiPage,
          text: textAiPage,
          instructions: instructionsAiPage,
        },
      },
    },
    placeholders: { name, email, fantasies },
    errors: { invalidEmail },
    apiErrors: { createStoryErrorMessage },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const nameInput = useRef();
  const emailInput = useRef();
  const fantasiesInput = useRef();
  const { width } = useWindowSize();

  const initialValues = {
    name: user?.personal_data?.name || '',
    email: user?.email || '',
    fantasies: '',
  };
  const initialValuesValidity = {
    name: { validState: Boolean(user?.personal_data?.name), errorMessage: '' },
    email: { validState: Boolean(user?.email), errorMessage: '' },
    fantasies: { validState: false, errorMessage: '' },
  };

  const [step, setStep] = useState(user ? 2 : 0);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isGreetingShown, setIsGreetingShown] = useState(false);
  const [parsedStory, setParsedStory] = useState(null);
  const [isStoryLoading, setIsStoryLoading] = useState(false);
  const [isNameTriggered, setNameTriggered] = useState(false);
  const [createdStoryId, setCreatedStoryId] = useState(undefined);

  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.replace('/', '').startsWith(AI_LINK) ||
    state?.background?.pathname.replace('/', '').startsWith(AI_LINK);

  const isFormValid =
    step === 0
      ? Boolean(values.name) && valuesValidity.name.validState
      : step === 1
      ? Boolean(values.email) && valuesValidity.email.validState
      : step === 2
      ? Boolean(values.fantasies) && valuesValidity.fantasies.validState
      : false;
  const maxChars = width > 760 ? 600 : width > 550 ? 450 : 300;

  useEffect(() => {
    if (user) {
      setStep(2);
      setValues(initialValues);
      setValuesValidity(initialValuesValidity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function handleChange(e) {
    const input = e.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity({
            ...valuesValidity,
            [name]: { validState: false, errorMessage: '' },
          });
        }

        if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity({
              ...valuesValidity,
              [name]: { validState: true, errorMessage: '' },
            });
          } else {
            setValuesValidity({
              ...valuesValidity,
              [name]: {
                validState: false,
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : invalidEmail,
              },
            });
          }
        }

        setValues({ ...values, [name]: value });
        break;

      default:
        setValues({ ...values, [name]: value });
        setValuesValidity({
          ...valuesValidity,
          [name]: { validState: Boolean(value), errorMessage: '' },
        });
    }
  }

  function focusOnInput() {
    if (nameInput?.current) nameInput.current.focus();
    if (emailInput?.current) emailInput.current.focus();
    if (fantasiesInput?.current) fantasiesInput.current.focus();
  }

  function nextStep() {
    setStep(step + 1);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!isFormValid) return;

    switch (step) {
      case 0:
        nextStep();
        if (!isAiStories) {
          setIsGreetingShown(true);
          setTimeout(() => {
            setIsGreetingShown(false);
            if (emailInput?.current) emailInput.current.focus();
          }, 4000);
        }
        break;

      case 1:
        nextStep();
        if (!isAiStories) {
          setTimeout(() => {
            if (fantasiesInput?.current) fantasiesInput.current.focus();
          }, 1000);
        }
        break;

      case 2:
        nextStep();
        setIsStoryLoading(true);

        tgFormApi
          .sendContactForm({
            name: `DEMO-PLATFORM - ` + values.name,
            email: values.email,
            story: values.fantasies,
          })
          .then(() => {})
          .catch(() => {});

        mainApi
          .createStory({ text: values.fantasies })
          .then((res) => {
            const story = parseStoryHTML(res.data, maxChars);
            setParsedStory(story);
            setCreatedStoryId(res._id);
          })
          .catch((err) => {
            console.log(err);
            const story = parseStoryHTML(createStoryErrorMessage, maxChars);
            setParsedStory(story);
          })
          .finally(() => {
            setIsStoryLoading(false);
          });
        break;

      default:
        nextStep();
    }
  }

  function handleMoreBtnClick() {
    if (user) {
      navigate(`/${AI_LINK}/${READER_MAIN_LINK}/${createdStoryId}`);
    } else {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
    }
  }

  return (
    <div className="ai-form">
      <div className="ai-form__container">
        <ul className="ai-form__heading">
          <motion.button
            className={`ai-form__logo-btn ${
              isAiStories ? 'ai-form__logo-btn_theme_dark' : ''
            }`}
            type="button"
            onClick={focusOnInput}
            whileTap={{ scale: TAP_BTN_SCALE }}
          >
            <EyeIcon
              mainClassName="ai-form__logo"
              fillClassName="ai-form__logo-fill"
            />
          </motion.button>

          {user && isAiStories && (
            <AvatarBox className="ai-form__avatar" data={user.personal_data} />
          )}

          {!user &&
            Object.values(values).map(
              (value, i) =>
                i < step && (
                  <motion.li
                    className="ai-form__data-item"
                    key={i}
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                  >
                    <p className="ai-form__data-value">
                      {value.split('').map((char, index) => (
                        <motion.span key={index} variants={characterVariants}>
                          {char === ' ' ? '\u00A0' : char}
                        </motion.span>
                      ))}
                    </p>
                  </motion.li>
                )
            )}
        </ul>

        <motion.div
          className="ai-form__content"
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <AnimatePresence mode="wait" initial={false}>
            {!isAiStories &&
              (step === 0 ? (
                isNameFocused || values.name ? (
                  <motion.h4
                    className="ai-form__title"
                    variants={containerVariants}
                    key="name-focus"
                  >
                    {textListPromo[step].focus.split('').map((char, index) => (
                      <motion.span key={index} variants={characterVariants}>
                        {char}
                      </motion.span>
                    ))}
                  </motion.h4>
                ) : (
                  <motion.h4
                    className="ai-form__title"
                    variants={containerVariants}
                    key="name-default"
                  >
                    {textListPromo[step].default
                      .split('')
                      .map((char, index) => (
                        <motion.span key={index} variants={characterVariants}>
                          {char}
                        </motion.span>
                      ))}
                  </motion.h4>
                )
              ) : step === 1 ? (
                isGreetingShown ? (
                  <motion.div
                    className="ai-form__text-box"
                    variants={containerVariants}
                    key="greeting"
                  >
                    <h4 className="ai-form__title">
                      {textListPromo[step].greeting
                        .title(values.name)
                        .split('')
                        .map((char, index) => (
                          <motion.span key={index} variants={characterVariants}>
                            {char}
                          </motion.span>
                        ))}
                    </h4>
                    <p className="ai-form__subtitle">
                      {textListPromo[step].greeting.subtitle
                        .split('')
                        .map((char, index) => (
                          <motion.span key={index} variants={characterVariants}>
                            {char}
                          </motion.span>
                        ))}
                    </p>
                  </motion.div>
                ) : (
                  <motion.h4
                    className="ai-form__title"
                    variants={containerVariants}
                    key="email-default"
                  >
                    {textListPromo[step].default
                      .split('')
                      .map((char, index) => (
                        <motion.span key={index} variants={characterVariants}>
                          {char}
                        </motion.span>
                      ))}
                  </motion.h4>
                )
              ) : step === 2 ? (
                <motion.h4
                  className="ai-form__title ai-form__title_width_restricted"
                  variants={containerVariants}
                  key="fantasies"
                >
                  {textListPromo[step].split('').map((char, index) => (
                    <motion.span key={index} variants={characterVariants}>
                      {char}
                    </motion.span>
                  ))}
                </motion.h4>
              ) : null)}

            {isAiStories && step < 3 && (
              <motion.div
                className="ai-form__text-box"
                variants={containerVariants}
                key="ai-page"
              >
                <h4 className="ai-form__title ai-form__title_size_medium">
                  {titleAiPage}
                </h4>
                {textAiPage && (
                  <p className="ai-form__text ai-form__text_type_stories">
                    {textAiPage}
                  </p>
                )}
                <motion.div
                  className="ai-form__instruction"
                  variants={instructionsVariants}
                  key={`ai-step-${step}`}
                >
                  <p className="ai-form__instruction-number">
                    {`0${user ? 1 : step + 1}`.split('').map((char, index) => (
                      <motion.span key={index} variants={characterVariants}>
                        {char}
                      </motion.span>
                    ))}
                  </p>
                  <p className="ai-form__instruction-text">
                    {instructionsAiPage[step].split('').map((char, index) => (
                      <motion.span key={index} variants={characterVariants}>
                        {char}
                      </motion.span>
                    ))}
                  </p>
                </motion.div>
              </motion.div>
            )}

            {step === 3 && (
              <motion.div
                className="ai-form__content-box"
                variants={containerVariants}
                key="result"
              >
                <h4 className="ai-form__title ai-form__title_size_medium">
                  {textListPromo[step](values.name)
                    .split('')
                    .map((char, index) => (
                      <motion.span key={index} variants={characterVariants}>
                        {char}
                      </motion.span>
                    ))}
                </h4>

                {isStoryLoading || !parsedStory ? (
                  <motion.div
                    className="ai-form__loader"
                    variants={contentVariants}
                  >
                    <ChatBubbleLoader />
                  </motion.div>
                ) : (
                  <motion.div
                    className="ai-form__story-box"
                    variants={storyContainerVariants}
                  >
                    {parsedStory.elements.map((element) => {
                      const renderContent = (content) => {
                        if (typeof content === 'string') {
                          return content.split('').map((char, index) => (
                            <motion.span
                              key={index}
                              variants={characterVariants}
                            >
                              {char}
                            </motion.span>
                          ));
                        }

                        return content.map((child, index) => {
                          switch (child.type) {
                            case 'text':
                              return renderContent(child.content);
                            case 'em':
                              return (
                                <em key={index}>
                                  {child.children.map((c) =>
                                    renderContent(c.content)
                                  )}
                                </em>
                              );
                            case 'b':
                              return (
                                <b key={index}>
                                  {child.children.map((c) =>
                                    renderContent(c.content)
                                  )}
                                </b>
                              );
                            default:
                              return null;
                          }
                        });
                      };

                      switch (element.type) {
                        case 'h2':
                          return (
                            <h2
                              className="ai-form__title ai-form__title_size_small"
                              key={element.key}
                            >
                              {element.children.map((child) =>
                                renderContent(child.content)
                              )}
                            </h2>
                          );
                        case 'p':
                          return (
                            <p className="ai-form__text" key={element.key}>
                              {element.children.map((child) => {
                                if (child.type === 'text') {
                                  return renderContent(child.content);
                                }
                                return renderContent([child]);
                              })}
                            </p>
                          );
                        default:
                          return null;
                      }
                    })}

                    {parsedStory.isTextTruncated && (
                      <motion.button
                        className="ai-form__more-btn"
                        type="button"
                        onClick={handleMoreBtnClick}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: TAP_BTN_SCALE }}
                        variants={contentVariants}
                      >
                        {readMore}
                      </motion.button>
                    )}
                  </motion.div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      <AnimatePresence>
        {step !== 3 && (
          <motion.form
            className={`ai-form__form ${
              !isNameTriggered && !isAiStories
                ? 'ai-form__form_first-not-triggered'
                : ''
            }`}
            onSubmit={handleSubmit}
            onClick={focusOnInput}
            initial={{ opacity: 1, visibility: 'visible', display: 'flex' }}
            exit={{ opacity: 0, visibility: 'hidden', display: 'none' }}
            transition={{ duration: 0.15 }}
            key="form"
          >
            {step === 0 && (
              <input
                className="ai-form__input"
                name="name"
                ref={nameInput}
                id="name"
                type="text"
                placeholder={name}
                value={values.name || ''}
                autoComplete="off"
                maxLength={256}
                onChange={handleChange}
                onFocus={() => {
                  setNameTriggered(true);
                  setIsNameFocused(true);
                }}
                onBlur={() => setIsNameFocused(false)}
              />
            )}

            {step === 1 && (
              <input
                className="ai-form__input"
                name="email"
                ref={emailInput}
                id="email"
                type="email"
                maxLength={256}
                placeholder={email}
                value={values.email || ''}
                onChange={handleChange}
              />
            )}

            {step === 2 && (
              <input
                className="ai-form__input"
                name="fantasies"
                id="fantasies"
                ref={fantasiesInput}
                type="text"
                maxLength={256}
                placeholder={fantasies}
                value={values.fantasies || ''}
                autoComplete="off"
                onChange={handleChange}
              />
            )}

            <div
              className="ai-form__submit-box"
              onClick={(e) => e.stopPropagation()}
            >
              <motion.button
                className={`ai-form__submit-btn ${
                  !isFormValid ? 'ai-form__submit-btn_disabled' : ''
                } ${isAiStories ? 'ai-form__submit-btn_theme_dark' : ''}`}
                type="submit"
                disabled={!isFormValid}
                whileHover={{ opacity: 0.85 }}
                whileTap={{ scale: TAP_BTN_SCALE }}
              >
                <TopArrowIcon
                  mainClassName="ai-form__submit-icon"
                  fillClassName="ai-form__submit-icon-fill"
                />
              </motion.button>
            </div>
          </motion.form>
        )}
      </AnimatePresence>
    </div>
  );
}
