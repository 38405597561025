export const EN_TRANSLATION = {
  auth: {
    login: {
      title: 'Log In',
      buttonText: 'Login',
      linkText: 'Sign Up',
    },

    register: {
      title: 'Registration',
      buttonText: 'Registration',
      linkText: 'Already have an account?',
    },

    registerOneMoreStep: {
      title: 'Just One More Step',
      subtitle:
        'We need your date of birth to&nbsp;finalize your account setup',
      buttonText: 'Continue',
    },

    preferences: {
      title: 'Select categories',
      skip: 'Skip',
      done: 'Done',
    },

    recoveryPassword: {
      redirectText: 'Forgot your password?',
      title: 'Password Recovery',
      buttonText: 'Reset Password',
      linkText: 'Back to Login',
      successText:
        'We have sent you an email with instructions to reset your password. Please check your inbox.',
    },

    resetPassword: {
      title: 'Reset Password',
      buttonText: 'Continue',
    },
  },

  verifyAgePopup: {
    subtitle: 'Verify Your Age',
    title: 'Are you 18 years old or older?',
  },

  confirmEmailPopup: {
    title: 'Confirm Email',
    text: 'We need to verify your email address to ensure the security of your account. Please resend the confirmation link and check your inbox for it.',
    btnText: 'Resend verification email',
    successMessage: 'Email sent',
  },

  checkCodePopup: {
    title: 'Verification Code',
    text: 'Enter the code sent to your email.',
    btnText: 'Verify',
  },

  labels: {
    name: 'Name',
    username: 'User name',
    dateOfBirth: 'Date of birth',
    email: 'E-mail',
    password: 'Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    code: 'Code',
    loadMore: 'Load more',
    loadMoreReply: 'Show more replies',
    creatorUserType: 'Creator',
    no: 'No',
    yes: 'Yes',
    save: 'Save',
    edit: 'Edit',
    changePhoto: 'Change photo',
    removePhoto: 'Remove photo',
    changePassword: 'Change password',
    ok: 'OK',
    cancel: 'Cancel',
  },

  placeholders: {
    name: "What's your name?",
    email: 'What is your email?',
    fantasies: 'Share your wildest desires...',
    search: 'Search',
  },

  errors: {
    fieldRequired: 'This field is required',
    invalidEmail: 'Invalid email address',
    passwordTooShort: 'Password must be at least 8 characters long',
    passwordTooLong: 'Password must be less than 32 characters long',
    invalidDateValue: 'Invalid date value',
    invalidDateMin: 'Date must be after 1900',
    invalidDateMax: 'Date must be at least 18 years ago',
    invalidCode: 'Invalid code',
  },

  apiErrors: {
    unknownError: 'Unknown error. Please try again later.',
    invalidRequest: 'Invalid request. Check the entered data.',
    unauthorized: 'Unauthorized. Please log in.',
    forbidden:
      'Access denied. You do not have permission to perform this action.',
    notFound: 'Resource not found. Check the request and try again.',
    conflict: 'Data conflict. The data already exists or is incorrect.',
    tooManyRequests: 'Too many requests. Please wait and try again later.',
    serverError: 'Server error. We are already working on fixing it.',
    gatewayError: 'Gateway error. Problems with connecting to the server.',
    serviceUnavailable:
      'Service temporarily unavailable. Please try again later.',
    requestTimeout: 'Request timeout. Please try again later.',
    clientError: 'Client error. Please check your request.',
    createStoryErrorMessage: "I'm sorry, I can't fulfill this request.",
    emailAlreadyExists: 'This email is already registered.',
    wrongCurrentPassword: 'The current password is incorrect.',
    passwordNotSet: 'Password is not set',
    wrongEmailOrPassword:
      'Wrong email or password. Check the entered data or reset your password.',
  },

  successMessages: {
    passwordChangeSuccess: 'Password changed successfully',
    emailChangeSuccess: 'Email changed successfully',
    nameChangeSuccess: 'Name changed successfully',
    dateOfBirthChangeSuccess: 'Date of birth changed successfully',
    avatarChangeSuccess: 'Avatar changed successfully',
    avatarRemoveSuccess: 'Avatar removed successfully',
    usernameChangeSuccess: 'Username changed successfully',
  },

  services: {
    catalog: 'Catalog',
    becomeCreator: 'Become Creator',
    copyright: `© ${new Date().getFullYear()} Muchi – All Rights Reserved`,
    balance: 'Balance',
  },

  mainPage: {
    banners: {
      readNow: 'Read Now',
      addToFavorites: 'Add story to favorites',
    },

    continueReading: {
      title: 'Continue Reading',
    },

    gallery: {
      free: 'FREE',
      seeAll: 'See All',
    },

    authorsCards: {
      stories: (count) =>
        count > 0
          ? `${count} ${count === 1 ? 'Story' : 'Stories'}`
          : 'No stories',
    },

    categoriesList: {
      title: 'Categories',
      more: 'MORE',
    },

    promo: {
      title: 'Your Fantasy, Unleashed by&nbsp;Our AI',
      cards: [
        {
          title: 'Share Your Ideas',
          text: 'Tell us about your fantasies.',
        },
        {
          title: 'Let Our AI Craft Your Story',
          text: 'Our advanced AI technology transforms your input into a&nbsp;captivating narrative.',
        },
        {
          title: 'Enjoy Your Exclusive Tale',
          text: "Immerse yourself in a&nbsp;story that's truly&nbsp;yours, anytime, anywhere.",
        },
      ],
      aiForm: {
        textList: [
          {
            default: 'let’s generate a story',
            focus: "What's your name?",
          },
          {
            greeting: {
              title: (name) => `Hello, ${name}!`,
              subtitle: 'Welcome to MUCHI Personal Stories Generator',
            },
            default: 'What is your email?',
          },
          'Tell me about your fantasies',
          (name) => `story for ${name}`,
        ],
        readMore: 'Read More...',
      },
    },
  },

  catalogDropdown: {
    title: 'Catalog',
    tags: 'Tags',
    categories: 'Categories',
    stories: 'Stories',
    seeAll: 'See All',
    emptyTags: 'No tags found',
    emptyCategories: 'No categories found',
    emptyStories: 'No stories found',
  },

  searchDropdown: {
    popularTags: 'Popular Tags',
    popularCategories: 'Popular Categories',
    popularStories: 'Popular Stories',
    all: 'All',
    stories: 'Stories',
    creators: 'Creators',
    categories: 'Categories',
    tags: 'Tags',
    more: 'View more results',
    noResults: 'No results found',
  },

  searchPage: {
    title: 'Search',
    titleFor: 'results for',
  },

  reader: {
    free: 'Free',
    gems: 'Gems',
    pages: 'Pages',
    moreStories: 'Other stories by the author',
    introduction: 'Introduction',

    paywall: {
      title: 'To continue reading, unlock this story!',
      button: (price) => `Unlock for ${price} Gems`,
      confirmPopup: {
        title: 'Are you sure you want to unlock this story?',
      },
    },

    comments: {
      title: 'Comments',
      noComments: `No comments yet. 
Be the first to share your thoughts!`,
      reply: 'Reply',
      addComment: 'Add comment',
      cancel: 'Cancel',
      replyTo: 'Reply to',
      edit: 'Edit',
      editComment: 'Edit comment',
      remove: 'Delete',
      confirmPopupTitle: 'Are you sure you want to delete this comment?',
      deletedComment: 'Comment has been deleted',
    },
  },

  storiesPage: {
    titleAll: 'All Stories',
    noStories: 'No stories found',
    stories: 'stories',
    category: 'category',
    tag: 'tag',
  },

  creatorsPage: {
    title: 'All Creators',
    noCreators: 'No creators found',
  },

  aiPage: {
    title: `Latest <span>AI</span> Stories`,
    noStories: 'No AI stories found',
  },

  categoriesPage: {
    title: 'All Categories',
    noCategories: 'No categories found',
  },

  tagsPage: {
    noTags: 'No tags found',
  },

  creatorPage: {
    backBtn: 'All Creators',
    addToFavorites: 'Add to Favorites',
    removeFromFavorites: 'Remove from Favorites',
    achievements: 'Achievements',
    categories: 'Categories',
    noStories: 'No stories found',
    noCategories: 'No categories found',
  },

  aiStories: {
    title: 'Stories',
    span: 'AI',
    swiperTitle: 'Latest AI Stories',

    aiBlock: {
      caption: 'Muchi AI',
      btnsLabels: ['Play', 'Sound', 'Copy', 'Settings'],
      aiForm: {
        title: 'Create erotic stories',
        text:
          'Unleash your desires and explore a world of tantalizing fantasies with our innovative \n' +
          'AI-powered story creator. Let your imagination roam free as you craft sensual, immersive narratives tailored to your every whim. Whether you yearn for sultry romance or daring escapades, our AI turns your prompts into steamy tales that captivate the senses. \n' +
          'Indulge in a journey where passion meets creativity—your own private realm of irresistible intrigue awaits.',
        instructions: ['Enter name', 'Enter email', 'Enter your fantasies'],
      },
    },
  },

  desk: {
    profile: {
      details: 'Profile details',
      accounts: 'Connected accounts',
      preferences: 'Preferences',
      changePreferences: 'Change preferences',
      password: 'Password',
      notSetPasswordDetails:
        'Please set your password to be able to change your profile details.',
      notSetPasswordOauth:
        'Please set your password to be able to disconnect your account.',

      accountsList: {
        google: {
          title: 'Google',
          subtitle: 'Connect to log in with your Google account',
        },

        connect: 'Connect',
        disconnect: 'Disconnect',
      },
    },

    favorites: {
      noFavoritesStories: 'No favorite stories found',
      noFavoritesCreators: 'No favorite creators found',
      noFavoritesTags: 'No favorite tags found',
      noFavoritesCategories: 'No favorite categories found',
      noFavoritesAi: 'No favorite AI stories found',
    },

    history: {
      noHistory: 'No history found',
    },
  },
};
