import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import AuthButton from '../../Auth/AuthButton/AuthButton';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import SuccessMessage from '../../SuccessMessage/SuccessMessage';
import './ConfirmEmailBlock.css';
import mainApi from '../../../assets/api/MainApi';
import { DESK_MAIN_LINK } from '../../../assets/utils/constants';

function ConfirmEmailBlock() {
  const {
    confirmEmailPopup: { title, text, btnText, successMessage },
  } = useContext(TranslationContext);
  const [isResendPreloader, setIsResendPreloader] = useState(false);
  const [resendError, showResendError] = useAutoDismissError();
  const [resendSuccess, showResendSuccess] = useAutoDismissError();
  const { parseApiError } = useParseApiError();
  const { pathname } = useLocation();
  const isDesk = pathname.includes(DESK_MAIN_LINK);

  function resendVerificationEmail() {
    setIsResendPreloader(true);
    mainApi
      .resendVerificationEmail()
      .then(() => {
        showResendSuccess(successMessage);
      })
      .catch((err) => {
        showResendError(parseApiError(err));
      })
      .finally(() => {
        setIsResendPreloader(false);
      });
  }

  return (
    <div
      className={`confirm-email-block profile__block ${
        !isDesk ? 'confirm-email-block_type_border' : ''
      }`}
    >
      <h3 className="confirm-email-block__title profile__title">{title}</h3>
      <div className="confirm-email-block__content profile__block-content">
        <p className="confirm-email-block__text profile__text">{text}</p>
        <ErrorMessage error={resendError} isApiError />
        <SuccessMessage message={resendSuccess} />
        <div className="confirm-email-block__btn-container profile__btn-container">
          <AuthButton
            label={btnText}
            onClick={resendVerificationEmail}
            isPreloader={isResendPreloader}
            isColorsInverted
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmailBlock;
