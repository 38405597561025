import { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PopupContext } from '../../../assets/contexts/popupContex';
import './Dropdown.css';
import {
  BASE_TIMING_FUNCTION,
  POPUP_TRANSITION_DURATION,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import { CloseIcon } from '../../../assets/icons/icons';

const defaultTransition = {
  duration: POPUP_TRANSITION_DURATION,
  ease: BASE_TIMING_FUNCTION,
};

const slideFromRightVariants = {
  opened: {
    x: 0,
    transition: defaultTransition,
  },
  closed: {
    x: '115%',
    transition: defaultTransition,
  },
};

const tooltipVariants = {
  opened: {
    opacity: 1,
    transition: defaultTransition,
  },
  closed: {
    opacity: 0,
    transition: defaultTransition,
  },
};

function Dropdown({
  children,
  popupName,
  onClose,
  openFromRight,
  slideFromTop,
  slideFromRight,
  fixed,
  withCloseBtn,
  tooltip,
}) {
  const { isPopupOpen: isOpen } = useContext(PopupContext);

  const dropdownVariants = {
    opened: {
      clipPath: 'inset(-15% -15% -15% -15% round 10px)',
      boxShadow: '0px 14px 24px 0px var(--box-shadow-primary-theme)',
      transition: {
        type: 'spring',
        bounce: 0,
        duration: POPUP_TRANSITION_DURATION * 1.5,
        delayChildren: POPUP_TRANSITION_DURATION / 3,
        staggerChildren: 0.05,
        delay: slideFromTop ? POPUP_TRANSITION_DURATION / 2 : 0,
      },
    },
    closed: {
      clipPath: openFromRight
        ? 'inset(0% 0% 100% 100% round 10px)'
        : slideFromTop
        ? 'inset(0% 0% 100% 0% round 10px)'
        : 'inset(0% 100% 100% 0% round 10px)',
      boxShadow: '0px 0px 0px 0px var(--box-shadow-primary-theme)',
      transition: {
        type: 'spring',
        bounce: 0,
        duration: POPUP_TRANSITION_DURATION * (slideFromTop ? 1 : 1.5),
      },
    },
  };

  return (
    <AnimatePresence>
      {isOpen[popupName] && (
        <>
          <motion.div
            className={`dropdown ${
              openFromRight || slideFromRight ? 'dropdown_open_from-right' : ''
            } ${slideFromTop ? 'dropdown_open_from-top' : ''} ${
              fixed ? 'dropdown_position_fixed' : ''
            } ${tooltip ? 'dropdown_type_tooltip' : ''}`}
            key={popupName}
            variants={
              tooltip
                ? tooltipVariants
                : slideFromRight
                ? slideFromRightVariants
                : dropdownVariants
            }
            initial="closed"
            animate="opened"
            exit="closed"
          >
            <div
              className={`dropdown__content ${
                fixed ? 'dropdown__content_full-height' : ''
              }`}
            >
              {withCloseBtn && (
                <motion.button
                  className="dropdown__close-btn"
                  type="button"
                  onClick={onClose}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: TAP_BTN_SCALE }}
                >
                  <CloseIcon
                    mainClassName="dropdown__close-icon"
                    fillClassName="dropdown__icon-fill"
                  />
                </motion.button>
              )}
              {children}
            </div>
          </motion.div>

          {!tooltip && <div className="dropdown__overlay" onClick={onClose} />}
        </>
      )}
    </AnimatePresence>
  );
}

export default Dropdown;
