import { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TranslationContext } from '../../../../../assets/contexts/translationContext';
import './AuthAccount.css';
import {
  OAUTH_GOOGLE_TYPE,
  TAP_BTN_SCALE,
} from '../../../../../assets/utils/constants';
import GoogleLoginBtn from '../GoogleLoginBtn/GoogleLoginBtn';
import MiniPreloader from '../../../../MiniPreloader/MiniPreloader';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import { UserContext } from '../../../../../assets/contexts/userContext';

const hoverBtnAnimation = {
  scale: 1.01,
};

const tapBtnAnimation = {
  scale: TAP_BTN_SCALE,
};

function AuthAccount({
  title,
  subtitle,
  onLogin,
  onLogout,
  isConnected,
  isPreloader,
  type,
  setIsPreloader,
  showError,
  error,
}) {
  const {
    desk: {
      profile: {
        accountsList: { connect, disconnect },
      },
    },
  } = useContext(TranslationContext);
  const {
    user: {
      reg_data: {
        stages: { is_email_verified },
        is_password_set,
      },
    },
  } = useContext(UserContext);
  const isDisconnectDisabled = !is_email_verified || !is_password_set;

  return (
    <div className="auth-account">
      <div className="auth-account__container">
        <div className="auth-account__info">
          <div className="auth-account__title-wrapper">
            <p className="auth-account__title">{title}</p>
            <AnimatePresence mode="wait" initial={false}>
              {isPreloader && (
                <motion.div
                  className="auth-account__title-preloader"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <MiniPreloader />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <p className="auth-account__subtitle">{subtitle}</p>
        </div>

        <AnimatePresence mode="popLayout" initial={false}>
          {isConnected && (
            <motion.button
              className="auth-account__btn auth-account__btn_type_disconnect"
              type="button"
              onClick={onLogout}
              whileHover={hoverBtnAnimation}
              whileTap={tapBtnAnimation}
              initial={{ opacity: 0 }}
              animate={{ opacity: isDisconnectDisabled ? 0.5 : 1 }}
              exit={{ opacity: 0 }}
              key="disconnect"
              disabled={isDisconnectDisabled}
              style={{
                pointerEvents: isDisconnectDisabled ? 'none' : 'auto',
                userSelect: isDisconnectDisabled ? 'none' : 'auto',
              }}
            >
              {disconnect}
            </motion.button>
          )}

          {!isConnected &&
            (type === OAUTH_GOOGLE_TYPE ? (
              <motion.div
                className="auth-account__google-login-wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="google-login"
              >
                <GoogleLoginBtn
                  setIsPreloader={setIsPreloader}
                  showError={showError}
                />
              </motion.div>
            ) : (
              <motion.button
                className="auth-account__btn"
                type="button"
                onClick={onLogin}
                whileHover={hoverBtnAnimation}
                whileTap={tapBtnAnimation}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="connect"
              >
                {connect}
              </motion.button>
            ))}
        </AnimatePresence>
      </div>
      <ErrorMessage error={error} />
    </div>
  );
}

export default AuthAccount;
