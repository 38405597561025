import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './ContinueCard.css';
import {
  READER_MAIN_LINK,
  CATALOG_PAGE_STORIES_LINK,
  CREATOR_LINK,
  BASE_TIMING_FUNCTION,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import { useImageLoader } from '../../../../assets/hooks/useImageLoader';

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function ContinueCard({
  author,
  title,
  _id,
  possession_data,
  meta,
  published_total_words,
}) {
  const navigate = useNavigate();
  const imgUrl = meta?.cover?.urls?.orig;
  const { isImgLoading } = useImageLoader(imgUrl);
  const progress = Math.round(
    (possession_data?.words_read / published_total_words) * 100
  );

  function navigateToCreator(evt) {
    evt.preventDefault();
    navigate(`/${CREATOR_LINK}/${author?._id}`);
  }

  return (
    <Link
      className="continue-card"
      to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${_id}`}
      state={{ disableScrollToTop: false }}
    >
      <motion.div
        className="continue-card__container"
        initial={{ borderColor: 'var(--block-background-primary)' }}
        whileHover={{ borderColor: 'var(--main-active-secondary)' }}
        whileTap={btnTapAnimation}
        transition={{ duration: 0.3, ease: BASE_TIMING_FUNCTION }}
      >
        <div className="continue-card__image-wrapper">
          <img
            className={`continue-card__image ${
              isImgLoading ? 'skeleton-loader' : ''
            }`}
            src={imgUrl}
            alt={meta?.cover?.description || title}
            loading="lazy"
          />
        </div>

        <div className="continue-card__content">
          <motion.button
            className="continue-card__link"
            type="button"
            onClick={navigateToCreator}
            whileHover={{ color: 'var(--main-active-primary)' }}
            whileTap={btnTapAnimation}
            transition={{ duration: 0.3, ease: BASE_TIMING_FUNCTION }}
          >
            <span className="continue-card__subtitle">
              {author?.personal_data?.name}
            </span>
          </motion.button>
          <h6 className="continue-card__title">{title}</h6>
          <div className="continue-card__progress-line">
            <div
              className="continue-card__progress"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </motion.div>
    </Link>
  );
}

export default ContinueCard;
