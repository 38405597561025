import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './AuthInput.css';
import { CloseEyeIcon, OpenEyeIcon } from '../../../assets/icons/icons';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import {
  AI_LINK,
  CATALOG_PAGE_MAIN_LINK,
} from '../../../assets/utils/constants';

function AuthInput({
  type = 'text',
  inputMode = 'text',
  label,
  name,
  value,
  autoComplete = 'off',
  error,
  onChange,
  ...props
}) {
  const [touched, setTouched] = useState(false);
  const [isLabelTransform, setLabelTransform] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { state } = useLocation();
  const isAiStories = state?.background?.pathname
    .replace('/', '')
    .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
    .startsWith(AI_LINK);

  useEffect(() => {
    if (isFocused) return;
    setLabelTransform(Boolean(value));
  }, [value, isFocused]);

  function handleFocus() {
    setLabelTransform(true);
    setIsFocused(true);
  }

  function handleBlur(evt) {
    const value = evt.target.value;
    setLabelTransform(Boolean(value));
    setIsFocused(false);
    setTouched(true);
    if (props.onBlur) props.onBlur();
  }

  function togglePasswordVisibility() {
    setIsPasswordVisible(!isPasswordVisible);
  }

  return (
    <div className="auth-input">
      <div
        className={`auth-input__container ${
          error?.errorMessage && touched ? 'auth-input__container_invalid' : ''
        } ${isFocused ? 'auth-input__container_focused' : ''} ${
          isAiStories ? 'auth-input__container_theme_dark' : ''
        }`}
      >
        <label
          className={`auth-input__label ${
            isLabelTransform ? 'auth-input__label_focused' : ''
          }`}
          htmlFor={name}
        >
          {label}
        </label>

        <input
          className={`auth-input__input ${
            props.icon || name.toLowerCase().includes('password')
              ? 'auth-input__input_with-icon'
              : ''
          } ${Boolean(value) ? 'auth-input__input_filled' : ''}`}
          name={name}
          id={name}
          type={
            props.isPassword ? (!isPasswordVisible ? 'password' : 'text') : type
          }
          inputMode={inputMode}
          value={value || ''}
          autoComplete={autoComplete}
          onChange={!props.readOnly ? onChange : undefined}
          onFocus={!props.readOnly ? handleFocus : undefined}
          onBlur={!props.readOnly ? handleBlur : undefined}
          onFocusCapture={!props.readOnly ? handleFocus : undefined}
          maxLength={props.maxLength || 1000}
          max={props.max || undefined}
          readOnly={props.readOnly || false}
        />

        {name.toLowerCase().includes('password') && (
          <button
            className="auth-input__icon-box auth-input__icon-box_type_btn"
            type="button"
            onClick={togglePasswordVisibility}
          >
            <AnimatePresence initial={false} mode="popLayout">
              {isPasswordVisible ? (
                <motion.div
                  className="auth-input__icon-wrapper"
                  key="password-visible"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <OpenEyeIcon
                    mainClassName="auth-input__icon"
                    fillClassName="auth-input__icon-fill"
                  />
                </motion.div>
              ) : (
                <motion.div
                  className="auth-input__icon-wrapper"
                  key="password-hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <CloseEyeIcon
                    mainClassName="auth-input__icon"
                    fillClassName="auth-input__icon-fill"
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        )}

        {props.icon && (
          <div className="auth-input__icon-box">
            {props.icon({
              mainClassName: 'auth-input__icon',
              fillClassName: 'auth-input__icon-fill',
            })}
          </div>
        )}
      </div>

      <ErrorMessage error={touched ? error?.errorMessage : ''} />
    </div>
  );
}

export default AuthInput;
