import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  ProfileIcon,
  FavoritesIcon,
  HistoryIcon,
  StudioIcon,
  MyStoriesIcon,
  BecomeCreatorIcon,
  LogoutIcon,
} from '../icons/icons';

export const MAIN_URL = 'https://app-v2.muchi.io/api';
export const TG_FORM_API_URL =
  'https://tg-function.yc-cabatest.ru/CABA_NOTIFIER/';

export const OAUTH_GOOGLE_TYPE = 'google';
export const OAUTH_GOOGLE_CLIENT_ID =
  '330809491788-9koii3i9pgqsek3pfo61jlts96shg5t6.apps.googleusercontent.com';

export const EN_LANGUAGE = 'EN';
export const VALIDE_LANG = ['EN'];

export const USER_CREATOR_TYPE = 'creator';
export const USER_READER_TYPE = 'reader';

export const BANNERS_BLOCK_TYPE = 'banners';
export const BOOKS_BLOCK_TYPE = 'books';
export const STORIES_BLOCK_TYPE = 'stories';
export const TAGS_BLOCK_TYPE = 'tags';
export const CATEGORIES_BLOCK_TYPE = 'categories';
export const AUTHORS_BLOCK_TYPE = 'authors';
export const PROMO_BLOCK_TYPE = 'promo';

export const POPUP_TYPE = 'popup';
export const AUTH_POPUP_TYPE = `auth-${POPUP_TYPE}`;
export const PREFERENCES_POPUP_TYPE = `preferences-${POPUP_TYPE}`;
export const VERIFY_AGE_POPUP_TYPE = `verify-age-${POPUP_TYPE}`;
export const CONFIRM_EMAIL_POPUP_TYPE = `confirm-email-${POPUP_TYPE}`;
export const CHECK_CODE_EMAIL_POPUP_TYPE = `check-code-email-${POPUP_TYPE}`;
export const CONFIRM_POPUP_TYPE = `confirm-${POPUP_TYPE}`;
export const MESSAGE_POPUP_TYPE = `message-${POPUP_TYPE}`;
export const COMMENTS_POPUP_TYPE = `comments-${POPUP_TYPE}`;
export const COMMENT_FORM_POPUP_TYPE = `comment-form-${POPUP_TYPE}`;

export const DROPDOWN_TYPE = 'dropdown';
export const PROFILE_DROPDOWN_TYPE = `profile-${DROPDOWN_TYPE}`;
export const CATALOG_DROPDOWN_TYPE = `catalog-${DROPDOWN_TYPE}`;
export const SEARCH_DROPDOWN_TYPE = `search-${DROPDOWN_TYPE}`;

export const TOOLTIP_TYPE = 'tooltip';
export const ACHIEVEMENT_TOOLTIP_TYPE = `achievement-${TOOLTIP_TYPE}`;

export const AGE_VERIFIED = 'age_submited';
export const PREFERENCES_LAST_VIEWED = 'preferences_last_viewed';
export const CONFIRM_EMAIL_LAST_VIEWED = 'confirm_email_last_viewed';

export const THUMBNAIL_BREAKPOINT = 320;
export const MOBILE_BREAKPOINT = 780;
export const TABLET_BREAKPOINT = 1100;
export const DESKTOP_BREAKPOINT = 1440;
export const CONTAINER_MAX_WIDTH = 1240;
export const CREATOR_BG_BREAKPOINT = 580;

export const FOOTER_CATEGORIES_ROW_COUNT = 11;
export const CATALOG_POPUP_DEFAULT_ROW_COUNT = 5;
export const CATALOG_POPUP_STORIES_ROW_COUNT = 2;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 32;

export const READER_LARGE_SCREEN_PAGE_SIZE = 6000;
export const READER_DESCTOP_PAGE_SIZE = 3000;
export const READER_TABLET_PAGE_SIZE = 2000;
export const READER_MOBILE_PAGE_SIZE = 1500;

export const HEADING_BLOCK_1 = 'heading_1';
export const HEADING_BLOCK_2 = 'heading_2';
export const HEADING_BLOCK_3 = 'heading_3';
export const PARAGRAPH_BLOCK = 'text';
export const QUOTE_BLOCK = 'quote';
export const DIALOGUE_LEFT_BLOCK = 'dialogue_left';
export const DIALOGUE_RIGHT_BLOCK = 'dialogue_right';
export const CHILD_TYPE_BOLD = 'bold';
export const CHILD_TYPE_ITALIC = 'italic';
export const CHILD_TYPE_UNDERLINE = 'underline';
export const CHILD_TYPE_STRIKETHROUGH = 'strikethrough';

export const TAGS_TYPE = 'tags';
export const TAG_TYPE = 'tag';
export const CATEGORIES_TYPE = 'categories';
export const CATEGORY_TYPE = 'category';
export const STORIES_TYPE = 'stories';
export const STORIES_API_TYPE = 'books';
export const AI_TYPE = 'ai-stories';
export const AI_API_TYPE = 'ai_books';
export const CREATORS_TYPE = 'creators';
export const CREATORS_API_TYPE = 'authors';
export const CREATOR_TYPE = 'creator';
export const ALL_TYPE = 'tags,categories,books,authors';
export const TOP_STORIES_TYPE = 'top_stories';
export const TOP_CATEGORIES_TYPE = 'top_categories';

export const SEARCH_TYPES = [
  ALL_TYPE,
  STORIES_API_TYPE,
  CREATORS_API_TYPE,
  CATEGORIES_TYPE,
  TAGS_TYPE,
];

export const SEARCH_VALUE_KEY = 'value';
export const SEARCH_TYPE_KEY = 'type';
export const READ_MARK_KEY = 'read_mark';

export const READER_MAIN_LINK = 'reader';
export const SEARCH_PAGE_MAIN_LINK = 'search';
export const AI_LINK = AI_TYPE;

export const CATALOG_PAGE_MAIN_LINK = 'catalog';
export const CATALOG_PAGE_STORIES_LINK = `${CATALOG_PAGE_MAIN_LINK}/${STORIES_TYPE}`;
export const CATALOG_PAGE_CREATORS_LINK = `${CATALOG_PAGE_MAIN_LINK}/${CREATORS_TYPE}`;
export const CATALOG_PAGE_AI_LINK = `${CATALOG_PAGE_MAIN_LINK}/${AI_TYPE}`;
export const CATALOG_PAGE_TAGS_LINK = `${CATALOG_PAGE_MAIN_LINK}/${TAGS_TYPE}`;
export const CATALOG_PAGE_CATEGORIES_LINK = `${CATALOG_PAGE_MAIN_LINK}/${CATEGORIES_TYPE}`;

export const CREATOR_LINK = `${CATALOG_PAGE_CREATORS_LINK}/${CREATOR_TYPE}`;

export const STORIES_NAV_LINK = {
  label: { en: 'Stories' },
  path: CATALOG_PAGE_STORIES_LINK,
};
export const CREATORS_NAV_LINK = {
  label: { en: 'Creators' },
  path: CATALOG_PAGE_CREATORS_LINK,
};
export const AI_NAV_LINK = {
  label: { en: 'Ai Stories' },
  path: AI_LINK,
};
export const HEADER_NAV_LIST = [
  STORIES_NAV_LINK,
  CREATORS_NAV_LINK,
  AI_NAV_LINK,
];

export const CATALOG_POPUP_MAIN_LINK = 'catalog-popup';
export const CATALOG_POPUP_TAGS_LINK = `/${CATALOG_POPUP_MAIN_LINK}/${TAGS_TYPE}`;
export const CATALOG_POPUP_CATEGORIES_LINK = `/${CATALOG_POPUP_MAIN_LINK}/${CATEGORIES_TYPE}`;
export const CATALOG_POPUP_STORIES_LINK = `/${CATALOG_POPUP_MAIN_LINK}/${STORIES_TYPE}`;

export const CATALOG_NAV_LIST = [
  {
    label: { en: 'Categories' },
    link: CATALOG_POPUP_CATEGORIES_LINK,
  },
  {
    label: { en: 'Tags #' },
    link: CATALOG_POPUP_TAGS_LINK,
  },
  {
    label: { en: 'Stories' },
    link: CATALOG_POPUP_STORIES_LINK,
  },
];

export const STORIES_TYPE_LINK = {
  label: { en: 'Stories' },
  path: STORIES_TYPE,
  type: STORIES_API_TYPE,
};
export const CREATORS_TYPE_LINK = {
  label: { en: 'Creators' },
  path: CREATORS_TYPE,
  type: CREATORS_API_TYPE,
};
export const AI_TYPE_LINK = {
  label: { en: 'AI Stories' },
  path: AI_TYPE,
  type: AI_API_TYPE,
};
export const CATEGORIES_TYPE_LINK = {
  label: { en: 'Categories' },
  path: CATEGORIES_TYPE,
  type: CATEGORIES_TYPE,
};
export const TAGS_TYPE_LINK = {
  label: { en: 'Tags #' },
  path: TAGS_TYPE,
  type: TAGS_TYPE,
};
export const CONTENT_TYPES_NAV_LIST = [
  STORIES_TYPE_LINK,
  CREATORS_TYPE_LINK,
  AI_TYPE_LINK,
  // CATEGORIES_TYPE_LINK,
  // TAGS_TYPE_LINK,
];

export const RESET_PASSWORD_TOKEN_KEY = 'token';

export const AUTH_SIGNUP_TYPE = 'signup';
export const AUTH_SIGNUP_ONE_MORE_STEP_TYPE = 'signup-one-more-step';
export const AUTH_LOGIN_TYPE = 'login';
export const AUTH_RECOVERY_TYPE = 'recovery';
export const AUTH_RESET_PASSWORD_TYPE = 'reset-password';

export const AUTH_MAIN_LINK = 'auth';
export const AUTH_SIGNUP_LINK = `/${AUTH_MAIN_LINK}/${AUTH_SIGNUP_TYPE}`;
export const AUTH_SIGNUP_ONE_MORE_STEP_LINK = `/${AUTH_MAIN_LINK}/${AUTH_SIGNUP_ONE_MORE_STEP_TYPE}`;
export const AUTH_LOGIN_LINK = `/${AUTH_MAIN_LINK}/${AUTH_LOGIN_TYPE}`;
export const AUTH_RECOVERY_LINK = `/${AUTH_MAIN_LINK}/${AUTH_RECOVERY_TYPE}`;
export const AUTH_RESET_PASSWORD_LINK = `/${AUTH_MAIN_LINK}/${AUTH_RESET_PASSWORD_TYPE}`;

export const DESK_PROFILE_TYPE = 'profile';
export const DESK_FAVORITES_TYPE = 'favorites';
export const DESK_HISTORY_TYPE = 'history';
export const DESK_BECOME_CREATOR_TYPE = 'become-creator';

export const DESK_MAIN_LINK = 'desk';
export const DESK_PROFILE_LINK = `/${DESK_MAIN_LINK}/${DESK_PROFILE_TYPE}`;
export const DESK_FAVORITES_LINK = `/${DESK_MAIN_LINK}/${DESK_FAVORITES_TYPE}`;
export const DESK_HISTORY_LINK = `/${DESK_MAIN_LINK}/${DESK_HISTORY_TYPE}`;

export const CREATOR_STUDIO_URL = 'https://studio.muchi.io';
export const MY_STORIES_URL = 'https://studio.muchi.io/my-stories';
export const BECOME_CREATOR_URL = CREATOR_STUDIO_URL;

export const ITEM_LINK_TYPE = 'link';
export const ITEM_BUTTON_TYPE = 'button';
export const BUTTON_LOGOUT_ACTION_TYPE = 'logout';

export const PROFILE_LINK = {
  label: { en: 'Profile' },
  path: DESK_PROFILE_LINK,
  icon: ProfileIcon,
  type: ITEM_LINK_TYPE,
};
export const FAVORITES_LINK = {
  label: { en: 'Favorites' },
  path: DESK_FAVORITES_LINK,
  icon: FavoritesIcon,
  type: ITEM_LINK_TYPE,
};
export const HISTORY_LINK = {
  label: { en: 'Browsing History' },
  path: DESK_HISTORY_LINK,
  icon: HistoryIcon,
  type: ITEM_LINK_TYPE,
};
export const CREATOR_STUDIO_LINK = {
  label: { en: "Creator's Studio" },
  path: CREATOR_STUDIO_URL,
  icon: StudioIcon,
  type: ITEM_LINK_TYPE,
};
export const MY_STORIES_LINK = {
  label: { en: 'My Stories' },
  path: MY_STORIES_URL,
  icon: MyStoriesIcon,
  type: ITEM_LINK_TYPE,
};
export const BECOME_CREATOR_LINK = {
  label: { en: 'Become a Creator' },
  path: BECOME_CREATOR_URL,
  icon: BecomeCreatorIcon,
  type: ITEM_LINK_TYPE,
};
export const LOGOUT_BUTTON_LINK = {
  label: { en: 'Logout' },
  icon: LogoutIcon,
  type: ITEM_BUTTON_TYPE,
  action: BUTTON_LOGOUT_ACTION_TYPE,
};

export const PROFILE_NAV_LIST = [PROFILE_LINK, FAVORITES_LINK, HISTORY_LINK];

export const FOOTER_SERVICE_LINK = {
  label: { en: 'Terms of Service' },
  path: '/terms-of-service',
};
export const FOOTER_PRIVACY_LINK = {
  label: { en: 'Privacy Policy' },
  path: '/privacy-policy',
};
export const FOOTER_NAV_LIST = [FOOTER_SERVICE_LINK, FOOTER_PRIVACY_LINK];

export const FACEBOOK_LINK = {
  label: 'Facebook',
  link: undefined,
  icon: FacebookIcon,
};
export const INSTAGRAM_LINK = {
  label: 'Instagram',
  link: undefined,
  icon: InstagramIcon,
};
export const TWITTER_LINK = {
  label: 'X',
  link: undefined,
  icon: TwitterIcon,
};
export const SOCIAL_LINKS = [FACEBOOK_LINK, INSTAGRAM_LINK, TWITTER_LINK];

export const TAP_BTN_SCALE = 0.95;
export const BASE_TRANSITION_DURATION = 0.8;
export const POPUP_TRANSITION_DURATION = 0.6;
export const CONTENT_TRANSITION_DURATION = 0.15;
export const BASE_TIMING_FUNCTION = 'easeInOut';
export const BANNER_AUTOPLAY_DELAY = 10000;
export const GEMS_NUMBER_ANIMATION_DURATION = 15;
export const NEW_COMMENT_ANIMATION_DURATION = 2;

export const DROPDOWN_ITEMS_VARIANTS = {
  opened: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: POPUP_TRANSITION_DURATION / 3 },
  },
};

export const ENTER_KEY = 'Enter';
export const ESCAPE_KEY = 'Escape';

export const KEY_UP_EVENT_TYPE = 'keyup';

export const CATALOG_DESKTOP_CARDS_NUMBER = 15;
export const CATALOG_TABLET_CARDS_NUMBER = 12;
export const CATALOG_MOBILE_CARDS_NUMBER = 6;

export const CATALOG_DESKTOP_SMALL_CARDS_NUMBER = 24;
export const CATALOG_TABLET_SMALL_CARDS_NUMBER = 20;
export const CATALOG_MOBILE_SMALL_CARDS_NUMBER = 12;

export const DESK_DESKTOP_CARDS_NUMBER = 24;
export const DESK_TABLET_CARDS_NUMBER = 12;

export const DEFAULT_MAX_AVATAR_SIZE = 5;

export const VERIFICATION_CODE_LENGTH = 6;

export const IMAGE_AVATARS_TYPE = 'avatars';
export const IMAGE_COVERS_TYPE = 'story_cover';
export const IMAGE_IMAGES_TYPE = 'images';

export const DEFAULT_DATA_LIMIT = 3;
export const MIN_DATA_LIMIT = 1;
export const MAX_DATA_LIMIT = 25;
export const CATALOG_POPUP_DATA_LIMIT = 15;
export const TOP_STORIES_DATA_LIMIT = 10;
export const COMMENTS_DATA_LIMIT = 10;

export const ALL_SEARCH_POPUP_LIMIT = 6;
export const CATEGORIES_SEARCH_POPUP_LIMIT = 3;
export const TAGS_SEARCH_POPUP_LIMIT = 4;
export const DEFAULT_SEARCH_POPUP_LIMIT = 2;

export const SEARCH_PAGE_DESKTOP_LIMIT = 25;
export const SEARCH_PAGE_TABLET_LIMIT = 18;
export const SEARCH_PAGE_MOBILE_LIMIT = 10;

export const AI_PAGE_CARDS_DESKTOP_LIMIT = 6;
export const AI_PAGE_CARDS_TABLET_LIMIT = 5;

export const DATA_ID_TYPE_KEY = '_id';
export const DATA_SLUG_TYPE_KEY = 'slug';

export const CREATOR_ID_TYPE_KEY = '_id';
export const CREATOR_USERNAME_TYPE_KEY = 'personal_data.username';

export const SEARCH_STRING_MIN_LENGTH = 1;
export const SEARCH_STRING_MAX_LENGTH = 100;

export const PRELOADER_MAIN_TYPE = 'main';
export const PRELOADER_MORE_TYPE = 'more';

export const FREE_ACCESS_TYPE = 'free';
export const PAID_ACCESS_TYPE = 'bought';
export const OWNER_ACCESS_TYPE = 'owner';
