import { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './CategoriesList.css';
import {
  CATALOG_PAGE_CATEGORIES_LINK,
  CATEGORY_TYPE,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { FireIcon, TriangleArrowIcon } from '../../../assets/icons/icons';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import { DataContext } from '../../../assets/contexts/dataContext';

const linkHoverAnimation = {
  scale: 1.01,
};

const linkTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function CategoriesList({ block, data, showAll, isPreloader, noDataText }) {
  const { frontend_data } = block;

  const {
    mainPage: {
      categoriesList: { more, title: defaultTitle },
    },
  } = useContext(TranslationContext);
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const isMobileWidth = width <= MOBILE_BREAKPOINT;
  const isTabletWidth = width <= TABLET_BREAKPOINT;
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  const thirdLineRef = useRef(null);
  const refs = [firstLineRef, secondLineRef, thirdLineRef];
  const visibleRefs = isMobileWidth ? refs : refs.slice(0, 2);
  const dotsRef = useRef(null);
  const [dotsNumber, setDotsNumber] = useState(0);
  const [visibleCategories, setVisibleCategories] = useState([]);

  useEffect(() => {
    const divider = width > MOBILE_BREAKPOINT ? 38 : 15;
    const containerWidth = dotsRef?.current?.clientWidth;
    const dotsNumber = containerWidth
      ? Math.floor(containerWidth / divider)
      : 0;
    setDotsNumber(dotsNumber);
  }, [dotsRef, width]);

  useEffect(() => {
    if (data?.length === 0 || showAll) return;

    const padding = isMobileWidth ? 11 : isTabletWidth ? 17 : 29;
    const iconWidth = isMobileWidth ? 26 : isTabletWidth ? 54 : 68;
    const moreElement = document.createElement('div');
    moreElement.className = 'categories-list__text';
    moreElement.style.visibility = 'hidden';
    moreElement.style.position = 'absolute';
    moreElement.innerText = 'MORE';
    document.body.appendChild(moreElement);
    const moreWidth = moreElement.offsetWidth + padding * 2 + 20;
    document.body.removeChild(moreElement);

    const updateVisibleLinks = () => {
      const categoriesListToShow = [];
      let categories = [...data];

      visibleRefs.forEach((ref, i) => {
        const withMore = i === visibleRefs.length - 1;

        if (ref?.current) {
          const maxAllowedWidth = ref.current.offsetWidth;
          if (maxAllowedWidth <= 0) return;
          let totalWidth = i === 0 ? iconWidth : withMore ? moreWidth : 0;
          const categoriesToShow = [];

          categories.forEach((category, c) => {
            const categoryElement = document.createElement('div');
            categoryElement.className = 'categories-list__text';
            categoryElement.style.visibility = 'hidden';
            categoryElement.style.position = 'absolute';
            categoryElement.innerText = category.title?.[lang];
            document.body.appendChild(categoryElement);
            const categoryWidth = categoryElement.offsetWidth + padding * 2;
            document.body.removeChild(categoryElement);

            if (totalWidth + categoryWidth <= maxAllowedWidth) {
              categoriesToShow.push(category);
              totalWidth += categoryWidth;
            }
          });

          categoriesListToShow.push(categoriesToShow);
          categories = categories.filter((category) =>
            categoriesToShow.every((c) => c._id !== category._id)
          );
        }
      });

      setVisibleCategories(categoriesListToShow);
    };

    updateVisibleLinks();

    window.addEventListener('resize', updateVisibleLinks);
    return () => window.removeEventListener('resize', updateVisibleLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width]);

  return (
    <section
      className="categories-list"
      style={{ '--dots-list-length': dotsNumber }}
    >
      <div className="categories-list__heading">
        <h2 className="categories-list__title">
          {frontend_data?.title?.[lang] || defaultTitle}
        </h2>
        <ul className="categories-list__dots" ref={dotsRef}>
          {Array.from({ length: dotsNumber * 3 }, (_, index) => (
            <li key={index} className="categories-list__dot" />
          ))}
        </ul>
      </div>

      {showAll ? (
        <div className="categories-list__content">
          {isPreloader ? (
            <MiniPreloader />
          ) : data?.length > 0 ? (
            <ul className="categories-list__list">
              <div className="categories-list__icon-box">
                <FireIcon
                  mainClassName="categories-list__icon"
                  fillClassName="categories-list__icon-fill"
                />
              </div>
              {data.map((category) => (
                <motion.li
                  key={category._id}
                  className="categories-list__category-box"
                  whileHover={linkHoverAnimation}
                  whileTap={linkTapAnimation}
                >
                  <Link
                    to={`/${CATALOG_PAGE_CATEGORIES_LINK}/${CATEGORY_TYPE}/${category.slug}`}
                    className="categories-list__category-link"
                    state={{ disableScrollToTop: false }}
                  >
                    <p className="categories-list__text">
                      {category.title?.[lang]}
                    </p>
                  </Link>
                </motion.li>
              ))}
            </ul>
          ) : (
            <p className="categories-list__no-data">{noDataText}</p>
          )}
        </div>
      ) : (
        <div className="categories-list__content">
          {visibleRefs.map((ref, i) => (
            <ul className="categories-list__line" ref={ref} key={i}>
              {i === 0 && (
                <div className="categories-list__icon-box">
                  <FireIcon
                    mainClassName="categories-list__icon"
                    fillClassName="categories-list__icon-fill"
                  />
                </div>
              )}
              {visibleCategories[i]?.map((category) => (
                <motion.li
                  key={category._id}
                  className="categories-list__category-box"
                  whileHover={linkHoverAnimation}
                  whileTap={linkTapAnimation}
                >
                  <Link
                    to={`/${CATALOG_PAGE_CATEGORIES_LINK}/${CATEGORY_TYPE}/${category.slug}`}
                    className="categories-list__category-link"
                    state={{ disableScrollToTop: false }}
                  >
                    <p className="categories-list__text">
                      {category.title?.[lang]}
                    </p>
                  </Link>
                </motion.li>
              ))}
              {i === visibleCategories.length - 1 && (
                <motion.li
                  className="categories-list__category-box"
                  whileHover={linkHoverAnimation}
                  whileTap={linkTapAnimation}
                >
                  <Link
                    to={`/${CATALOG_PAGE_CATEGORIES_LINK}`}
                    className="categories-list__category-link"
                    state={{ disableScrollToTop: false }}
                  >
                    <p className="categories-list__text">{more}</p>
                    <TriangleArrowIcon
                      mainClassName="categories-list__arrow"
                      fillClassName="categories-list__arrow-fill"
                    />
                  </Link>
                </motion.li>
              )}
            </ul>
          ))}
        </div>
      )}
    </section>
  );
}

export default CategoriesList;
