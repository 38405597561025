import { useContext } from 'react';
import { motion } from 'framer-motion';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import {
  SEARCH_TYPES,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import './SearchNavBar.css';

function SearchNavBar({ searchType, setSearchType, isPage = false }) {
  const {
    searchDropdown: { all, stories, creators, categories, tags },
  } = useContext(TranslationContext);
  const navLabels = [all, stories, creators, categories, tags];

  return (
    <ul
      className={`search-nav-bar ${isPage ? 'search-nav-bar_type_page' : ''}`}
    >
      {SEARCH_TYPES.map((type, i) => (
        <li key={type} className="search-nav-bar__item">
          <motion.button
            className="search-nav-bar__button"
            onClick={() => setSearchType(type)}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: TAP_BTN_SCALE }}
            animate={{
              backgroundColor:
                searchType === type
                  ? 'var(--neutral-active-theme)'
                  : 'var(--neutral-bg-opacity-primary)',
              color:
                searchType === type
                  ? 'var(--neutral-contrast-active-theme)'
                  : 'var(--neutral-active-theme)',
            }}
            style={{
              pointerEvents: searchType === type ? 'none' : 'auto',
              userSelect: searchType === type ? 'none' : 'auto',
            }}
          >
            {navLabels[i]}
          </motion.button>
        </li>
      ))}
    </ul>
  );
}

export default SearchNavBar;
