import { useEffect, useContext, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import './CatalogPageTemplate.css';
import {
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
} from '../../../assets/utils/constants';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { UserContext } from '../../../assets/contexts/userContext';
import ConfirmEmailBlock from '../../Desk/ConfirmEmailBlock/ConfirmEmailBlock';

const contentVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

function PageTemplate({
  title,
  data,
  getData,
  children,
  noDataText,
  isSmallCards,
  hasMore,
  isPreloader,
  isBlock = false,
  hasAllData = false,
  isStoriesPage = false,
}) {
  const {
    labels: { loadMore },
  } = useContext(TranslationContext);
  const { user, isUserLoading } = useContext(UserContext);
  const isEmailVerified = user?.reg_data?.stages?.is_email_verified;
  const moreBtnRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (data || !width || isUserLoading || (isStoriesPage && !hasAllData))
      return;

    getData(PRELOADER_MAIN_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, user, isUserLoading, hasAllData]);

  useEffect(() => {
    // Настраиваем Intersection Observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore) getData(PRELOADER_MORE_TYPE);
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    const moreBtnRefCurrent = moreBtnRef?.current;
    if (moreBtnRefCurrent) {
      observer.observe(moreBtnRefCurrent);
      return () => observer.unobserve(moreBtnRefCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, data]);

  return (
    <motion.div
      className={`catalog-page ${
        isSmallCards ? 'catalog-page_type_small' : ''
      } ${isBlock ? 'catalog-page_type_block' : ''}`}
      initial="initial"
      animate="animate"
      exit="initial"
    >
      <h1
        className="catalog-page__title"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      {user && !isEmailVerified && !isBlock && <ConfirmEmailBlock />}

      <AnimatePresence initial={false} mode="wait">
        {isPreloader[PRELOADER_MAIN_TYPE] ? (
          <motion.div
            className="catalog-page__preloader"
            variants={contentVariants}
            key="preloader"
          >
            <MiniPreloader />
          </motion.div>
        ) : (
          <motion.div
            className="catalog-page__content"
            variants={contentVariants}
            key="content"
          >
            {data?.length > 0 ? (
              <ul
                className={`catalog-page__list ${
                  isSmallCards ? 'catalog-page__list_cards_small' : ''
                }`}
              >
                {children}
              </ul>
            ) : (
              <p className="catalog-page__text">{noDataText}</p>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {hasMore && (
          <motion.button
            ref={moreBtnRef}
            className="catalog-page__more-button"
            type="button"
            variants={contentVariants}
            style={{
              opacity: isPreloader[PRELOADER_MORE_TYPE] ? 1 : 0,
            }}
          >
            {isPreloader[PRELOADER_MORE_TYPE] ? <MiniPreloader /> : loadMore}
          </motion.button>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

function CustomPageTemplate({ children }) {
  return (
    <motion.div
      className="catalog-page catalog-page_type_custom"
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
}

function CatalogPageTemplate({ isCustomPage, ...props }) {
  return isCustomPage ? (
    <CustomPageTemplate {...props} />
  ) : (
    <PageTemplate {...props} />
  );
}

export default CatalogPageTemplate;
