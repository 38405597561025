import { DialogueIcon } from '../../../../../assets/icons/icons';
import {
  DIALOGUE_LEFT_BLOCK,
  DIALOGUE_RIGHT_BLOCK,
} from '../../../../../assets/utils/constants';
import { renderStoryChildren } from '../../../../../assets/utils/utils';
import './DialogueTextElement.css';

function DialogueTextElement({ elem }) {
  const { block_type, data } = elem;

  return (
    <div className="dialogue-text-element">
      <div
        className={`dialogue-text-element__icon-container ${
          block_type === DIALOGUE_LEFT_BLOCK
            ? 'dialogue-text-element__icon-container_visible'
            : ''
        }`}
      >
        <DialogueIcon
          mainClassName="dialogue-text-element__icon"
          fillClassName="dialogue-text-element__icon-fill"
        />
      </div>
      <p className="dialogue-text-element__text">{renderStoryChildren(data)}</p>
      <div
        className={`dialogue-text-element__icon-container dialogue-text-element__icon-container_type_right ${
          block_type === DIALOGUE_RIGHT_BLOCK
            ? 'dialogue-text-element__icon-container_visible'
            : ''
        }`}
      >
        <DialogueIcon
          mainClassName="dialogue-text-element__icon"
          fillClassName="dialogue-text-element__icon-fill"
        />
      </div>
    </div>
  );
}

export default DialogueTextElement;
