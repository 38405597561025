import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  currentPage,
  siblingCount = 1,
  containerWidth,
  minPaginationBtnWidth = 40,
}) => {
  const paginationRange = useMemo(() => {
    // Calculate how many page numbers we can fit
    const maxPageNumbers = Math.floor(containerWidth / minPaginationBtnWidth);
    // We need at least 5 + siblingCount * 2 spaces to show dots (1 ... 4 5 6 ... 10)
    const availableSpace = Math.max(5 + siblingCount * 2, maxPageNumbers);
    // Adjust siblingCountNumber to fill available space
    const siblingCountNumber = Math.floor((availableSpace - 5) / 2);

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalCount]
    */
    if (availableSpace >= totalCount) return range(1, totalCount);

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCountNumber, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCountNumber,
      totalCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between 
      the extremes of sibling and the page limits i.e 1 and totalCount. Hence we are using 
      leftSiblingIndex > 2 and rightSiblingIndex < totalCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCountNumber;
      const leftItem =
        leftItemCount + 2 < availableSpace ? leftItemCount + 1 : leftItemCount;
      const leftRange = range(1, leftItem);

      return [...leftRange, DOTS, totalCount];
    }

    /*
        Case 3: No right dots to show, but left dots to be shown
      */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 2 + 2 * siblingCountNumber;
      const rightItem =
        totalCount -
        (rightItemCount + 3 < availableSpace
          ? rightItemCount + 1
          : rightItemCount);
      const rightRange = range(rightItem, totalCount);

      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const rightItem =
        rightSiblingIndex - leftSiblingIndex + 5 < availableSpace
          ? rightSiblingIndex + 1
          : rightSiblingIndex;
      const middleRange = range(leftSiblingIndex, rightItem);

      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [
    totalCount,
    currentPage,
    siblingCount,
    containerWidth,
    minPaginationBtnWidth,
  ]);

  return paginationRange;
};
