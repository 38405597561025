import { useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import AuthInput from '../../../../Auth/AuthInput/AuthInput';
import ErrorMessage from '../../../../ErrorMessage/ErrorMessage';
import ProfileFormButtons from '../ProfileFormButtons/ProfileFormButtons';
import './ProfileFormBlock.css';
import { TAP_BTN_SCALE } from '../../../../../assets/utils/constants';
import { TranslationContext } from '../../../../../assets/contexts/translationContext';
import AuthButton from '../../../../Auth/AuthButton/AuthButton';
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage';
import { useImageLoader } from '../../../../../assets/hooks/useImageLoader';

function ProfileFormBlock({
  inputType,
  label,
  name,
  inputMode,
  autoComplete,
  value,
  onChange,
  isEditMode,
  isPreloader,
  onSubmit,
  handleOpenEditMode,
  handleCancelEditMode,
  isNotAllowEdit,
  valueValidity,
  apiError,
  icon,
  max,
  default_avatar,
  successMessage,
}) {
  const {
    labels: { changePhoto, removePhoto },
  } = useContext(TranslationContext);
  const { isImgLoading } = useImageLoader(
    name === 'avatar' ? value : undefined
  );
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const type =
    name === 'date' ? (isMobileDevice ? inputType : undefined) : inputType;

  return (
    <div className="profile-form-block">
      <div
        className={`profile-form-block__content profile-form-block__content_type_${name}`}
      >
        {name === 'avatar' ? (
          <>
            <AnimatePresence mode="popLayout" initial={false}>
              <motion.img
                className={`profile-form-block__avatar ${
                  isImgLoading ? 'skeleton-loader' : ''
                }`}
                src={value}
                alt={name}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key={value}
              />
            </AnimatePresence>

            <div className="profile-form-block__avatar-btns">
              <motion.label
                className="profile-form-block__custom-file-btn"
                htmlFor={name}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: TAP_BTN_SCALE }}
                initial={{ opacity: 0.5 }}
                animate={{ opacity: isNotAllowEdit ? 0.5 : 1 }}
                style={{
                  pointerEvents: isNotAllowEdit ? 'none' : 'auto',
                  userSelect: isNotAllowEdit ? 'none' : 'auto',
                }}
              >
                <input
                  className="profile-form-block__invisible-file-input"
                  id={name}
                  name={name}
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={onChange}
                />
                <span className="profile-form-block__file-btn-text">
                  {changePhoto}
                </span>
              </motion.label>

              <AuthButton
                label={removePhoto}
                isDisabled={isNotAllowEdit || value === default_avatar}
                onClick={() => {
                  handleOpenEditMode(name);
                  onChange({ target: { name } });
                }}
                isMobileSmall
                isColorContrast
              />
            </div>
          </>
        ) : name === 'date' && isMobileDevice ? (
          <AuthInput
            label={label}
            name={name}
            type={type}
            autoComplete={autoComplete}
            value={value}
            max={max}
            onChange={(e) => {
              onChange({
                target: {
                  value: e.target.value,
                  name: `mobile-${name}`,
                  name_key: name,
                },
              });
            }}
            readOnly={!isEditMode}
            icon={icon}
          />
        ) : (
          <AuthInput
            label={label}
            name={name}
            type={type}
            inputMode={inputMode}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            readOnly={!isEditMode}
            icon={icon}
          />
        )}

        <ProfileFormButtons
          name={name}
          isEditMode={isEditMode}
          isPreloader={isPreloader}
          isDisabled={!valueValidity.validState}
          onSubmit={onSubmit}
          handleOpenEditMode={handleOpenEditMode}
          handleCancelEditMode={handleCancelEditMode}
          isNotAllowEdit={isNotAllowEdit}
        />
      </div>

      <ErrorMessage error={valueValidity.errorMessage || apiError} isApiError />
      <SuccessMessage message={successMessage} />
    </div>
  );
}

export default ProfileFormBlock;
