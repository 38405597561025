import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { FireIcon } from '../../../assets/icons/icons';
import {
  CATEGORIES_TYPE,
  DESK_PROFILE_TYPE,
  PREFERENCES_LAST_VIEWED,
  PREFERENCES_POPUP_TYPE,
} from '../../../assets/utils/constants';
import Popup from '../../Popup/Popup';
import './PreferencesPopup.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { PopupContext } from '../../../assets/contexts/popupContex';
import AuthButton from '../AuthButton/AuthButton';
import { UserContext } from '../../../assets/contexts/userContext';
import PreferencesList from './PreferencesList/PreferencesList';
import { DataContext } from '../../../assets/contexts/dataContext';
import mainApi from '../../../assets/api/MainApi';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function PreferencesPopup() {
  const {
    auth: {
      preferences: { title, skip, done },
    },
    labels: { cancel },
  } = useContext(TranslationContext);
  const { user, setUser } = useContext(UserContext);
  const { handlePopupClose } = useContext(PopupContext);
  const { categories, isDataLoading } = useContext(DataContext);
  const initialSelected = user?.personal_data?.category_ids || [];
  const [selected, setSelected] = useState(initialSelected);
  const [isPreloader, setIsPreloader] = useState(false);
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  const { pathname } = useLocation();
  const isProfile = pathname.includes(DESK_PROFILE_TYPE);

  useEffect(() => {
    setSelected(initialSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function handleSelect(category) {
    if (selected.some((item) => item._id === category._id)) {
      setSelected((prev) => prev.filter((item) => item._id !== category._id));
    } else {
      setSelected((prev) => [...prev, category]);
    }
  }

  function handleClose() {
    handlePopupClose(PREFERENCES_POPUP_TYPE);
    localStorage.setItem(PREFERENCES_LAST_VIEWED, moment().format());
    setSelected(initialSelected);
  }

  function handleSubmit() {
    setIsPreloader(true);
    mainApi
      .editPersonalData({
        category_ids:
          selected.length > 0 ? selected.map((item) => item._id) : null,
        unset_categories: selected.length === 0,
      })
      .then((res) => {
        setUser(res);
        handleClose();
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  return (
    <Popup popupName={PREFERENCES_POPUP_TYPE} fullScreen>
      <div className="preferences-popup">
        <div className="preferences-popup__heading">
          <div className="preferences-popup__icon-box">
            <FireIcon
              mainClassName="preferences-popup__icon"
              fillClassName="preferences-popup__icon-fill"
            />
          </div>
          <h3 className="preferences-popup__title">{title}</h3>
        </div>

        <div className="preferences-popup__content">
          {isDataLoading[CATEGORIES_TYPE] ? (
            <MiniPreloader />
          ) : (
            <PreferencesList
              data={categories}
              handleSelect={handleSelect}
              selected={selected}
            />
          )}
        </div>

        <ErrorMessage error={error} isApiError />

        <div className="preferences-popup__btns">
          <AuthButton label={isProfile ? cancel : skip} onClick={handleClose} />
          <AuthButton
            label={done}
            isTypeSubmit
            onClick={handleSubmit}
            isPreloader={isPreloader}
          />
        </div>
      </div>
    </Popup>
  );
}

export default PreferencesPopup;
