import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import './Paywall.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  AUTH_POPUP_TYPE,
  AUTH_LOGIN_LINK,
  CONFIRM_POPUP_TYPE,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import { ArrowInCircleIcon, GemsLogo } from '../../../assets/icons/icons';
import LineDivider from '../../LineDivider/LineDivider';
import ConfirmPopup from '../../ConfirmPopup/ConfirmPopup';
import { PopupContext } from '../../../assets/contexts/popupContex';
import mainApi from '../../../assets/api/MainApi';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import { UserContext } from '../../../assets/contexts/userContext';

function Paywall({ _id, price, totalPages = 0, text = '', setIsAvailable }) {
  const {
    reader: {
      pages,
      paywall: {
        title,
        button,
        confirmPopup: { title: confirmPopupTitle },
      },
    },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen, handlePopupClose } = useContext(PopupContext);
  const [isPreloader, setIsPreloader] = useState(false);
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  function openConfirmPopup() {
    user
      ? handlePopupOpen(CONFIRM_POPUP_TYPE)
      : handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
  }

  function handlePayClick() {
    setIsPreloader(true);
    mainApi
      .buyStory({ _id })
      .then(() => {
        setIsAvailable(true);
        handlePopupClose(CONFIRM_POPUP_TYPE);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  return (
    <div className="paywall">
      <div className="paywall__container">
        <div className="paywall__content">
          <h4 className="paywall__title">{title}</h4>
          <div className="paywall__pages">
            <p className="paywall__pages-number">{totalPages}</p>
            <p className="paywall__pages-text">{pages}</p>
          </div>
          <motion.button
            className="paywall__button"
            type="button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: TAP_BTN_SCALE }}
            onClick={openConfirmPopup}
          >
            <GemsLogo
              mainClassName="paywall__button-icon"
              strokeClassName="paywall__button-icon-stroke"
            />
            {button(price)}
          </motion.button>
        </div>
        <div className="paywall__bg paywall__bg_position_left" />
        <div className="paywall__bg paywall__bg_position_right" />
      </div>

      <ArrowInCircleIcon
        mainClassName="paywall__arrow"
        fillClassName="paywall__arrow-fill"
      />

      <p className="paywall__text">{text}</p>

      <div className="paywall__divider">
        <LineDivider noCenterRhomb noAnimation />
      </div>

      <ConfirmPopup
        title={confirmPopupTitle}
        onConfirm={handlePayClick}
        error={error}
        isPreloader={isPreloader}
      />
    </div>
  );
}

export default Paywall;
