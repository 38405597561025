import { useEffect, useRef, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  BASE_TIMING_FUNCTION,
  BASE_TRANSITION_DURATION,
  CATALOG_POPUP_CATEGORIES_LINK,
  CATALOG_DROPDOWN_TYPE,
  HEADER_NAV_LIST,
  POPUP_TRANSITION_DURATION,
  SOCIAL_LINKS,
  TAP_BTN_SCALE,
  USER_CREATOR_TYPE,
  BECOME_CREATOR_URL,
} from '../../../assets/utils/constants';
import './MobileMenu.css';
import LineDivider from '../../LineDivider/LineDivider';
import { CloseIcon, EyeIcon, LinesIcon } from '../../../assets/icons/icons';
import { UserContext } from '../../../assets/contexts/userContext';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { PopupContext } from '../../../assets/contexts/popupContex';
import useEscapeKey from '../../../assets/hooks/useEscapeKey';
import { DataContext } from '../../../assets/contexts/dataContext';

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

const popupVariants = {
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: {
      duration: POPUP_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
      opacity: { duration: POPUP_TRANSITION_DURATION / 2 },
    },
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: {
      duration: POPUP_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
      opacity: {
        duration: POPUP_TRANSITION_DURATION / 2,
        delay: POPUP_TRANSITION_DURATION / 2,
      },
    },
  },
};

const containerVariants = {
  visible: {
    transform: 'translateX(0)',
    transition: {
      duration: POPUP_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
  hidden: {
    transform: 'translateX(-125%)',
    transition: {
      duration: POPUP_TRANSITION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

const itemsVariants = {
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: BASE_TRANSITION_DURATION * 1.2,
      delay: i * (BASE_TRANSITION_DURATION / 2),
      ease: BASE_TIMING_FUNCTION,
      type: 'spring',
      bounce: 0.4,
    },
  }),
  hidden: {
    opacity: 0,
    y: -20,
    transition: { delay: POPUP_TRANSITION_DURATION },
  },
};

export default function MobileMenu({ isMobileMenuOpen, setIsMobileMenuOpen }) {
  const {
    services: { catalog, becomeCreator, copyright },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const { lang } = useContext(DataContext);
  const overlay = useRef();
  const shouldShowBecomeCreatorBtn =
    !user || user?.reg_data?.role?.name !== USER_CREATOR_TYPE;
  useEscapeKey(closeMobileMenu);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closeMobileMenu();
      }
    }

    document.addEventListener('mousedown', handleOverlayClose);
    return () => {
      document.removeEventListener('mousedown', handleOverlayClose);
    };
  });

  function closeMobileMenu() {
    setIsMobileMenuOpen(false);
  }

  const handleCatalogBtnClick = () => {
    closeMobileMenu();
    setTimeout(() => {
      handlePopupOpen(CATALOG_DROPDOWN_TYPE, CATALOG_POPUP_CATEGORIES_LINK);
    }, (POPUP_TRANSITION_DURATION * 1000) / 2);
  };

  return (
    <AnimatePresence>
      {isMobileMenuOpen && (
        <motion.div
          className="mobile-menu"
          ref={overlay}
          variants={popupVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div
            className="mobile-menu__container"
            variants={containerVariants}
            layout
          >
            <div className="mobile-menu__wrapper">
              <div className="mobile-menu__heading">
                <Link
                  to="/"
                  className="mobile-menu__icon-link"
                  onClick={closeMobileMenu}
                  state={{ disableScrollToTop: false }}
                >
                  <motion.div
                    className="mobile-menu__icon-btn"
                    whileTap={btnTapAnimation}
                  >
                    <EyeIcon
                      mainClassName="mobile-menu__logo-icon"
                      fillClassName="mobile-menu__icon-fill"
                    />
                  </motion.div>
                </Link>

                <motion.button
                  className="mobile-menu__icon-btn mobile-menu__icon-btn_type_close"
                  type="button"
                  whileTap={btnTapAnimation}
                  onClick={closeMobileMenu}
                >
                  <CloseIcon
                    mainClassName="mobile-menu__close-icon"
                    fillClassName="mobile-menu__icon-fill"
                  />
                </motion.button>
              </div>

              <div className="mobile-menu__content">
                <motion.button
                  className="mobile-menu__btn"
                  type="button"
                  onClick={handleCatalogBtnClick}
                  whileTap={btnTapAnimation}
                  variants={itemsVariants}
                  custom={0}
                  layout
                >
                  <p className="mobile-menu__btn-text">{catalog}</p>
                </motion.button>

                <nav className="mobile-menu__nav">
                  <ul className="mobile-menu__links-list">
                    {HEADER_NAV_LIST.map((item, i) => (
                      <motion.li
                        className="mobile-menu__item"
                        key={item.label[lang]}
                        variants={itemsVariants}
                        custom={i + 1}
                        layout
                      >
                        <NavLink
                          className={({ isActive }) =>
                            `mobile-menu__link ${
                              isActive ? 'mobile-menu__link_active' : ''
                            }`
                          }
                          to={item.path}
                          onClick={closeMobileMenu}
                          state={{ disableScrollToTop: false }}
                        >
                          <motion.div
                            className="mobile-menu__btn"
                            whileTap={btnTapAnimation}
                          >
                            <LinesIcon
                              mainClassName="mobile-menu__btn-icon"
                              fillClassName="mobile-menu__btn-icon-fill"
                            />
                            <p className="mobile-menu__btn-text">
                              {item.label[lang]}
                            </p>
                          </motion.div>
                        </NavLink>
                      </motion.li>
                    ))}
                  </ul>
                </nav>

                {shouldShowBecomeCreatorBtn && (
                  <motion.a
                    className="mobile-menu__btn"
                    whileTap={btnTapAnimation}
                    variants={itemsVariants}
                    custom={HEADER_NAV_LIST.length + 1}
                    layout
                    href={BECOME_CREATOR_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mobile-menu__btn-text">{becomeCreator}</p>
                  </motion.a>
                )}
              </div>

              <div className="mobile-menu__divider-wrapper">
                <LineDivider isSmallRhomb />
              </div>

              <ul className="mobile-menu__social-list">
                {SOCIAL_LINKS.map((item, i) => (
                  <motion.li
                    className="mobile-menu__item"
                    key={item.label}
                    variants={itemsVariants}
                    custom={i + HEADER_NAV_LIST.length + 2}
                    layout
                  >
                    <motion.a
                      className="mobile-menu__social-link"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      whileTap={btnTapAnimation}
                    >
                      {item.icon({
                        mainClassName: 'mobile-menu__link-icon',
                        backgroundClassName:
                          'mobile-menu__link-icon-background',
                        fillClassName: 'mobile-menu__link-icon-fill',
                        strokeClassName: 'mobile-menu__link-icon-stroke',
                      })}
                    </motion.a>
                  </motion.li>
                ))}
              </ul>

              <p className="mobile-menu__copyright">{copyright}</p>
            </div>

            <div
              className="mobile-menu__dots-wrapper"
              onClick={closeMobileMenu}
            >
              <ul className="mobile-menu__dots">
                {Array.from({ length: 36 }).map((_, i) => (
                  <li className="mobile-menu__dot" key={i} />
                ))}
              </ul>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
