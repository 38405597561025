import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  MY_STORIES_URL,
  PROFILE_NAV_LIST,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import './DeskNav.css';
import { AngleArrowIcon } from '../../../assets/icons/icons';
import { UserContext } from '../../../assets/contexts/userContext';
import { DataContext } from '../../../assets/contexts/dataContext';

function DeskNav({ linksList }) {
  const { lang } = useContext(DataContext);
  const { user } = useContext(UserContext);
  const published_stories = user?.stats?.published_stories;

  return (
    <nav className="desk-nav">
      <ul className="desk-nav__list">
        {PROFILE_NAV_LIST.map((item) => (
          <motion.li
            className="desk-nav__item"
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: TAP_BTN_SCALE }}
            key={item.label[lang]}
          >
            <NavLink
              className={({ isActive }) =>
                `desk-nav__link ${isActive ? 'desk-nav__link_active' : ''}`
              }
              to={item.path}
              state={{ disableScrollToTop: false }}
            >
              <item.icon
                mainClassName="desk-nav__link-icon"
                strokeClassName="desk-nav__link-icon-stroke"
              />
              <p className="desk-nav__link-label">{item.label[lang]}</p>
              <AngleArrowIcon
                mainClassName="desk-nav__link-arrow"
                fillClassName="desk-nav__link-arrow-fill"
              />
            </NavLink>
          </motion.li>
        ))}
      </ul>

      {linksList?.length > 0 && (
        <ul className="desk-nav__list desk-nav__list_border_top">
          {linksList.map((item) => (
            <motion.li
              className="desk-nav__item"
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: TAP_BTN_SCALE }}
              key={item.label[lang]}
            >
              <a className="desk-nav__link" href={item.path}>
                <item.icon
                  mainClassName="desk-nav__link-icon"
                  strokeClassName="desk-nav__link-icon-stroke"
                />
                <p className="desk-nav__link-label">{item.label[lang]}</p>
                {item.path === MY_STORIES_URL && (
                  <p className="desk-nav__link-label desk-nav__link-label_type_number">
                    {published_stories}
                  </p>
                )}
              </a>
            </motion.li>
          ))}
        </ul>
      )}
    </nav>
  );
}

export default DeskNav;
