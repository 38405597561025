import { motion, AnimatePresence } from 'framer-motion';
import './ErrorMessage.css';
import { AttentionIcon } from '../../assets/icons/icons';

const inputErrorVariants = {
  hidden: {
    height: 0,
    paddingTop: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: { duration: 0.3 },
  },
  visible: {
    height: 'auto',
    paddingTop: '6px',
    opacity: 1,
    visibility: 'visible',
    transition: { duration: 0.3 },
  },
};

const apiErrorVariants = {
  hidden: {
    height: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: { duration: 0.3 },
  },
  visible: {
    height: 'auto',
    opacity: 1,
    visibility: 'visible',
    transition: { duration: 0.3 },
  },
};

function ErrorMessage({ isApiError, error }) {
  return (
    <AnimatePresence mode="wait">
      {error && (
        <motion.div
          className="error-message"
          variants={isApiError ? apiErrorVariants : inputErrorVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          layout
        >
          {isApiError && (
            <AttentionIcon
              mainClassName="error-message__icon"
              fillClassName="error-message__icon-fill"
            />
          )}
          <span className="error-message__text">{error}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ErrorMessage;
