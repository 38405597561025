import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import {
  TAGS_TYPE,
  CATEGORIES_TYPE,
  STORIES_API_TYPE,
  ALL_TYPE,
  CREATORS_API_TYPE,
  TAP_BTN_SCALE,
  BASE_TIMING_FUNCTION,
} from '../../../../assets/utils/constants';
import Category from '../Category/Category';
import Story from '../Story/Story';
import Tag from '../Tag/Tag';
import './SearchResultsList.css';
import Creator from '../Creator/Creator';
import { parseLink } from '../../../../assets/utils/utils';

function SearchResultsList({ data, searchType, onClose }) {
  const {
    searchDropdown: { stories, creators, categories, tags },
  } = useContext(TranslationContext);

  return (
    <ul className="search-results-list">
      {data.map((item) => (
        <motion.li
          key={item.elem?._id}
          className="search-results-list__card"
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: TAP_BTN_SCALE }}
          transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
        >
          <Link
            className="search-results-list__link"
            to={parseLink(
              item.content_type,
              item.content_type === CATEGORIES_TYPE ||
                item.content_type === TAGS_TYPE
                ? item.elem?.slug
                : item.elem?._id
            )}
            onClick={onClose}
            state={{ disableScrollToTop: false }}
          >
            {item.content_type === TAGS_TYPE && <Tag data={item.elem} />}
            {item.content_type === CATEGORIES_TYPE && (
              <Category data={item.elem} />
            )}
            {item.content_type === STORIES_API_TYPE && (
              <Story data={item.elem} />
            )}
            {item.content_type === CREATORS_API_TYPE && (
              <Creator data={item.elem} />
            )}

            {searchType === ALL_TYPE && (
              <p className="search-results-list__text">
                {item.content_type === TAGS_TYPE && tags}
                {item.content_type === CATEGORIES_TYPE && categories}
                {item.content_type === STORIES_API_TYPE && stories}
                {item.content_type === CREATORS_API_TYPE && creators}
              </p>
            )}

            {searchType !== ALL_TYPE &&
              item.content_type !== STORIES_API_TYPE && (
                <p className="search-results-list__text">
                  {item.elem?.stats?.published_stories} {stories}
                </p>
              )}
          </Link>
        </motion.li>
      ))}
    </ul>
  );
}

export default SearchResultsList;
