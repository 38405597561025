import { useRef, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { CloseIcon, SearchIcon } from '../../../../assets/icons/icons';
import './SearchBar.css';
import {
  BASE_TIMING_FUNCTION,
  ESCAPE_KEY,
  POPUP_TRANSITION_DURATION,
  SEARCH_DROPDOWN_TYPE,
  SEARCH_STRING_MAX_LENGTH,
  SEARCH_STRING_MIN_LENGTH,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import { PopupContext } from '../../../../assets/contexts/popupContex';

const containerVariants = {
  visible: {
    width: '100%',
    transition: {
      duration: POPUP_TRANSITION_DURATION / 2,
      ease: BASE_TIMING_FUNCTION,
    },
  },
  hidden: {
    width: 0,
    transition: {
      duration: POPUP_TRANSITION_DURATION / 2,
      ease: BASE_TIMING_FUNCTION,
      delay: POPUP_TRANSITION_DURATION / 2,
    },
  },
};

const searchBarVariants = {
  visible: {
    clipPath: 'inset(0% 0% 0% 0% round 60px)',
    transition: {
      duration: POPUP_TRANSITION_DURATION / 2,
      ease: BASE_TIMING_FUNCTION,
    },
  },
  hidden: {
    clipPath: 'inset(0% 100% -0% 0% round 60px)',
    transition: {
      duration: POPUP_TRANSITION_DURATION / 2,
      ease: BASE_TIMING_FUNCTION,
      delay: POPUP_TRANSITION_DURATION / 2,
    },
  },
};

const buttonVariants = {
  visible: {
    opacity: 1,
    visibility: 'visible',
    transition: {
      duration: POPUP_TRANSITION_DURATION / 2,
      ease: BASE_TIMING_FUNCTION,
      delay: POPUP_TRANSITION_DURATION / 2,
    },
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
    transition: {
      duration: POPUP_TRANSITION_DURATION / 2,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

function SearchBar({ value, onChange, onSubmit, onClose, isPage = false }) {
  const {
    placeholders: { search },
  } = useContext(TranslationContext);
  const { isPopupOpen: isOpen } = useContext(PopupContext);
  const searchRef = useRef();

  useEffect(() => {
    if (isOpen[SEARCH_DROPDOWN_TYPE]) {
      setTimeout(() => searchRef?.current?.focus(), 100);
    }
  }, [isOpen]);

  function handleKeyDown(evt) {
    if (evt.key === ESCAPE_KEY) {
      onClose();
      if (isPage) searchRef?.current?.blur();
    }
  }

  return (
    <motion.div
      className={`search-bar ${isPage ? 'search-bar_type_page' : ''}`}
      variants={containerVariants}
    >
      <motion.form
        className="search-bar__container"
        variants={searchBarVariants}
        onSubmit={onSubmit}
        layout
      >
        <div className="search-bar__icon-container">
          <SearchIcon
            mainClassName="search-bar__search-icon"
            fillClassName="search-bar__search-icon-fill"
          />
        </div>
        <input
          className="search-bar__input"
          ref={searchRef}
          name="search"
          type="text"
          placeholder={search}
          minLength={SEARCH_STRING_MIN_LENGTH}
          maxLength={SEARCH_STRING_MAX_LENGTH}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
      </motion.form>

      {!isPage && (
        <motion.button
          className="search-bar__close-btn"
          type="button"
          onClick={onClose}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: TAP_BTN_SCALE }}
          variants={buttonVariants}
        >
          <CloseIcon
            mainClassName="search-bar__close-icon"
            fillClassName="search-bar__close-icon-fill"
          />
        </motion.button>
      )}
    </motion.div>
  );
}

export default SearchBar;
