import {
  HEADING_BLOCK_1,
  HEADING_BLOCK_2,
  HEADING_BLOCK_3,
  PARAGRAPH_BLOCK,
} from '../../../../../assets/utils/constants';
import { renderStoryChildren } from '../../../../../assets/utils/utils';
import './TextElement.css';

function TextElement({ elem }) {
  const { block_type, data } = elem;

  return (
    <>
      {block_type === HEADING_BLOCK_1 && (
        <h2 className="text-element-title text-element-title_size_l">
          {renderStoryChildren(data)}
        </h2>
      )}
      {block_type === HEADING_BLOCK_2 && (
        <h3 className="text-element-title text-element-title_size_m">
          {renderStoryChildren(data)}
        </h3>
      )}
      {block_type === HEADING_BLOCK_3 && (
        <h4 className="text-element-title text-element-title_size_s">
          {renderStoryChildren(data)}
        </h4>
      )}
      {block_type === PARAGRAPH_BLOCK && (
        <p className="text-element-text">{renderStoryChildren(data)}</p>
      )}
    </>
  );
}

export default TextElement;
