import { motion, AnimatePresence } from 'framer-motion';
import './SuccessMessage.css';

const apiErrorVariants = {
  hidden: {
    height: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: { duration: 0.3 },
  },
  visible: {
    height: 'auto',
    opacity: 1,
    visibility: 'visible',
    transition: { duration: 0.3 },
  },
};

function SuccessMessage({ message }) {
  return (
    <AnimatePresence mode="wait">
      {message && (
        <motion.div
          className="success-message"
          variants={apiErrorVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          layout
        >
          <span className="success-message__text">{message}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default SuccessMessage;
