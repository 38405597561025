import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import {
  MOBILE_BREAKPOINT,
  SEARCH_DROPDOWN_TYPE,
  TAGS_TYPE,
  CATEGORIES_TYPE,
  ALL_TYPE,
  SEARCH_PAGE_MAIN_LINK,
  ALL_SEARCH_POPUP_LIMIT,
  TAGS_SEARCH_POPUP_LIMIT,
  CATEGORIES_SEARCH_POPUP_LIMIT,
  DEFAULT_SEARCH_POPUP_LIMIT,
  SEARCH_VALUE_KEY,
  SEARCH_TYPE_KEY,
} from '../../../assets/utils/constants';
import Popup from '../../Popup/Popup';
import './SearchDropdown.css';
import { PopupContext } from '../../../assets/contexts/popupContex';
import SearchBar from './SearchBar/SearchBar';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import PopularSearch from './PopularSearch/PopularSearch';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import SearchResults from './SearchResults/SearchResults';
import mainApi from '../../../assets/api/MainApi';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

function SearchDropdown() {
  const { isPopupOpen: isOpen, handlePopupClose } = useContext(PopupContext);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [searchData, setSearchData] = useState(null);
  const [searchType, setSearchType] = useState(ALL_TYPE);
  const [searchLink, setSearchLink] = useState('');
  const [isPreloader, setIsPreloader] = useState(false);
  const [apiError, showApiError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    setSearchLink(
      `/${SEARCH_PAGE_MAIN_LINK}?${SEARCH_VALUE_KEY}=${value}&${SEARCH_TYPE_KEY}=${searchType}`
    );
  }, [value, searchType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!value) {
        clearSearch();
        return;
      }

      handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!value) return;
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);

  function handleSearch() {
    const limit =
      searchType === ALL_TYPE
        ? ALL_SEARCH_POPUP_LIMIT
        : searchType === TAGS_TYPE
        ? TAGS_SEARCH_POPUP_LIMIT
        : searchType === CATEGORIES_TYPE
        ? CATEGORIES_SEARCH_POPUP_LIMIT
        : DEFAULT_SEARCH_POPUP_LIMIT;

    setIsPreloader(true);
    mainApi
      .search({
        limit,
        string: value,
        content_types: searchType,
      })
      .then((res) => {
        setSearchData(res.data);
      })
      .catch((err) => {
        showApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function clearSearch() {
    setValue('');
    setSearchData(null);
    setSearchType(ALL_TYPE);
  }

  function handleClose() {
    handlePopupClose(SEARCH_DROPDOWN_TYPE);
    clearSearch();
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (value) {
      navigate(searchLink);
      handleClose();
    }
  }

  return width > MOBILE_BREAKPOINT ? (
    <AnimatePresence>
      {isOpen[SEARCH_DROPDOWN_TYPE] && (
        <motion.div
          className="search-dropdown"
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div className="search-dropdown__container">
            <SearchBar
              value={value}
              onClose={handleClose}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />

            <Popup
              popupName={SEARCH_DROPDOWN_TYPE}
              onClose={clearSearch}
              dropdown
              slideFromTop
            >
              <div className="search-dropdown__popup-content">
                {isPreloader && (
                  <AnimatePresence mode="popLayout">
                    <motion.div
                      className="search-dropdown__preloader"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key="preloader"
                    >
                      <MiniPreloader />
                    </motion.div>
                  </AnimatePresence>
                )}

                {!searchData && <ErrorMessage error={apiError} isApiError />}

                {searchData ? (
                  <SearchResults
                    data={searchData}
                    searchType={searchType}
                    setSearchType={setSearchType}
                    searchLink={searchLink}
                    onClose={handleClose}
                    error={apiError}
                  />
                ) : (
                  <PopularSearch onClose={handleClose} />
                )}
              </div>
            </Popup>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <Popup
      popupName={SEARCH_DROPDOWN_TYPE}
      onClose={clearSearch}
      dropdown
      slideFromTop
    >
      <div className="search-dropdown__popup-content">
        <SearchBar
          value={value}
          onClose={handleClose}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />

        {!searchData && <ErrorMessage error={apiError} isApiError />}

        {isPreloader ? (
          <AnimatePresence mode="popLayout">
            <motion.div
              className="search-dropdown__preloader"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="preloader"
            >
              <MiniPreloader />
            </motion.div>
          </AnimatePresence>
        ) : searchData ? (
          <SearchResults
            data={searchData}
            searchType={searchType}
            setSearchType={setSearchType}
            searchLink={searchLink}
            onClose={handleClose}
            error={apiError}
          />
        ) : (
          <PopularSearch onClose={handleClose} />
        )}
      </div>
    </Popup>
  );
}

export default SearchDropdown;
