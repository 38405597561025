import { useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './PopularSearch.css';
import PopularSearchList from '../PopularSearchList/PopularSearchList';
import {
  BASE_TIMING_FUNCTION,
  POPUP_TRANSITION_DURATION,
  TAGS_TYPE,
  TOP_CATEGORIES_TYPE,
  TOP_STORIES_TYPE,
} from '../../../../assets/utils/constants';
import { DataContext } from '../../../../assets/contexts/dataContext';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';

function PopularSearch({ onClose }) {
  const {
    searchDropdown: { popularTags, popularCategories, popularStories },
  } = useContext(TranslationContext);
  const { tags, topCategories, topStories, isDataLoading } =
    useContext(DataContext);
  const [data, setData] = useState(null);
  const isPreloader =
    isDataLoading[TAGS_TYPE] ||
    isDataLoading[TOP_CATEGORIES_TYPE] ||
    isDataLoading[TOP_STORIES_TYPE];

  useEffect(() => {
    setData([
      {
        title: popularTags,
        data: tags,
        type: TAGS_TYPE,
      },
      {
        title: popularCategories,
        data: topCategories,
        type: TOP_CATEGORIES_TYPE,
      },
      {
        title: popularStories,
        data: topStories,
        type: TOP_STORIES_TYPE,
      },
    ]);
  }, [
    topCategories,
    popularCategories,
    popularStories,
    popularTags,
    tags,
    topStories,
  ]);

  return (
    <AnimatePresence mode="wait">
      {isPreloader ? (
        <motion.div
          className="popular-search__preloader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="preloader"
        >
          <MiniPreloader />
        </motion.div>
      ) : (
        <motion.ul
          className="popular-search"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="popular-search"
          transition={{
            duration: POPUP_TRANSITION_DURATION / 2,
            ease: BASE_TIMING_FUNCTION,
          }}
        >
          {data?.map(
            (card) =>
              card.data?.length > 0 && (
                <li className="popular-search__card" key={card.title}>
                  <p className="popular-search__title">{card.title}</p>
                  <PopularSearchList
                    data={card.data}
                    type={card.type}
                    onClose={onClose}
                  />
                </li>
              )
          )}
        </motion.ul>
      )}
    </AnimatePresence>
  );
}

export default PopularSearch;
