import { useContext } from 'react';
import { SwiperSlide } from 'swiper/react';
import './ContinueBlock.css';
import gradient from '../../../assets/images/swiper/gradient-blue.webp';
import SwiperWrapper from '../SwiperGallery/SwiperWrapper/SwiperWrapper';
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../../../assets/utils/constants';
import ContinueCard from './ContinueCard/ContinueCard';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function ContinueBlock({ _id, data }) {
  const {
    mainPage: {
      continueReading: { title },
    },
  } = useContext(TranslationContext);

  return (
    <section className="continue-block">
      <div className="continue-block__container">
        <h2 className="continue-block__title">{title}</h2>
        <SwiperWrapper
          _id={_id}
          gradient={gradient}
          isContinueBlock={true}
          spaceBetweenOptions={{
            default: 10,
            [DESKTOP_BREAKPOINT + 1]: 16,
            [TABLET_BREAKPOINT + 1]: 12,
            [MOBILE_BREAKPOINT + 1]: 10,
          }}
        >
          {data.map(
            (card) =>
              !card.possession_data?.is_completed && (
                <SwiperSlide className="swiper-block__slide" key={card._id}>
                  <ContinueCard {...card} />
                </SwiperSlide>
              )
          )}
        </SwiperWrapper>
      </div>
    </section>
  );
}

export default ContinueBlock;
