import { useContext, useState } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import AuthFormWrapper from '../AuthFormWrapper/AuthFormWrapper';
import './ConfirmEmailPopup.css';
import { CONFIRM_EMAIL_POPUP_TYPE } from '../../../assets/utils/constants';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import mainApi from '../../../assets/api/MainApi';
import { PopupContext } from '../../../assets/contexts/popupContex';
import Popup from '../../Popup/Popup';

function ConfirmEmailPopup() {
  const {
    confirmEmailPopup: { title, text, btnText },
  } = useContext(TranslationContext);
  const { handlePopupClose, logout } = useContext(PopupContext);
  const [isPreloader, setIsPreloader] = useState(false);
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  function resendVerificationEmail() {
    setIsPreloader(true);
    mainApi
      .resendVerificationEmail()
      .then(() => {
        handlePopupClose(CONFIRM_EMAIL_POPUP_TYPE);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  return (
    <Popup
      popupName={CONFIRM_EMAIL_POPUP_TYPE}
      onClose={logout}
      withCopyright
    >
      <AuthFormWrapper
        title={title}
        subtitle={text}
        buttonText={btnText}
        onSubmit={resendVerificationEmail}
        id={CONFIRM_EMAIL_POPUP_TYPE}
        isPreloader={isPreloader}
        isFormValid={true}
        error={error}
      />
    </Popup>
  );
}

export default ConfirmEmailPopup;
