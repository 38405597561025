import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FillBookmarkIcon } from '../../../../assets/icons/icons';
import './Banner.css';
import {
  AUTH_LOGIN_LINK,
  AUTH_POPUP_TYPE,
  READER_MAIN_LINK,
  TAP_BTN_SCALE,
  CREATOR_LINK,
  BASE_TIMING_FUNCTION,
  STORIES_API_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
  CONFIRM_POPUP_TYPE,
  CREATOR_BG_BREAKPOINT,
  BOOKS_BLOCK_TYPE,
  CATALOG_PAGE_STORIES_LINK,
  MOBILE_BREAKPOINT,
} from '../../../../assets/utils/constants';
import { UserContext } from '../../../../assets/contexts/userContext';
import { PopupContext } from '../../../../assets/contexts/popupContex';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import AuthorInfo from '../../AuthorsCards/AuthorInfo/AuthorInfo';
import mainApi from '../../../../assets/api/MainApi';
import { useImageLoader } from '../../../../assets/hooks/useImageLoader';
import useWindowSize from '../../../../assets/hooks/useWindowSize';

const btnHoverAnimation = {
  scale: 1.02,
};

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function Banner({ colour, content_type, elem, files }) {
  const {
    mainPage: {
      banners: { readNow, addToFavorites },
    },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const { width } = useWindowSize();
  const [isLiked, setIsLiked] = useState(user ? elem?.is_favourite : false);
  const [bannerStats, setBannerStats] = useState(elem?.stats);

  const imgUrlBig = files?.big?.urls?.orig;
  const imgUrlSmall = files?.small?.urls?.orig;
  const { isImgLoading: isBigImgLoading } = useImageLoader(imgUrlBig);
  const { isImgLoading: isSmallImgLoading } = useImageLoader(imgUrlSmall);

  function handleLikeClick() {
    if (!user) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
    } else if (!user?.reg_data?.stages?.is_email_verified) {
      handlePopupOpen(CONFIRM_POPUP_TYPE);
    } else if (!user?.reg_data?.stages?.is_all_auth_data_provided) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_SIGNUP_ONE_MORE_STEP_LINK);
    } else {
      mainApi
        .setLike({
          _id: elem?._id,
          content_type: STORIES_API_TYPE,
          value: !isLiked,
        })
        .then(() => {
          setIsLiked(!isLiked);
          setBannerStats((prev) => ({
            ...prev,
            likes: !isLiked ? Number(prev.likes) + 1 : Number(prev.likes) - 1,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div className="banner" style={{ '--banner-color': colour }}>
      <Link
        className="banner__image-link"
        to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${elem?._id}`}
        state={{ disableScrollToTop: false }}
      >
        <picture className="banner__image-wrapper">
          <source
            srcSet={imgUrlBig}
            media={`(min-width: ${CREATOR_BG_BREAKPOINT + 1}px)`}
          />
          <source
            srcSet={imgUrlSmall}
            media={`(max-width: ${CREATOR_BG_BREAKPOINT}px)`}
          />
          <img
            className={`banner__image ${
              isSmallImgLoading || isBigImgLoading
                ? 'skeleton-loader banner__skeleton-loader'
                : ''
            }`}
            src={imgUrlSmall}
            alt={elem?.title}
            loading="lazy"
          />
        </picture>
      </Link>

      {content_type === BOOKS_BLOCK_TYPE && (
        <div className="banner__content">
          <Link
            to={`/${CREATOR_LINK}/${elem?.author?._id}`}
            className="banner__link"
            state={{ disableScrollToTop: false }}
          >
            <motion.p
              className="banner__subtitle"
              initial={{ color: 'var(--second-active-primary)' }}
              whileHover={{ color: 'var(--main-active-primary)' }}
              whileTap={btnTapAnimation}
              transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
            >
              {elem?.author?.personal_data?.name}
            </motion.p>
          </Link>
          <Link
            className="banner__link"
            to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${elem?._id}`}
            state={{ disableScrollToTop: false }}
          >
            <motion.h2
              className="banner__title"
              dangerouslySetInnerHTML={{ __html: elem?.title }}
              initial={{ color: 'var(--neutral-active)' }}
              whileHover={{ color: 'var(--main-active-primary)' }}
              whileTap={btnTapAnimation}
              transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
            />
          </Link>

          <div className="banner__info">
            <div className="banner__btns-block">
              <Link
                className="banner__link"
                to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${elem?._id}`}
                state={{ disableScrollToTop: false }}
              >
                <motion.div
                  className="banner__link-btn"
                  whileHover={btnHoverAnimation}
                  whileTap={btnTapAnimation}
                >
                  {readNow}
                </motion.div>
              </Link>
              <motion.button
                className={`banner__save-btn ${
                  isLiked ? 'banner__save-btn_active' : ''
                }`}
                type="button"
                aria-label={addToFavorites}
                whileHover={btnHoverAnimation}
                whileTap={btnTapAnimation}
                onClick={handleLikeClick}
              >
                <FillBookmarkIcon
                  mainClassName="banner__save-icon"
                  fillClassName={`banner__save-icon-fill ${
                    isLiked ? 'banner__save-icon-fill_active' : ''
                  }`}
                />
              </motion.button>
            </div>

            {width > MOBILE_BREAKPOINT ? (
              <Link
                className="banner__link"
                to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${elem?._id}`}
                state={{ disableScrollToTop: false }}
              >
                <AuthorInfo
                  className="banner__info-block"
                  stats={bannerStats}
                  isBig
                />
              </Link>
            ) : (
              <AuthorInfo
                className="banner__info-block"
                stats={bannerStats}
                isBig
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Banner;
