import { useContext } from 'react';
import { PopupContext } from '../../assets/contexts/popupContex';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { EyeIcon } from '../../assets/icons/icons';
import { MESSAGE_POPUP_TYPE } from '../../assets/utils/constants';
import AuthButton from '../Auth/AuthButton/AuthButton';
import Popup from '../Popup/Popup';
import './MessagePopup.css';

function MessagePopup({ title, text, onClose }) {
  const {
    labels: { ok },
  } = useContext(TranslationContext);
  const { handlePopupClose } = useContext(PopupContext);

  function handleClose() {
    if (onClose) onClose();
    handlePopupClose(MESSAGE_POPUP_TYPE);
  }

  return (
    <Popup popupName={MESSAGE_POPUP_TYPE} fitContent>
      <div className="message-popup">
        <div className="message-popup__heading">
          <div className="message-popup__logo-box">
            <EyeIcon
              mainClassName="message-popup__logo"
              fillClassName="message-popup__logo-fill"
            />
          </div>
          <h3 className="message-popup__title">{title}</h3>
        </div>

        {text && <p className="message-popup__text">{text}</p>}

        <AuthButton label={ok} onClick={handleClose} isColorsInverted />
      </div>
    </Popup>
  );
}

export default MessagePopup;
