import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './SearchResults.css';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import {
  BASE_TIMING_FUNCTION,
  POPUP_TRANSITION_DURATION,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import SearchResultsList from '../SearchResultsList/SearchResultsList';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import SearchNavBar from '../SearchNavBar/SearchNavBar';

function SearchResults({
  data,
  searchType,
  setSearchType,
  searchLink,
  onClose,
  error,
}) {
  const {
    searchDropdown: { more, noResults },
  } = useContext(TranslationContext);

  return (
    <AnimatePresence mode="popLayout">
      <motion.div
        className="search-results"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={searchType}
        transition={{
          duration: POPUP_TRANSITION_DURATION / 2,
          ease: BASE_TIMING_FUNCTION,
        }}
      >
        <SearchNavBar searchType={searchType} setSearchType={setSearchType} />

        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            className="search-results__content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={error ? 'error' : data?.length > 0 ? 'results' : 'no-results'}
          >
            {error ? (
              <ErrorMessage error={error} />
            ) : data?.length > 0 ? (
              <>
                <SearchResultsList
                  data={data}
                  searchType={searchType}
                  onClose={onClose}
                />

                <Link
                  to={searchLink}
                  className="search-results__link"
                  onClick={onClose}
                  state={{ disableScrollToTop: false }}
                >
                  <motion.span
                    className="search-results__link-text"
                    whileHover={{ opacity: 0.8 }}
                    whileTap={{ scale: TAP_BTN_SCALE }}
                  >
                    {more}
                  </motion.span>
                </Link>
              </>
            ) : (
              <p className="search-results__no-results">{noResults}</p>
            )}
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
}

export default SearchResults;
