import { useContext } from 'react';
import { UserContext } from '../../../../assets/contexts/userContext';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import { COMMENT_FORM_POPUP_TYPE } from '../../../../assets/utils/constants';
import AvatarBox from '../../../AvatarBox/AvatarBox';
import Popup from '../../../Popup/Popup';
import CommentForm from '../CommentForm/CommentForm';
import CommentItem from '../CommentItem/CommentItem';
import './CommentFormPopup.css';

function CommentFormPopup({
  story_id,
  comment_id,
  utc_datetime,
  nested,
  level,
  author,
  onClose,
  comments,
  setComments,
  branchId,
  scrollToComment,
  text,
  isEditMode = false,
  newCommentIds,
  setNewCommentIds,
}) {
  const {
    reader: {
      comments: { reply, replyTo, editComment },
    },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);

  return (
    <Popup
      popupName={`${COMMENT_FORM_POPUP_TYPE}-${comment_id}`}
      fitContent
      noDots
      noHeading
    >
      <div className="comment-form-popup">
        <p className="comment-form-popup__title">
          {isEditMode ? editComment : replyTo}:
        </p>
        <div
          className={`comment-form-popup__comment-block ${
            nested && level === 2
              ? 'comment-form-popup__comment-block_type_padding'
              : ''
          }`}
        >
          <CommentItem
            story_id={story_id}
            _id={comment_id}
            utc_datetime={utc_datetime}
            nested={nested}
            author={author}
            comments={comments}
            setComments={setComments}
            scrollToComment={scrollToComment}
            text={text}
            isCommentFormPopup={true}
            isEditMode={isEditMode}
            newCommentIds={newCommentIds}
            setNewCommentIds={setNewCommentIds}
          />
        </div>

        <div className="comment-form-popup__input-block">
          <AvatarBox
            className="comment-form-popup__avatar"
            data={user?.personal_data}
          />
          <CommentForm
            story_id={story_id}
            comment_id={comment_id}
            text={text}
            placeholder={reply}
            nested={true}
            level={nested ? 2 : 1}
            author={author}
            setComments={setComments}
            branchId={branchId}
            scrollToComment={scrollToComment}
            onClose={onClose}
            isCommentFormPopup={true}
            isEditMode={isEditMode}
            setNewCommentIds={setNewCommentIds}
          />
        </div>
      </div>
    </Popup>
  );
}

export default CommentFormPopup;
