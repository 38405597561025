import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CREATOR_LINK,
  AI_LINK,
  DESKTOP_BREAKPOINT,
  AI_PAGE_CARDS_DESKTOP_LIMIT,
  AI_PAGE_CARDS_TABLET_LIMIT,
} from '../../../assets/utils/constants';
import SwiperGallery from '../../MainPage/SwiperGallery/SwiperGallery';
import './MoreStories.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { DataContext } from '../../../assets/contexts/dataContext';
import mainApi from '../../../assets/api/MainApi';
import useWindowSize from '../../../assets/hooks/useWindowSize';

function MoreStories({ _id, author_id }) {
  const {
    reader: { moreStories },
    aiStories: { swiperTitle },
  } = useContext(TranslationContext);
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const [data, setData] = useState(null);
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);
  const limit =
    width > DESKTOP_BREAKPOINT
      ? AI_PAGE_CARDS_DESKTOP_LIMIT + 1
      : AI_PAGE_CARDS_TABLET_LIMIT + 1;

  useEffect(() => {
    isAiStories ? getMoreAiStories() : getMoreStories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, pathname]);

  function getMoreAiStories() {
    mainApi
      .getAllAiStories({ limit })
      .then((res) => {
        setData(res.data?.filter((item) => item._id !== _id));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getMoreStories() {
    mainApi
      .getAllStories({ limit, author_id })
      .then((res) => {
        setData(res.data?.filter((item) => item._id !== _id));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    data?.length > 0 && (
      <div className="more-stories">
        <SwiperGallery
          data={data}
          block={{
            _id,
            slug: isAiStories ? `/${AI_LINK}` : `/${CREATOR_LINK}/${author_id}`,
            frontend_data: {
              is_get_all: true,
              is_reader: true,
              is_ai: isAiStories,
              title: { [lang]: isAiStories ? swiperTitle : moreStories },
            },
          }}
          is_more={true}
        />
      </div>
    )
  );
}

export default MoreStories;
