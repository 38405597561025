import { useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { ArrowInCircleIcon, CloseIcon } from '../../../../assets/icons/icons';
import './CommentForm.css';
import {
  ENTER_KEY,
  ESCAPE_KEY,
  MOBILE_BREAKPOINT,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import useEscapeKey from '../../../../assets/hooks/useEscapeKey';
import mainApi from '../../../../assets/api/MainApi';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';

function CommentForm({
  story_id,
  comment_id,
  text,
  placeholder,
  nested,
  onClose,
  level,
  author,
  setComments,
  branchId,
  scrollToComment,
  isCommentFormPopup = false,
  isInputOpen = false,
  isEditMode = false,
  setNewCommentIds,
}) {
  const { width } = useWindowSize();
  const inputRef = useRef(null);
  const [value, setValue] = useState(isEditMode ? text : '');
  const [isPreloader, setIsPreloader] = useState(false);
  const authorName = author?.personal_data?.name;
  const isDisabled = isEditMode
    ? value === text
    : nested && level === 2
    ? value.replace(/@/, '').replace(authorName, '').replace(/\s/g, '')
        .length === 0
    : value.replace(/\s/g, '').length === 0;
  const isOpen = isInputOpen ? Object.values(isInputOpen).some(Boolean) : false;
  useEscapeKey(handleClose, isCommentFormPopup || isOpen);

  useEffect(() => {
    if (nested) {
      inputRef?.current?.focus();
      if (level === 2 && !isEditMode) setValue(`@${authorName} `);
    }
  }, [nested, level, authorName, isEditMode]);

  useEffect(() => {
    if (isCommentFormPopup) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 300);
    }
  }, [isCommentFormPopup]);

  function handleChange(evt) {
    setValue(evt.target.value);
  }

  function handleKeyDown(evt) {
    if (evt.key === ENTER_KEY) {
      evt.preventDefault();

      if (evt.shiftKey || evt.metaKey) {
        document.execCommand('insertLineBreak', false, null);
        return;
      }

      if (!isDisabled) handleSubmit(evt);
    }

    if (nested && evt.key === ESCAPE_KEY) {
      handleClose();
    }
  }

  function handleClose() {
    setTimeout(() => setValue(''), 200);
    if (onClose) onClose();
  }

  function addComment() {
    setIsPreloader(true);
    mainApi
      .addComment({
        _id: story_id,
        text:
          nested && level === 2
            ? value.replace(/@/, '').replace(`${authorName} `, '')
            : value,
        reply_to: comment_id,
      })
      .then((res) => {
        setNewCommentIds((prev) => [...prev, res._id]);
        setComments((prev) =>
          !nested
            ? [res, ...prev]
            : prev.map((item) =>
                item._id === branchId
                  ? {
                      ...item,
                      child_comments: item.child_comments
                        ? {
                            ...item.child_comments,
                            data: [res, ...item.child_comments.data],
                          }
                        : { data: [res], is_more: false },
                    }
                  : item
              )
        );

        setTimeout(() => scrollToComment(res._id), 100);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function editComment() {
    setIsPreloader(true);
    mainApi
      .editComment({ _id: comment_id, text: value })
      .then(() => {
        setComments((prevComments) =>
          prevComments.map((comment) => {
            if (comment._id === comment_id) {
              return { ...comment, text: value };
            } else if (comment.child_comments?.data?.length > 0) {
              return {
                ...comment,
                child_comments: {
                  ...comment.child_comments,
                  data: comment.child_comments.data.map((child) => {
                    if (child._id === comment_id) {
                      return { ...child, text: value };
                    }
                    return child;
                  }),
                },
              };
            }
            return comment;
          })
        );
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    isEditMode ? editComment() : addComment();
  }

  return (
    <form className="comment-form" onSubmit={handleSubmit}>
      <TextareaAutosize
        className="comment-form__input"
        ref={inputRef}
        name="comment"
        id={`comment-${comment_id}`}
        minRows={1}
        maxRows={10}
        maxLength={1000}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />

      <motion.button
        className="comment-form__btn"
        type="submit"
        initial={{
          backgroundColor: 'var(--neutral-active-theme)',
          opacity: 0.5,
        }}
        animate={{ opacity: isDisabled || isPreloader ? 0.5 : 1 }}
        whileHover={{ backgroundColor: 'var(--auth-color-primary)' }}
        whileTap={{ scale: TAP_BTN_SCALE }}
        style={{
          userSelect: isDisabled || isPreloader ? 'none' : 'auto',
          pointerEvents: isDisabled || isPreloader ? 'none' : 'auto',
        }}
        disabled={isDisabled || isPreloader}
        key={isPreloader ? 'preloader' : 'submit'}
      >
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            className="comment-form__btn-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={isPreloader ? 'preloader' : 'submit'}
          >
            {isPreloader ? (
              <MiniPreloader isLinkColor />
            ) : (
              <ArrowInCircleIcon
                mainClassName="comment-form__submit-icon"
                fillClassName="comment-form__submit-icon-fill"
              />
            )}
          </motion.div>
        </AnimatePresence>
      </motion.button>

      {nested && width > MOBILE_BREAKPOINT && (
        <motion.button
          className="comment-form__btn comment-form__btn_type_close"
          type="button"
          onClick={handleClose}
          initial={{ backgroundColor: 'var(--website-background-theme)' }}
          whileHover={{ backgroundColor: 'var(--neutral-bg-opacity-primary)' }}
          whileTap={{ scale: TAP_BTN_SCALE }}
        >
          <CloseIcon
            mainClassName="comment-form__close-icon"
            fillClassName="comment-form__close-icon-fill"
          />
        </motion.button>
      )}
    </form>
  );
}

export default CommentForm;
