import { useContext } from 'react';
import { motion } from 'framer-motion';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import './AiBlock.css';
import banner from '../../../assets/images/ai/banner.webp';
import AiForm from '../../MainPage/Promo/AiForm/AiForm';
import {
  PlayIcon,
  SoundIcon,
  CopyIcon,
  SettingsIcon,
} from '../../../assets/icons/icons';
import { TAP_BTN_SCALE } from '../../../assets/utils/constants';

const formBtns = [
  {
    icon: PlayIcon,
  },
  {
    icon: SoundIcon,
  },
  {
    icon: CopyIcon,
  },
  {
    icon: SettingsIcon,
  },
];

function AiBlock() {
  const {
    aiStories: {
      aiBlock: { caption, btnsLabels },
    },
  } = useContext(TranslationContext);

  return (
    <div className="ai-block">
      <div className="ai-block__image-box">
        <p className="ai-block__banner-text">{caption}</p>
        <img className="ai-block__banner" src={banner} alt="" />
      </div>

      <div className="ai-block__form-box">
        <AiForm />
      </div>

      {/*<div className="ai-block__btns">*/}
      {/*  {formBtns.map((btn, i) => (*/}
      {/*    <div className="ai-block__btn-box" key={btnsLabels[i]}>*/}
      {/*      <motion.button*/}
      {/*        className="ai-block__btn"*/}
      {/*        type="button"*/}
      {/*        whileHover={{ backgroundColor: 'var(--ai-neutral-secondary)' }}*/}
      {/*        whileTap={{ scale: TAP_BTN_SCALE }}*/}
      {/*      >*/}
      {/*        <btn.icon*/}
      {/*          mainClassName="ai-block__btn-icon"*/}
      {/*          strokeClassName="ai-block__btn-icon-stroke"*/}
      {/*        />*/}
      {/*      </motion.button>*/}
      {/*      <span className="ai-block__btn-label">{btnsLabels[i]}</span>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  );
}

export default AiBlock;
