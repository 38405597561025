import { useContext } from 'react';
import { TranslationContext } from '../../assets/contexts/translationContext';
import {
  AGE_VERIFIED,
  VERIFY_AGE_POPUP_TYPE,
} from '../../assets/utils/constants';
import Popup from '../Popup/Popup';
import './VerifyAgePopup.css';
import { EyeIcon } from '../../assets/icons/icons';
import AuthButton from '../Auth/AuthButton/AuthButton';
import { PopupContext } from '../../assets/contexts/popupContex';

function VerifyAgePopup() {
  const {
    verifyAgePopup: { subtitle, title },
    labels: { no, yes },
  } = useContext(TranslationContext);
  const { handlePopupClose } = useContext(PopupContext);

  function handleAgeDecline() {
    window.location.href =
      'https://youtu.be/XqZsoesa55w?si=cHBFOP0X5FSXaoY4&t=27';
  }

  function handleAgeSubmit() {
    localStorage.setItem(AGE_VERIFIED, true);
    handlePopupClose(VERIFY_AGE_POPUP_TYPE);
  }

  return (
    <Popup popupName={VERIFY_AGE_POPUP_TYPE} modal>
      <div className="verify-age-popup">
        <div className="verify-age-popup__heading">
          <div className="verify-age-popup__logo-box">
            <EyeIcon
              mainClassName="verify-age-popup__logo"
              fillClassName="verify-age-popup__logo-fill"
            />
          </div>
          <p className="verify-age-popup__subtitle">{subtitle}</p>
          <h3 className="verify-age-popup__title">{title}</h3>
        </div>

        <div className="verify-age-popup__buttons">
          <AuthButton label={no} onClick={handleAgeDecline} />
          <AuthButton label={yes} isTypeSubmit onClick={handleAgeSubmit} />
        </div>
      </div>
    </Popup>
  );
}

export default VerifyAgePopup;
