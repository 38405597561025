import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Category.css';
import {
  TAP_BTN_SCALE,
  CATALOG_PAGE_CATEGORIES_LINK,
  CATEGORY_TYPE,
} from '../../../../assets/utils/constants';
import { DataContext } from '../../../../assets/contexts/dataContext';

const CategoryLink = ({ data, onClose, lang }) => {
  return (
    <Link
      to={`/${CATALOG_PAGE_CATEGORIES_LINK}/${CATEGORY_TYPE}/${data?.slug}`}
      className="search-dropdown-category"
      onClick={onClose}
      state={{ disableScrollToTop: false }}
    >
      <motion.div
        className="search-dropdown-category__text-box"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
      >
        <p className="search-dropdown-category__text">{data?.title?.[lang]}</p>
      </motion.div>
    </Link>
  );
};

const CategoryBox = ({ data, lang }) => {
  return (
    <div className="search-dropdown-category">
      <div className="search-dropdown-category__text-box">
        <p className="search-dropdown-category__text">{data?.title?.[lang]}</p>
      </div>
    </div>
  );
};

function Category({ isLink, ...props }) {
  const { lang } = useContext(DataContext);

  return isLink ? (
    <CategoryLink {...props} lang={lang} />
  ) : (
    <CategoryBox {...props} lang={lang} />
  );
}

export default Category;
