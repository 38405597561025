import { useContext } from 'react';
import { EyeIcon } from '../../assets/icons/icons';
import { CONFIRM_POPUP_TYPE } from '../../assets/utils/constants';
import AuthButton from '../Auth/AuthButton/AuthButton';
import Popup from '../Popup/Popup';
import './ConfirmPopup.css';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { PopupContext } from '../../assets/contexts/popupContex';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

function ConfirmPopup({
  subtitle,
  title,
  onConfirm,
  onDecline,
  error,
  isPreloader,
  _id,
}) {
  const {
    labels: { no, yes },
  } = useContext(TranslationContext);
  const { handlePopupClose } = useContext(PopupContext);
  const popupName = `${CONFIRM_POPUP_TYPE}${_id ? `-${_id}` : ''}`;

  function handleDecline() {
    if (onDecline) onDecline();
    handlePopupClose(popupName);
  }

  function handleConfirm() {
    if (onConfirm) onConfirm();
  }

  return (
    <Popup popupName={popupName} fitContent>
      <div className="confirm-popup">
        <div className="confirm-popup__heading">
          <div className="confirm-popup__logo-box">
            <EyeIcon
              mainClassName="confirm-popup__logo"
              fillClassName="confirm-popup__logo-fill"
            />
          </div>
          {subtitle && <p className="confirm-popup__subtitle">{subtitle}</p>}
          <h3 className="confirm-popup__title">{title}</h3>
        </div>

        <ErrorMessage error={error} isApiError />

        <div className="confirm-popup__buttons">
          <AuthButton label={no} onClick={handleDecline} />
          <AuthButton
            label={yes}
            isTypeSubmit
            onClick={handleConfirm}
            isPreloader={isPreloader}
          />
        </div>
      </div>
    </Popup>
  );
}

export default ConfirmPopup;
