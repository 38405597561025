import { useContext } from 'react';
import { Routes, Route, useLocation, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  CATALOG_DROPDOWN_TYPE,
  TAGS_TYPE,
  CATEGORIES_TYPE,
  STORIES_TYPE,
  TOP_STORIES_TYPE,
  MOBILE_BREAKPOINT,
  CATALOG_NAV_LIST,
  TAP_BTN_SCALE,
  TOP_CATEGORIES_TYPE,
} from '../../../assets/utils/constants';
import Popup from '../../Popup/Popup';
import './CatalogDropdown.css';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import CatalogPopupBlock from './CatalogPopupBlock/CatalogPopupBlock';
import { DataContext } from '../../../assets/contexts/dataContext';

function CatalogDropdown() {
  const { width } = useWindowSize();
  const {
    catalogDropdown: {
      title,
      tags: tagsTitle,
      categories: categoriesTitle,
      stories: storiesTitle,
      emptyTags,
      emptyCategories,
      emptyStories,
    },
  } = useContext(TranslationContext);
  const { tags, topCategories, topStories, isDataLoading, lang } =
    useContext(DataContext);
  const location = useLocation();
  const background = location.state?.background
    ? location.state.background
    : { ...location, pathname: '/' };

  return (
    <Popup popupName={CATALOG_DROPDOWN_TYPE} dropdown withLogo>
      <div className="catalog-dropdown">
        {width > MOBILE_BREAKPOINT ? (
          <>
            <CatalogPopupBlock
              list={tags}
              title={tagsTitle}
              type={TAGS_TYPE}
              emptyText={emptyTags}
              isPreloader={isDataLoading[TAGS_TYPE]}
            />
            <CatalogPopupBlock
              list={topCategories}
              title={categoriesTitle}
              type={CATEGORIES_TYPE}
              emptyText={emptyCategories}
              isPreloader={isDataLoading[TOP_CATEGORIES_TYPE]}
            />
            <CatalogPopupBlock
              list={topStories}
              title={storiesTitle}
              type={STORIES_TYPE}
              emptyText={emptyStories}
              isPreloader={isDataLoading[TOP_STORIES_TYPE]}
            />
          </>
        ) : (
          <>
            <div className="catalog-dropdown__heading">
              <p className="catalog-dropdown__title">{title}</p>
              <ul className="catalog-dropdown__nav-list">
                {CATALOG_NAV_LIST.map((item) => (
                  <motion.li
                    className="catalog-dropdown__nav-item"
                    key={item.label[lang]}
                    whileTap={{ scale: TAP_BTN_SCALE }}
                  >
                    <NavLink
                      className={({ isActive }) =>
                        `catalog-dropdown__nav-link ${
                          isActive ? 'catalog-dropdown__nav-link_active' : ''
                        }`
                      }
                      to={item.link}
                      state={{ background, disableScrollToTop: false }}
                    >
                      {item.label[lang]}
                    </NavLink>
                  </motion.li>
                ))}
              </ul>
            </div>

            <div className="catalog-dropdown__content">
              <Routes location={location}>
                <Route
                  index
                  element={
                    <CatalogPopupBlock
                      list={topCategories}
                      title={categoriesTitle}
                      type={CATEGORIES_TYPE}
                      emptyText={emptyCategories}
                      isPreloader={isDataLoading[TOP_CATEGORIES_TYPE]}
                    />
                  }
                />
                <Route
                  path={`/${CATEGORIES_TYPE}`}
                  element={
                    <CatalogPopupBlock
                      list={topCategories}
                      title={categoriesTitle}
                      type={CATEGORIES_TYPE}
                      emptyText={emptyCategories}
                      isPreloader={isDataLoading[TOP_CATEGORIES_TYPE]}
                    />
                  }
                />
                <Route
                  path={`/${TAGS_TYPE}`}
                  element={
                    <CatalogPopupBlock
                      list={tags}
                      title={tagsTitle}
                      type={TAGS_TYPE}
                      emptyText={emptyTags}
                      isPreloader={isDataLoading[TAGS_TYPE]}
                    />
                  }
                />
                <Route
                  path={`/${STORIES_TYPE}`}
                  element={
                    <CatalogPopupBlock
                      list={topStories}
                      title={storiesTitle}
                      type={STORIES_TYPE}
                      emptyText={emptyStories}
                      isPreloader={isDataLoading[TOP_STORIES_TYPE]}
                    />
                  }
                />
              </Routes>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
}

export default CatalogDropdown;
