import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { UserContext } from '../../../assets/contexts/userContext';
import { PopupContext } from '../../../assets/contexts/popupContex';
import {
  BECOME_CREATOR_LINK,
  BUTTON_LOGOUT_ACTION_TYPE,
  CREATOR_STUDIO_LINK,
  DROPDOWN_ITEMS_VARIANTS,
  ITEM_BUTTON_TYPE,
  ITEM_LINK_TYPE,
  LOGOUT_BUTTON_LINK,
  PROFILE_DROPDOWN_TYPE,
  PROFILE_NAV_LIST,
  TAP_BTN_SCALE,
  USER_CREATOR_TYPE,
} from '../../../assets/utils/constants';
import Popup from '../../Popup/Popup';
import './ProfileDropdown.css';
import AvatarBox from '../../AvatarBox/AvatarBox';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import { DataContext } from '../../../assets/contexts/dataContext';

function ProfileDropdown() {
  const { lang } = useContext(DataContext);
  const { user } = useContext(UserContext);
  const { logout, handlePopupClose, isLogoutPreloader } =
    useContext(PopupContext);
  const data = [
    ...PROFILE_NAV_LIST,
    user?.reg_data?.role?.name === USER_CREATOR_TYPE
      ? CREATOR_STUDIO_LINK
      : BECOME_CREATOR_LINK,
    LOGOUT_BUTTON_LINK,
  ];

  function handleLogoutClick() {
    logout();
  }

  return (
    <Popup
      popupName={PROFILE_DROPDOWN_TYPE}
      openFromRight
      dropdown
      fitContent
      withLogo
    >
      <div className="profile-dropdown">
        <div className="profile-dropdown__heading">
          <AvatarBox
            className="profile-dropdown__avatar"
            data={user?.personal_data}
          />
          <motion.p
            className="profile-dropdown__title"
            variants={DROPDOWN_ITEMS_VARIANTS}
            key={user?.personal_data?.username || user?.personal_data?.name}
            layout
          >
            {user?.personal_data?.username || user?.personal_data?.name}
          </motion.p>
          <motion.p
            className="profile-dropdown__subtitle"
            variants={DROPDOWN_ITEMS_VARIANTS}
            key={user?.email}
            layout
          >
            {user?.email}
          </motion.p>
        </div>

        <ul className="profile-dropdown__list">
          {data.map((item) => (
            <motion.li
              className="profile-dropdown__item"
              key={item.label[lang]}
              variants={DROPDOWN_ITEMS_VARIANTS}
              whileHover={{ opacity: 0.7 }}
              whileTap={{ scale: TAP_BTN_SCALE }}
              layout
            >
              {item.type === ITEM_LINK_TYPE && (
                <Link
                  to={item.path}
                  className="profile-dropdown__link"
                  onClick={() => handlePopupClose(PROFILE_DROPDOWN_TYPE)}
                  state={{ disableScrollToTop: false }}
                >
                  <item.icon
                    mainClassName="profile-dropdown__link-icon"
                    strokeClassName="profile-dropdown__link-icon-stroke"
                  />
                  <p className="profile-dropdown__link-label">
                    {item.label[lang]}
                  </p>
                </Link>
              )}
              {item.type === ITEM_BUTTON_TYPE && (
                <button
                  className="profile-dropdown__link"
                  type="button"
                  onClick={
                    item.action === BUTTON_LOGOUT_ACTION_TYPE
                      ? handleLogoutClick
                      : undefined
                  }
                >
                  <item.icon
                    mainClassName="profile-dropdown__link-icon"
                    strokeClassName="profile-dropdown__link-icon-stroke"
                  />
                  <p className="profile-dropdown__link-label">
                    {item.label[lang]}
                  </p>
                  {item.action === BUTTON_LOGOUT_ACTION_TYPE &&
                    isLogoutPreloader && <MiniPreloader />}
                </button>
              )}
            </motion.li>
          ))}
        </ul>
      </div>
    </Popup>
  );
}

export default ProfileDropdown;
