import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { TopArrowIcon, TriangleArrowIcon } from '../../assets/icons/icons';
import './Pagination.css';
import {
  BASE_TIMING_FUNCTION,
  TAP_BTN_SCALE,
  AI_LINK,
} from '../../assets/utils/constants';
import PaginationBar from './PaginationBar/PaginationBar';

const hoverBtnAnimation = { scale: 1.03 };
const tapBtnAnimation = { scale: TAP_BTN_SCALE };

function Pagination({ onPageChange, totalCount = 10, currentPage = 1 }) {
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);
  const [isPaginationOpen, setIsPaginationOpen] = useState(false);
  const [paginationRange, setPaginationRange] = useState([]);
  const lastPage = paginationRange[paginationRange.length - 1];
  const isPaginationDisabled = totalCount <= 1;

  const handleNextClick = () => {
    onPageChange(currentPage + 1);
  };

  const handlePreviousClick = () => {
    onPageChange(currentPage - 1);
  };

  const togglePagination = () => {
    if (isPaginationDisabled) return;
    setIsPaginationOpen(!isPaginationOpen);
  };

  return (
    <div className={`pagination ${isAiStories ? 'pagination_theme_dark' : ''}`}>
      <motion.button
        className={`pagination__nav-btn ${
          isPaginationDisabled || currentPage === 1
            ? 'pagination__nav-btn_disabled'
            : ''
        }`}
        type="button"
        onClick={handlePreviousClick}
        whileHover={hoverBtnAnimation}
        whileTap={tapBtnAnimation}
        disabled={isPaginationDisabled || currentPage === 1}
      >
        <TopArrowIcon
          mainClassName="pagination__arrow pagination__arrow_type_prev"
          fillClassName="pagination__arrow-fill"
        />
      </motion.button>

      <motion.button
        className="pagination__fraction"
        type="button"
        onClick={togglePagination}
        whileHover={hoverBtnAnimation}
        whileTap={tapBtnAnimation}
        disabled={isPaginationDisabled}
        style={{
          pointerEvents: isPaginationDisabled ? 'none' : 'auto',
          userSelect: isPaginationDisabled ? 'none' : 'auto',
        }}
      >
        <span className="pagination__fraction_type_current">{currentPage}</span>
        <span className="pagination__fraction_type_slash">/</span>
        <span className="pagination__fraction_type_total">{totalCount}</span>
        {!isPaginationDisabled && (
          <motion.div
            className="pagination__fraction-arrow-box"
            animate={{ rotate: isPaginationOpen ? 180 : 0 }}
            transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
          >
            <TriangleArrowIcon
              mainClassName="pagination__fraction-arrow"
              fillClassName="pagination__fraction-arrow-fill"
            />
          </motion.div>
        )}
      </motion.button>

      <motion.button
        className={`pagination__nav-btn ${
          currentPage === lastPage || isPaginationDisabled
            ? 'pagination__nav-btn_disabled'
            : ''
        }`}
        type="button"
        onClick={handleNextClick}
        whileHover={hoverBtnAnimation}
        whileTap={tapBtnAnimation}
        disabled={isPaginationDisabled || currentPage === lastPage}
      >
        <TopArrowIcon
          mainClassName="pagination__arrow pagination__arrow_type_next"
          fillClassName="pagination__arrow-fill"
        />
      </motion.button>

      <PaginationBar
        isOpen={isPaginationOpen}
        totalCount={totalCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
        onClose={togglePagination}
        setPaginationRange={setPaginationRange}
      />
    </div>
  );
}

export default Pagination;
