import { useContext, useState } from 'react';
import validator from 'validator';
import moment from 'moment-timezone';
import './ProfileForm.css';
import { UserContext } from '../../../../assets/contexts/userContext';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import { DateIcon } from '../../../../assets/icons/icons';
import {
  CHECK_CODE_EMAIL_POPUP_TYPE,
  DEFAULT_MAX_AVATAR_SIZE,
  IMAGE_AVATARS_TYPE,
} from '../../../../assets/utils/constants';
import mainApi from '../../../../assets/api/MainApi';
import useParseApiError from '../../../../assets/hooks/useParseApiError';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import { PopupContext } from '../../../../assets/contexts/popupContex';
import CheckCodePopup from '../../../Auth/CheckCodePopup/CheckCodePopup';
import ProfileFormBlock from './ProfileFormBlock/ProfileFormBlock';

const initialValuesValidity = {
  username: { validState: false, errorMessage: '' },
  name: { validState: false, errorMessage: '' },
  email: { validState: false, errorMessage: '' },
  avatar: { validState: false, errorMessage: '' },
  date: { validState: false, errorMessage: '' },
};

function ProfileForm() {
  const {
    labels: {
      name: nameLabel,
      username: usernameLabel,
      email: emailLabel,
      dateOfBirth,
    },
    errors: {
      invalidEmail,
      fieldRequired,
      invalidDateValue,
      invalidDateMin,
      invalidDateMax,
    },
    apiErrors: { emailAlreadyExists },
    successMessages: {
      emailChangeSuccess,
      usernameChangeSuccess,
      nameChangeSuccess,
      dateOfBirthChangeSuccess,
      avatarChangeSuccess,
      avatarRemoveSuccess,
    },
  } = useContext(TranslationContext);
  const { handlePopupOpen, handlePopupClose } = useContext(PopupContext);
  const { user, setUser } = useContext(UserContext);
  const {
    email,
    personal_data: { avatar, default_avatar, name, username, date_of_birth },
    reg_data: {
      is_password_set,
      stages: { is_email_verified },
      role: {
        permissions: { user_management },
      },
    },
  } = user;

  const isNotAllowEdit = !is_email_verified || !is_password_set;
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const initialValues = {
    avatar: avatar?.urls?.orig ?? default_avatar?.urls?.orig,
    name,
    username,
    email,
    date: isMobileDevice
      ? moment(date_of_birth).format('YYYY-MM-DD')
      : moment(date_of_birth).format('DD.MM.YYYY'),
  };

  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [code, setCode] = useState('');
  const [isEditMode, setIsEditMode] = useState({});
  const [isPreloader, setIsPreloader] = useState({});

  const { parseApiError } = useParseApiError();
  const [changeEmailError, showChangeEmailError] = useAutoDismissError();
  const [checkCodeError, showCheckCodeError] = useAutoDismissError();
  const [editUserNameError, showEditUserNameError] = useAutoDismissError();
  const [editNameError, showEditNameError] = useAutoDismissError();
  const [editDateError, showEditDateError] = useAutoDismissError();
  const [editAvatarError, showEditAvatarError] = useAutoDismissError();

  const [changeEmailSuccess, showChangeEmailSuccess] = useAutoDismissError();
  const [editUserNameSuccess, showEditUserNameSuccess] = useAutoDismissError();
  const [editNameSuccess, showEditNameSuccess] = useAutoDismissError();
  const [editDateSuccess, showEditDateSuccess] = useAutoDismissError();
  const [editAvatarSuccess, showEditAvatarSuccess] = useAutoDismissError();

  function handleOpenEditMode(name) {
    setIsEditMode((prevValue) => ({ ...prevValue, [name]: true }));
    setTimeout(() => {
      document.getElementById(name)?.focus();
    }, 100);
  }

  function handleCloseEditMode(name) {
    setIsEditMode((prevValue) => ({ ...prevValue, [name]: false }));
    setValuesValidity((prevValue) => ({
      ...prevValue,
      [name]: initialValuesValidity[name],
    }));
    document.getElementById(name)?.blur();
  }

  function handleCancelEditMode(name) {
    setValues((prevValue) => ({
      ...prevValue,
      [name]: initialValues[name],
    }));
    handleCloseEditMode(name);
  }

  function handleChange(e) {
    const { name, value, files, name_key } = e.target;
    const date_now = moment();
    const max_date = moment().subtract(18, 'years');
    const min_date = moment('1900-01-01', 'YYYY-MM-DD');

    switch (name) {
      case 'email':
        if (!value) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: { validState: false, errorMessage: fieldRequired },
          }));
        }

        if (value === user[name]) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: { validState: false, errorMessage: '' },
          }));
        } else if (value.length >= 2) {
          if (validator.isEmail(value)) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: { validState: true, errorMessage: '' },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                validState: false,
                errorMessage: !e.target.validity.valid
                  ? e.target.validationMessage
                  : invalidEmail,
              },
            }));
          }
        }

        setValues((prevValue) => ({ ...prevValue, [name]: value }));
        break;

      case 'avatar':
        const file = files?.[0];
        if (!file) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              validState: initialValues.avatar !== default_avatar?.urls?.orig,
              errorMessage: '',
            },
          }));
          setValues((prevValue) => ({
            ...prevValue,
            avatar: default_avatar?.urls?.orig,
            avatarFile: null,
          }));
        } else if (file?.size / 1024 / 1024 > DEFAULT_MAX_AVATAR_SIZE) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: { validState: false, errorMessage: '' },
          }));
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            setValues((prevValue) => ({
              ...prevValue,
              [name]: reader.result,
            }));
          };
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: { validState: true, errorMessage: '' },
          }));
          setValues((prevValue) => ({
            ...prevValue,
            avatarFile: file,
          }));
        }
        break;

      case 'username':
        setValues((prevValue) => ({ ...prevValue, [name]: value }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: { validState: true, errorMessage: '' },
        }));
        break;

      case 'date':
        let dateValue = value.replace(/\D/g, '');
        let formattedDateValue = '';

        if (!dateValue) {
          setValues((prevValue) => ({ ...prevValue, [name]: '' }));
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: { errorMessage: fieldRequired, validState: false },
          }));
        } else {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: { errorMessage: '', validState: false },
          }));

          formattedDateValue += dateValue.substring(0, 2);
          if (dateValue.length >= 3) {
            formattedDateValue += '.' + dateValue.substring(2, 4);
          }
          if (dateValue.length >= 5) {
            formattedDateValue += '.' + dateValue.substring(4, 8);
          }
          if (dateValue.length >= 8) {
            const selected_date = moment(formattedDateValue, 'DD.MM.YYYY');

            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: selected_date.isValid()
                ? selected_date.diff(date_now, 'seconds') >= 10
                  ? {
                      errorMessage: invalidDateValue,
                      validState: false,
                    }
                  : selected_date.diff(max_date, 'seconds') <= 10
                  ? selected_date.diff(min_date, 'seconds') >= 10
                    ? {
                        errorMessage: '',
                        validState: true,
                      }
                    : {
                        errorMessage: invalidDateMin,
                        validState: false,
                      }
                  : {
                      errorMessage: invalidDateMax,
                      validState: false,
                    }
                : {
                    errorMessage: invalidDateValue,
                    validState: false,
                  },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: { errorMessage: '', validState: false },
            }));
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedDateValue,
          }));
        }
        break;

      case 'mobile-date':
        const selected_date = moment(value);

        setValues((prevValue) => ({ ...prevValue, [name_key]: value }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name_key]: selected_date.isValid()
            ? selected_date.diff(date_now, 'seconds') >= 10
              ? {
                  errorMessage: invalidDateValue,
                  validState: false,
                }
              : selected_date.diff(max_date, 'seconds') <= 10
              ? selected_date.diff(min_date, 'seconds') >= 10
                ? {
                    errorMessage: '',
                    validState: true,
                  }
                : {
                    errorMessage: invalidDateMin,
                    validState: false,
                  }
              : {
                  errorMessage: invalidDateMax,
                  validState: false,
                }
            : {
                errorMessage: invalidDateValue,
                validState: false,
              },
        }));
        break;

      default:
        const isValid = Boolean(value) && value !== user[name];
        setValues((prevValue) => ({ ...prevValue, [name]: value }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            validState: isValid,
            errorMessage: isValid ? '' : fieldRequired,
          },
        }));
        break;
    }
  }

  function togglePreloader(name) {
    setIsPreloader((prevValue) => ({ ...prevValue, [name]: !prevValue[name] }));
  }

  function handleSubmit(e, field) {
    e.preventDefault();
    togglePreloader(field);

    switch (field) {
      case 'email':
        mainApi
          .changeEmail({ email: values.email })
          .then(() => {
            handlePopupOpen(CHECK_CODE_EMAIL_POPUP_TYPE);
            handleCloseEditMode(field);
            showChangeEmailSuccess(emailChangeSuccess);
          })
          .catch((err) => {
            switch (err.statusCode) {
              case 409:
                showChangeEmailError(emailAlreadyExists);
                break;

              default:
                showChangeEmailError(parseApiError(err));
                break;
            }
          })
          .finally(() => {
            togglePreloader(field);
          });
        break;

      case 'username':
        mainApi
          .editUserName({ username: values.username })
          .then((res) => {
            setUser(res);
            handleCloseEditMode(field);
            showEditUserNameSuccess(usernameChangeSuccess);
          })
          .catch((err) => {
            showEditUserNameError(parseApiError(err));
          })
          .finally(() => {
            togglePreloader(field);
          });
        break;

      case 'avatar':
        if (values.avatarFile) {
          const data = new FormData();
          data.append('image', values.avatarFile);

          mainApi
            .uploadImage({ data, type: IMAGE_AVATARS_TYPE })
            .then((image) => {
              mainApi
                .editPersonalData({
                  name: null,
                  date_of_birth: null,
                  avatar: image._id,
                  unset_avatar: false,
                })
                .then((res) => {
                  setUser((prevValue) => ({
                    ...prevValue,
                    personal_data: {
                      ...prevValue.personal_data,
                      avatar: res.personal_data.avatar,
                    },
                  }));
                  handleCloseEditMode(field);
                  showEditAvatarSuccess(avatarChangeSuccess);
                })
                .catch((err) => {
                  showEditAvatarError(parseApiError(err));
                });
            })
            .catch((err) => {
              showEditAvatarError(parseApiError(err));
            })
            .finally(() => {
              togglePreloader(field);
            });
        } else {
          mainApi
            .editPersonalData({
              name: null,
              date_of_birth: null,
              avatar: null,
              unset_avatar: true,
            })
            .then((res) => {
              setUser((prevValue) => ({
                ...prevValue,
                personal_data: {
                  ...prevValue.personal_data,
                  avatar: res.personal_data.avatar,
                },
              }));
              handleCloseEditMode(field);
              showEditAvatarSuccess(avatarRemoveSuccess);
            })
            .catch((err) => {
              showEditAvatarError(parseApiError(err));
            })
            .finally(() => {
              togglePreloader(field);
            });
        }
        break;

      default:
        mainApi
          .editPersonalData({
            name: field === 'name' ? values.name : null,
            date_of_birth:
              field === 'date'
                ? moment(values.date, 'DD.MM.YYYY').isValid()
                  ? moment(values.date, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  : values.date
                : null,
            avatar: null,
            unset_avatar: false,
          })
          .then((res) => {
            setUser((prevValue) => ({
              ...prevValue,
              personal_data: {
                ...prevValue.personal_data,
                name:
                  field === 'name'
                    ? res.personal_data.name
                    : prevValue.personal_data.name,
                date_of_birth:
                  field === 'date'
                    ? res.personal_data.date_of_birth
                    : prevValue.personal_data.date_of_birth,
              },
            }));
            handleCloseEditMode(field);
            if (field === 'name') showEditNameSuccess(nameChangeSuccess);
            if (field === 'date') showEditDateSuccess(dateOfBirthChangeSuccess);
          })
          .catch((err) => {
            if (field === 'name') showEditNameError(parseApiError(err));
            if (field === 'date') showEditDateError(parseApiError(err));
          })
          .finally(() => {
            togglePreloader(field);
          });
        break;
    }
  }

  function handleCodeSubmit(e) {
    e.preventDefault();
    setIsPreloader((prevValue) => ({ ...prevValue, code: true }));
    mainApi
      .checkCodeForEmailChange({ code })
      .then(() => {
        setUser((prevValue) => ({
          ...prevValue,
          email: values.email,
        }));
        handlePopupClose(CHECK_CODE_EMAIL_POPUP_TYPE);
      })
      .catch((err) => {
        showCheckCodeError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prevValue) => ({ ...prevValue, code: false }));
      });
  }

  function setValuesEmailOnCodeClose() {
    setValues((prevValue) => ({
      ...prevValue,
      email: user.email,
    }));
  }

  return (
    <>
      <form className="profile-form" id="profile" encType="multipart/form-data">
        <ProfileFormBlock
          name="avatar"
          value={values.avatar}
          onChange={handleChange}
          isEditMode={valuesValidity.avatar.validState}
          isPreloader={isPreloader.avatar}
          onSubmit={handleSubmit}
          handleOpenEditMode={handleOpenEditMode}
          handleCancelEditMode={handleCancelEditMode}
          isNotAllowEdit={isNotAllowEdit}
          valueValidity={valuesValidity.avatar}
          apiError={editAvatarError}
          successMessage={editAvatarSuccess}
          default_avatar={default_avatar?.urls?.orig}
        />

        <ProfileFormBlock
          label={nameLabel}
          name="name"
          autoComplete="name"
          value={values.name}
          onChange={handleChange}
          isEditMode={isEditMode.name}
          isPreloader={isPreloader.name}
          onSubmit={handleSubmit}
          handleOpenEditMode={handleOpenEditMode}
          handleCancelEditMode={handleCancelEditMode}
          isNotAllowEdit={isNotAllowEdit}
          valueValidity={valuesValidity.name}
          apiError={editNameError}
          successMessage={editNameSuccess}
        />

        {user_management.includes('set_username') && (
          <ProfileFormBlock
            label={usernameLabel}
            name="username"
            autoComplete="username"
            value={values.username}
            onChange={handleChange}
            isEditMode={isEditMode.username}
            isPreloader={isPreloader.username}
            onSubmit={handleSubmit}
            handleOpenEditMode={handleOpenEditMode}
            handleCancelEditMode={handleCancelEditMode}
            valueValidity={valuesValidity.username}
            apiError={editUserNameError}
            successMessage={editUserNameSuccess}
            isNotAllowEdit={isNotAllowEdit}
          />
        )}

        <ProfileFormBlock
          label={dateOfBirth}
          name="date"
          inputType="date"
          inputMode="numeric"
          autoComplete="bday"
          max={moment().format('YYYY-MM-DD')}
          value={values.date}
          onChange={handleChange}
          isEditMode={isEditMode.date}
          isPreloader={isPreloader.date}
          onSubmit={handleSubmit}
          handleOpenEditMode={handleOpenEditMode}
          handleCancelEditMode={handleCancelEditMode}
          isNotAllowEdit={isNotAllowEdit}
          valueValidity={valuesValidity.date}
          apiError={editDateError}
          successMessage={editDateSuccess}
          icon={DateIcon}
        />

        <ProfileFormBlock
          label={emailLabel}
          name="email"
          inputType="email"
          inputMode="email"
          autoComplete="email"
          value={values.email}
          onChange={handleChange}
          isEditMode={isEditMode.email}
          isPreloader={isPreloader.email}
          onSubmit={handleSubmit}
          handleOpenEditMode={handleOpenEditMode}
          handleCancelEditMode={handleCancelEditMode}
          isNotAllowEdit={isNotAllowEdit}
          valueValidity={valuesValidity.email}
          apiError={changeEmailError}
          successMessage={changeEmailSuccess}
        />
      </form>

      <CheckCodePopup
        popupName={CHECK_CODE_EMAIL_POPUP_TYPE}
        onSubmit={handleCodeSubmit}
        apiError={checkCodeError}
        isPreloader={isPreloader.code}
        value={code}
        setValue={setCode}
        onClose={setValuesEmailOnCodeClose}
      />
    </>
  );
}

export default ProfileForm;
