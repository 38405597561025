import { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { PopupContext } from '../../../assets/contexts/popupContex';
import './MobilePopup.css';
import {
  BASE_TIMING_FUNCTION,
  POPUP_TRANSITION_DURATION,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import { CloseIcon, EyeIcon } from '../../../assets/icons/icons';
import { TranslationContext } from '../../../assets/contexts/translationContext';

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

const transitionWithOpacity = {
  duration: POPUP_TRANSITION_DURATION,
  ease: BASE_TIMING_FUNCTION,
  opacity: { duration: POPUP_TRANSITION_DURATION / 2 },
};

const defaultTransition = {
  duration: POPUP_TRANSITION_DURATION,
  ease: BASE_TIMING_FUNCTION,
};

const visibilityVariants = {
  initial: { visibility: 'hidden' },
  visible: { visibility: 'visible', transition: defaultTransition },
  hidden: { visibility: 'hidden', transition: defaultTransition },
};

const defaultPopupVariants = {
  initial: { y: '100%', opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: transitionWithOpacity,
  },
  hidden: {
    y: '100%',
    opacity: 1,
    transition: defaultTransition,
  },
};

const slideFromTopPopupVariants = {
  initial: { y: '-100%', opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: transitionWithOpacity,
  },
  hidden: {
    y: '-100%',
    opacity: 1,
    transition: defaultTransition,
  },
};

const opacityVariants = {
  initial: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: defaultTransition,
  },
  hidden: {
    opacity: 0,
    transition: defaultTransition,
  },
};

function MobilePopup({
  children,
  modal,
  popupName,
  onClose,
  slideFromTop,
  fitContent,
  fullScreen,
  withLogo,
  withCopyright,
  noHeading = false,
  noDots = false,
  tooltip,
}) {
  const {
    services: { copyright },
  } = useContext(TranslationContext);
  const { isPopupOpen: isOpen } = useContext(PopupContext);

  return (
    <AnimatePresence>
      {isOpen[popupName] && (
        <motion.div
          className={`mobile-popup ${
            fitContent ? 'mobile-popup_type_fit-content' : ''
          }`}
          variants={visibilityVariants}
          initial="initial"
          animate="visible"
          exit="hidden"
          layout={!slideFromTop}
        >
          <motion.div
            className={`mobile-popup__container ${
              modal ? 'mobile-popup__container_type_modal' : ''
            } ${fitContent ? 'mobile-popup__container_type_fit-content' : ''} ${
              withCopyright ? 'mobile-popup__container_type_with-copyright' : ''
            } ${
              fitContent && noDots
                ? 'mobile-popup__container_type_small-radius'
                : ''
            }`}
            variants={
              slideFromTop
                ? slideFromTopPopupVariants
                : modal
                ? opacityVariants
                : defaultPopupVariants
            }
            layout={!slideFromTop}
          >
            {!noHeading && (
              <div className="mobile-popup__heading">
                {withLogo && (
                  <motion.button
                    className="mobile-popup__icon-btn"
                    type="button"
                    onClick={onClose}
                    whileTap={btnTapAnimation}
                  >
                    <EyeIcon
                      mainClassName="mobile-popup__logo-icon"
                      fillClassName="mobile-popup__icon-fill"
                    />
                  </motion.button>
                )}
                {!modal && !fullScreen && (
                  <motion.button
                    className="mobile-popup__icon-btn mobile-popup__icon-btn_type_close"
                    type="button"
                    onClick={onClose}
                    whileTap={btnTapAnimation}
                  >
                    <CloseIcon
                      mainClassName="mobile-popup__close-icon"
                      fillClassName="mobile-popup__icon-fill"
                    />
                  </motion.button>
                )}
              </div>
            )}
            {children}
            {withCopyright && (
              <p className="mobile-popup__copyright">{copyright}</p>
            )}

            {fitContent && !noDots && (
              <motion.div
                className="mobile-popup__dots-wrapper"
                onClick={onClose}
                variants={opacityVariants}
                layout
              >
                <ul className="mobile-popup__dots">
                  {Array.from({ length: 30 }).map((_, i) => (
                    <li
                      className={`mobile-popup__dot ${
                        tooltip ? 'mobile-popup__dot_type_tooltip' : ''
                      }`}
                      key={i}
                    />
                  ))}
                </ul>
              </motion.div>
            )}
          </motion.div>

          {(modal || fitContent) && (
            <motion.div
              className={`mobile-popup__overlay ${
                modal ? 'mobile-popup__overlay_type_modal' : ''
              }`}
              onClick={!modal ? onClose : undefined}
              variants={opacityVariants}
              layout
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default MobilePopup;
