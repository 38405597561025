import { useState, useContext, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './SearchPage.css';
import {
  SEARCH_VALUE_KEY,
  SEARCH_TYPE_KEY,
  ALL_TYPE,
  DESKTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  SEARCH_PAGE_DESKTOP_LIMIT,
  SEARCH_PAGE_TABLET_LIMIT,
  SEARCH_PAGE_MOBILE_LIMIT,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
} from '../../assets/utils/constants';
import { TranslationContext } from '../../assets/contexts/translationContext';
import SearchBar from '../Header/SearchDropdown/SearchBar/SearchBar';
import SearchNavBar from '../Header/SearchDropdown/SearchNavBar/SearchNavBar';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import useAutoDismissError from '../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../assets/hooks/useParseApiError';
import SearchResultsList from '../Header/SearchDropdown/SearchResultsList/SearchResultsList';
import PopularSearch from '../Header/SearchDropdown/PopularSearch/PopularSearch';
import mainApi from '../../assets/api/MainApi';
import useWindowSize from '../../assets/hooks/useWindowSize';

const contentVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

function SearchPage() {
  const {
    searchPage: { title, titleFor },
    searchDropdown: { noResults },
    labels: { loadMore },
  } = useContext(TranslationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get(SEARCH_VALUE_KEY);
  const type = searchParams.get(SEARCH_TYPE_KEY);
  const { width } = useWindowSize();
  const moreBtnRef = useRef(null);
  const [value, setValue] = useState(query);
  const [searchType, setSearchType] = useState(type);
  const [searchData, setSearchData] = useState(null);
  const [page, setPage] = useState(0);
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const [apiError, showApiError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchParams((prev) => {
        prev.set(SEARCH_VALUE_KEY, value);
        return prev;
      });

      if (!value) {
        clearSearch();
        return;
      }

      handleSearch(PRELOADER_MAIN_TYPE);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set(SEARCH_TYPE_KEY, searchType);
      return prev;
    });
    handleSearch(PRELOADER_MAIN_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);

  useEffect(() => {
    // Настраиваем Intersection Observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && searchData.is_more) {
          setPage(page + 1);
          handleSearch(PRELOADER_MORE_TYPE);
        }
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    const moreBtnRefCurrent = moreBtnRef?.current;
    if (moreBtnRefCurrent) {
      observer.observe(moreBtnRefCurrent);
      return () => observer.unobserve(moreBtnRefCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  function handleSearch(preloaderType) {
    const scroll_id =
      preloaderType === PRELOADER_MORE_TYPE ? searchData?.scroll_id : null;
    const limit =
      width > DESKTOP_BREAKPOINT
        ? SEARCH_PAGE_DESKTOP_LIMIT
        : width > TABLET_BREAKPOINT
        ? SEARCH_PAGE_TABLET_LIMIT
        : SEARCH_PAGE_MOBILE_LIMIT;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .search({
        limit,
        string: value,
        content_types: searchType,
        page: preloaderType === PRELOADER_MORE_TYPE ? page + 1 : null,
        scroll_id,
      })
      .then((res) => {
        setSearchData((prev) => ({
          ...res,
          data:
            preloaderType === PRELOADER_MORE_TYPE
              ? prev.data.concat(res.data)
              : res.data,
        }));
      })
      .catch((err) => {
        showApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  function clearSearch() {
    setValue('');
    setSearchData(null);
    setSearchType(ALL_TYPE);
    setSearchParams('');
    setPage(0);
    setIsPreloader({
      [PRELOADER_MAIN_TYPE]: false,
      [PRELOADER_MORE_TYPE]: false,
    });
  }

  function handleChange(e) {
    setValue(e.target.value);
    setPage(0);
  }

  function handleTypeChange(type) {
    setSearchType(type);
    setPage(0);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setPage(0);
    if (value) handleSearch(PRELOADER_MAIN_TYPE);
  }

  return (
    <motion.section
      className="search-page"
      initial="initial"
      animate="animate"
      exit="initial"
    >
      <h1 className="search-page__title">
        {title}
        {value && (
          <AnimatePresence mode="wait">
            <motion.span
              className="search-page__title-value"
              variants={contentVariants}
            >
              {` ${titleFor}: ${value}`}
            </motion.span>
          </AnimatePresence>
        )}
      </h1>

      <SearchBar
        value={value}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onClose={clearSearch}
        isPage
      />

      <AnimatePresence mode="wait" initial={false}>
        {searchData && (
          <motion.div
            className="search-page__navbar"
            variants={contentVariants}
          >
            <SearchNavBar
              searchType={searchType}
              setSearchType={handleTypeChange}
              isPage
            />
          </motion.div>
        )}
      </AnimatePresence>

      <ErrorMessage error={apiError} isApiError />

      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          className="search-page__content"
          variants={contentVariants}
          key={
            isPreloader[PRELOADER_MAIN_TYPE]
              ? 'preloader'
              : !searchData
              ? 'popular'
              : searchData?.data?.length === 0
              ? 'no-results'
              : searchData
          }
        >
          {isPreloader[PRELOADER_MAIN_TYPE] ? (
            <MiniPreloader />
          ) : !searchData ? (
            <PopularSearch isPage />
          ) : searchData?.data?.length > 0 ? (
            <SearchResultsList data={searchData.data} searchType={searchType} />
          ) : (
            <p className="search-page__no-results">{noResults}</p>
          )}
        </motion.div>
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {searchData?.is_more && (
          <motion.button
            ref={moreBtnRef}
            className="search-page__more-button"
            type="button"
            variants={contentVariants}
            style={{ opacity: isPreloader[PRELOADER_MORE_TYPE] ? 1 : 0 }}
          >
            {isPreloader[PRELOADER_MORE_TYPE] ? <MiniPreloader /> : loadMore}
          </motion.button>
        )}
      </AnimatePresence>
    </motion.section>
  );
}

export default SearchPage;
