import { useContext, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  CATALOG_DROPDOWN_TYPE,
  CATALOG_PAGE_MAIN_LINK,
  CATALOG_POPUP_DEFAULT_ROW_COUNT,
  CATALOG_POPUP_STORIES_ROW_COUNT,
  STORIES_TYPE,
  TAGS_TYPE,
  CATEGORIES_TYPE,
  DROPDOWN_ITEMS_VARIANTS,
  MOBILE_BREAKPOINT,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import './CatalogPopupBlock.css';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import StoryCard from './StoryCard/StoryCard';
import { PopupContext } from '../../../../assets/contexts/popupContex';
import { parseLink } from '../../../../assets/utils/utils';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import { DataContext } from '../../../../assets/contexts/dataContext';

const linkHoverAnimation = {
  scale: 1.02,
};

const linkTapAnimation = {
  scale: TAP_BTN_SCALE,
};

const contentVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function CatalogPopupBlock({ list, title, type, isPreloader, emptyText }) {
  const {
    catalogDropdown: { seeAll },
  } = useContext(TranslationContext);
  const { handlePopupClose } = useContext(PopupContext);
  const { lang } = useContext(DataContext);
  const { width, height } = useWindowSize();
  const dataRef = useRef(null);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);

  useEffect(() => {
    if (!dataRef?.current || !list) return;
    const containerWidth = dataRef.current.clientWidth;
    const containerHeight = dataRef.current.clientHeight;

    if (width > MOBILE_BREAKPOINT) {
      const rows =
        type === STORIES_TYPE
          ? CATALOG_POPUP_STORIES_ROW_COUNT
          : CATALOG_POPUP_DEFAULT_ROW_COUNT;
      const columns = containerWidth ? Math.floor(containerWidth / 220) : 0;
      const total = columns * rows - 1;
      const finalRows =
        list?.length < total ? Math.ceil((list?.length + 1) / columns) : rows;

      setRows(finalRows);
      setColumns(columns);
      setData(list.slice(0, total));
    } else {
      const rowDivider = type === STORIES_TYPE ? 65 : 30;
      const columnDivider =
        type === STORIES_TYPE ? 250 : width < 500 ? 125 : 140;
      const rows = containerHeight
        ? Math.floor(containerHeight / rowDivider)
        : 0;
      const columns = containerWidth
        ? Math.floor(containerWidth / columnDivider)
        : 0;

      setRows(rows);
      setColumns(columns);
      setData(list.slice(0, columns * rows));
    }
  }, [width, height, list, type]);

  function handleClose() {
    handlePopupClose(CATALOG_DROPDOWN_TYPE, false);
  }

  return (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        className="catalog-popup-block"
        key={type}
        variants={DROPDOWN_ITEMS_VARIANTS}
      >
        {width > MOBILE_BREAKPOINT && (
          <p className="catalog-popup-block__title">
            {title}
            {type === TAGS_TYPE ? ' #' : ''}
          </p>
        )}

        <motion.div
          className="catalog-popup-block__content"
          ref={dataRef}
          style={{ '--grid-rows': rows, '--grid-columns': columns }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <AnimatePresence mode="wait" initial={false}>
            {isPreloader ? (
              <motion.div
                className="catalog-popup-block__preloader"
                variants={contentVariants}
                key="preloader"
              >
                <MiniPreloader />
              </motion.div>
            ) : list?.length > 0 ? (
              <motion.ul
                className={`catalog-popup-block__items ${
                  type === STORIES_TYPE
                    ? 'catalog-popup-block__items_type_stories'
                    : ''
                }`}
                key={type}
                variants={contentVariants}
              >
                {data?.map((item) => (
                  <motion.li
                    className="catalog-popup-block__item"
                    key={item._id}
                    whileHover={linkHoverAnimation}
                    whileTap={linkTapAnimation}
                  >
                    {type === STORIES_TYPE ? (
                      <StoryCard data={item} onClose={handleClose} />
                    ) : (
                      <Link
                        to={parseLink(
                          type,
                          type === CATEGORIES_TYPE || type === TAGS_TYPE
                            ? item.slug
                            : item._id
                        )}
                        className="catalog-popup-block__link"
                        onClick={handleClose}
                        state={{ disableScrollToTop: false }}
                      >
                        {type === TAGS_TYPE ? item.slug : item.title?.[lang]}
                      </Link>
                    )}
                  </motion.li>
                ))}

                {width > MOBILE_BREAKPOINT && (
                  <motion.li
                    className="catalog-popup-block__item"
                    whileHover={linkHoverAnimation}
                    whileTap={linkTapAnimation}
                  >
                    <Link
                      to={`/${CATALOG_PAGE_MAIN_LINK}/${type}`}
                      className={`catalog-popup-block__link catalog-popup-block__link_type_all ${
                        type === STORIES_TYPE
                          ? 'catalog-popup-block__link_type_stories'
                          : ''
                      }`}
                      onClick={handleClose}
                      state={{ disableScrollToTop: false }}
                    >
                      {seeAll} →
                    </Link>
                  </motion.li>
                )}
              </motion.ul>
            ) : (
              <motion.p
                className="catalog-popup-block__empty"
                variants={contentVariants}
                key={`${type}-empty`}
              >
                {emptyText}
              </motion.p>
            )}
          </AnimatePresence>
        </motion.div>

        {width <= MOBILE_BREAKPOINT && list?.length > 0 && (
          <Link
            to={`/${CATALOG_PAGE_MAIN_LINK}/${type}`}
            className="catalog-popup-block__link catalog-popup-block__link_type_all"
            onClick={handleClose}
            state={{ disableScrollToTop: false }}
          >
            {seeAll}
          </Link>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default CatalogPopupBlock;
