import { useState, useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  Outlet,
  useSearchParams,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import moment from 'moment-timezone';
import { googleLogout } from '@react-oauth/google';
import Header from '../Header/Header';
import './App.css';
import MainPage from '../MainPage/MainPage';
import NotFound from '../NotFound/NotFound';
import { UserContext } from '../../assets/contexts/userContext';
import { PopupContext } from '../../assets/contexts/popupContex';
import {
  TranslationContext,
  translations,
} from '../../assets/contexts/translationContext';
import Footer from '../Footer/Footer';
import {
  AGE_VERIFIED,
  AUTH_MAIN_LINK,
  AUTH_POPUP_TYPE,
  CATALOG_DROPDOWN_TYPE,
  CATALOG_POPUP_MAIN_LINK,
  DESK_MAIN_LINK,
  EN_LANGUAGE,
  FOOTER_PRIVACY_LINK,
  FOOTER_SERVICE_LINK,
  MOBILE_BREAKPOINT,
  POPUP_TRANSITION_DURATION,
  PREFERENCES_LAST_VIEWED,
  PREFERENCES_POPUP_TYPE,
  READER_MAIN_LINK,
  VALIDE_LANG,
  VERIFY_AGE_POPUP_TYPE,
  AI_LINK,
  CATALOG_PAGE_MAIN_LINK,
  CONFIRM_EMAIL_POPUP_TYPE,
  AUTH_RESET_PASSWORD_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
  SEARCH_PAGE_MAIN_LINK,
  AUTH_LOGIN_LINK,
  TOP_STORIES_DATA_LIMIT,
  CATEGORIES_TYPE,
  TAGS_TYPE,
  TOP_STORIES_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_TYPE,
  PROFILE_DROPDOWN_TYPE,
  CATALOG_PAGE_STORIES_LINK,
  TOP_CATEGORIES_TYPE,
  TOOLTIP_TYPE,
} from '../../assets/utils/constants';
import StaticDocs from '../StaticDocs/StaticDocs';
import {
  PRIVACY_POLICY_TEXT,
  PRIVACY_POLICY_TITLE,
} from '../../assets/utils/legal_docs/privacy_policy';
import {
  TERMS_OF_SERVICE_TEXT,
  TERMS_OF_SERVICE_TITLE,
} from '../../assets/utils/legal_docs/user_agreement';
import Auth from '../Auth/Auth';
import VerifyAgePopup from '../VerifyAgePopup/VerifyAgePopup';
import PreferencesPopup from '../Auth/PreferencesPopup/PreferencesPopup';
import usePreventScroll from '../../assets/hooks/usePreventScroll';
import CatalogDropdown from '../Header/CatalogDropdown/CatalogDropdown';
import useWindowSize from '../../assets/hooks/useWindowSize';
import Reader from '../Reader/Reader';
import Desk from '../Desk/Desk';
import AiStories from '../AiStories/AiStories';
import Catalog from '../Catalog/Catalog';
import mainApi from '../../assets/api/MainApi';
import ConfirmEmailPopup from '../Auth/ConfirmEmailPopup/ConfirmEmailPopup';
import SearchPage from '../SearchPage/SearchPage';
import { DataContext } from '../../assets/contexts/dataContext';

function App() {
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang');
  const [language, setLanguage] = useState(
    localStorage.getItem('language') !== null &&
      VALIDE_LANG.includes(localStorage.getItem('language'))
      ? localStorage.getItem('language')
      : EN_LANGUAGE
  );
  const [user, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const [topCategories, setTopCategories] = useState(null);
  const [tags, setTags] = useState(null);
  const [topStories, setTopStories] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState({
    [CATEGORIES_TYPE]: true,
    [TAGS_TYPE]: true,
    [TOP_STORIES_TYPE]: true,
    [TOP_CATEGORIES_TYPE]: true,
  });
  const [isLogoutPreloader, setIsLogoutPreloader] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState({});
  usePreventScroll(
    Object.keys(isPopupOpen)
      .filter((popup) =>
        width > MOBILE_BREAKPOINT ? !popup.includes(TOOLTIP_TYPE) : popup
      )
      ?.some((popup) => isPopupOpen[popup])
  );

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, state } = location;
  const isStaticDocs =
    pathname.includes(FOOTER_PRIVACY_LINK.path) ||
    pathname.includes(FOOTER_SERVICE_LINK.path);
  const isAuthPage = pathname.includes(AUTH_MAIN_LINK);
  const isCatalogPopup = pathname.includes(CATALOG_POPUP_MAIN_LINK);
  const isReader =
    pathname.includes(READER_MAIN_LINK) ||
    state?.background?.pathname.includes(READER_MAIN_LINK);
  const isAiStoriesPage =
    pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK) ||
    state?.background?.pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK);
  const isDeskPage = pathname.includes(DESK_MAIN_LINK);
  const isBackDesk =
    location.state?.background?.pathname.includes(DESK_MAIN_LINK);
  const background = state?.background
    ? state.background
    : isAuthPage || isCatalogPopup
    ? { ...location, pathname: '/' }
    : null;

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(user));
    } else {
      console.warn('ReactNativeWebView не определён');
    }
  }, [user]);

  useEffect(() => {
    if (lang && VALIDE_LANG.includes(lang)) {
      setLanguage(lang);
      localStorage.setItem('language', lang);
    } else {
      localStorage.setItem('language', language);
    }
  }, [lang, language]);

  useEffect(() => {
    const age_submited = JSON.parse(localStorage.getItem(AGE_VERIFIED));
    const preferences_last_viewed = localStorage.getItem(
      PREFERENCES_LAST_VIEWED
    );

    if (isCatalogPopup) {
      handlePopupOpen(CATALOG_DROPDOWN_TYPE);
    }

    if (!user) {
      if (isAuthPage) {
        handlePopupOpen(AUTH_POPUP_TYPE);
      } else if (!age_submited) {
        handlePopupOpen(VERIFY_AGE_POPUP_TYPE);
      }
    } else {
      const stages = user?.reg_data?.stages;
      const isAllDataProvided = stages?.is_all_auth_data_provided;
      const isEmailVerified = stages?.is_email_verified;
      const isPreferencesMoreThanDay =
        moment().diff(preferences_last_viewed, 'days') > 1;
      const isNoPreferences = user.personal_data.category_ids.length === 0;

      if (!isAuthPage) {
        if (!isAllDataProvided) {
          handlePopupOpen(AUTH_POPUP_TYPE, AUTH_SIGNUP_ONE_MORE_STEP_LINK);
        } else if (!isEmailVerified) {
          handlePopupOpen(CONFIRM_EMAIL_POPUP_TYPE);
        } else if (isNoPreferences && isPreferencesMoreThanDay) {
          handlePopupOpen(PREFERENCES_POPUP_TYPE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, user]);

  useEffect(() => {
    getUser();
    getCategories();
    getTopCategories();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUserLoading) return;
    getTopStories();
  }, [user, isUserLoading]);

  function getUser() {
    setIsUserLoading(true);
    mainApi
      .getMe()
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
        if (isDeskPage) {
          navigate(AUTH_LOGIN_LINK, { state: { background: location } });
        }
      })
      .finally(() => {
        setIsUserLoading(false);
      });
  }

  function getCategories() {
    setIsDataLoading((prev) => ({ ...prev, [CATEGORIES_TYPE]: true }));
    mainApi
      .getAllCategories()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsDataLoading((prev) => ({ ...prev, [CATEGORIES_TYPE]: false }));
      });
  }

  function getTopCategories() {
    setIsDataLoading((prev) => ({ ...prev, [TOP_CATEGORIES_TYPE]: true }));
    mainApi
      .getTopCategories()
      .then((res) => {
        setTopCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsDataLoading((prev) => ({ ...prev, [TOP_CATEGORIES_TYPE]: false }));
      });
  }

  function getTags() {
    setIsDataLoading((prev) => ({ ...prev, [TAGS_TYPE]: true }));
    mainApi
      .getTopTags()
      .then((res) => {
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsDataLoading((prev) => ({ ...prev, [TAGS_TYPE]: false }));
      });
  }

  function getTopStories() {
    setIsDataLoading((prev) => ({ ...prev, [TOP_STORIES_TYPE]: true }));
    mainApi
      .getAllStories({ limit: TOP_STORIES_DATA_LIMIT })
      .then((res) => {
        setTopStories(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsDataLoading((prev) => ({ ...prev, [TOP_STORIES_TYPE]: false }));
      });
  }

  function handlePopupOpen(popup, link) {
    setIsPopupOpen((prev) => ({ ...prev, [popup]: true }));
    if (link) navigate(link, { state: { background: location } });
  }

  function handlePopupClose(
    popup,
    shouldNavigate = true,
    hasUser = Boolean(user),
    delay = POPUP_TRANSITION_DURATION * 1000
  ) {
    setIsPopupOpen((prev) => ({ ...prev, [popup]: false }));
    setTimeout(() => {
      if (background && shouldNavigate)
        navigate(isBackDesk && isAuthPage && !hasUser ? '/' : background, {
          state: { disableScrollToTop: true },
        });
    }, delay);
  }

  function logout() {
    setIsLogoutPreloader(true);
    mainApi
      .logout()
      .then(() => {
        setUser(null);
        googleLogout();
        if (isDeskPage) {
          navigate(AUTH_LOGIN_LINK, { state: { background: location } });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLogoutPreloader(false);
        if (isPopupOpen[PROFILE_DROPDOWN_TYPE]) {
          handlePopupClose(PROFILE_DROPDOWN_TYPE);
        }
      });
  }

  return (
    <TranslationContext.Provider value={translations[language]}>
      {/*<button onClick={methodDoesNotist}>Break the world</button>*/}
      <UserContext.Provider value={{ user, setUser, isUserLoading }}>
        <PopupContext.Provider
          value={{
            isPopupOpen,
            setIsPopupOpen,
            handlePopupOpen,
            handlePopupClose,
            logout,
            isLogoutPreloader,
          }}
        >
          <DataContext.Provider
            value={{
              lang: language.toLowerCase(),
              categories,
              tags,
              topStories,
              topCategories,
              isDataLoading,
            }}
          >
            <div
              className="app"
              style={{
                '--website-background-theme': isAiStoriesPage
                  ? 'var(--website-background-dark-theme)'
                  : 'var(--website-background)',
                '--neutral-active-theme': isAiStoriesPage
                  ? 'var(--neutral-active-dark-theme)'
                  : 'var(--neutral-active)',
                '--neutral-contrast-active-theme': isAiStoriesPage
                  ? 'var(--neutral-contrast-active-dark-theme)'
                  : 'var(--neutral-contrast-active)',
                '--neutral-line-primary-theme': isAiStoriesPage
                  ? 'var(--neutral-line-primary-dark-theme)'
                  : 'var(--neutral-line-primary)',
                '--catalog-block-bg-color-theme': isAiStoriesPage
                  ? 'var(--catalog-block-bg-color-dark-theme)'
                  : 'var(--catalog-block-bg-color)',
                '--neutral-border-primary-theme': isAiStoriesPage
                  ? 'var(--neutral-border-primary-dark-theme)'
                  : 'var(--neutral-border-primary)',
                '--box-shadow-primary-theme': isAiStoriesPage
                  ? 'var(--box-shadow-primary-dark-theme)'
                  : 'var(--box-shadow-primary)',
                '--auth-inactive-color-theme': isAiStoriesPage
                  ? 'var(--auth-inactive-color-dark-theme)'
                  : 'var(--auth-inactive-color)',
                '--auth-error-color-theme': isAiStoriesPage
                  ? 'var(--auth-error-color-dark-theme)'
                  : 'var(--auth-error-color)',
                '--main-active-tertiary-theme': isAiStoriesPage
                  ? 'var(--main-active-tertiary-dark-theme)'
                  : 'var(--main-active-tertiary)',
                '--pagination-text-color-theme': isAiStoriesPage
                  ? 'var(--pagination-text-color-dark-theme)'
                  : 'var(--pagination-text-color)',
              }}
            >
              {!isStaticDocs && <Header />}

              <div className="app__container">
                <Routes location={background || location}>
                  <Route index element={<MainPage />} />
                  <Route path="/" element={<MainPage />}>
                    <Route
                      path={`/${AUTH_MAIN_LINK}/*`}
                      element={
                        !user ||
                        pathname.includes(AUTH_RESET_PASSWORD_TYPE) ||
                        pathname.includes(AUTH_SIGNUP_ONE_MORE_STEP_TYPE) ? (
                          <Auth />
                        ) : (
                          <Navigate to={background} />
                        )
                      }
                    />
                    <Route
                      path={`/${CATALOG_POPUP_MAIN_LINK}/*`}
                      element={
                        width > MOBILE_BREAKPOINT ? (
                          <Navigate to={background} />
                        ) : (
                          <CatalogDropdown />
                        )
                      }
                    />
                  </Route>
                  <Route
                    path={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/:story_id`}
                    element={<Reader />}
                  />
                  <Route
                    path={`/${CATALOG_PAGE_MAIN_LINK}/*`}
                    element={<Catalog />}
                  />
                  <Route path={`/${AI_LINK}/*`} element={<AiStories />} />
                  <Route
                    path={`/${AI_LINK}/${READER_MAIN_LINK}/:story_id`}
                    element={<Reader />}
                  />
                  <Route path={`/${DESK_MAIN_LINK}/*`} element={<Desk />} />
                  <Route
                    path={`/${SEARCH_PAGE_MAIN_LINK}`}
                    element={<SearchPage />}
                  />
                  <Route
                    path={FOOTER_PRIVACY_LINK.path}
                    element={
                      <StaticDocs
                        title={PRIVACY_POLICY_TITLE}
                        text={PRIVACY_POLICY_TEXT}
                      />
                    }
                  />
                  <Route
                    path={FOOTER_SERVICE_LINK.path}
                    element={
                      <StaticDocs
                        title={TERMS_OF_SERVICE_TITLE}
                        text={TERMS_OF_SERVICE_TEXT}
                      />
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>

                {background && (
                  <Routes>
                    <Route
                      path={`/${AUTH_MAIN_LINK}/*`}
                      element={
                        !user ||
                        pathname.includes(AUTH_RESET_PASSWORD_TYPE) ||
                        pathname.includes(AUTH_SIGNUP_ONE_MORE_STEP_TYPE) ? (
                          <Auth />
                        ) : (
                          <Navigate to={background} />
                        )
                      }
                    />
                    <Route
                      path={`/${CATALOG_POPUP_MAIN_LINK}/*`}
                      element={
                        width > MOBILE_BREAKPOINT ? (
                          <Navigate to={background} />
                        ) : (
                          <CatalogDropdown />
                        )
                      }
                    />
                  </Routes>
                )}
              </div>

              {!isStaticDocs && !isReader && <Footer />}
              <VerifyAgePopup />
              <PreferencesPopup />
              <ConfirmEmailPopup />
              <Outlet />
            </div>
          </DataContext.Provider>
        </PopupContext.Provider>
      </UserContext.Provider>
    </TranslationContext.Provider>
  );
}

export default App;
