import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './CreatorCard.css';
import AvatarBox from '../../../AvatarBox/AvatarBox';
import {
  BASE_TIMING_FUNCTION,
  CREATOR_LINK,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import AuthorInfo from '../../../MainPage/AuthorsCards/AuthorInfo/AuthorInfo';

function CreatorCard({ personal_data, stats, _id }) {
  const {
    mainPage: {
      authorsCards: { stories },
    },
  } = useContext(TranslationContext);

  return (
    <Link
      className="creator-card"
      to={`/${CREATOR_LINK}/${_id}`}
      state={{ disableScrollToTop: false }}
    >
      <motion.div
        className="creator-card__container"
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
        transition={{ duration: 0.2, ease: BASE_TIMING_FUNCTION }}
      >
        <AvatarBox className="creator-card__img" data={personal_data} />
        <p className="creator-card__title">{personal_data?.name}</p>
        <p className="creator-card__subtitle">
          {stories(Number(stats?.published_stories))}
        </p>
        <AuthorInfo
          stats={{
            ...stats,
            views: stats?.stories_views,
            likes: stats?.stories_likes,
          }}
        />
      </motion.div>
    </Link>
  );
}

export default CreatorCard;
