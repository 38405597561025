import { useContext } from 'react';
import { Link } from 'react-router-dom';
import './AuthorCard.css';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import AvatarBox from '../../../AvatarBox/AvatarBox';
import { CREATOR_LINK } from '../../../../assets/utils/constants';
import AuthorInfo from '../AuthorInfo/AuthorInfo';

function AuthorCard({ card }) {
  const {
    mainPage: {
      authorsCards: { stories },
    },
  } = useContext(TranslationContext);

  return (
    <li className="author-card">
      <Link
        to={`/${CREATOR_LINK}/${card?._id}`}
        className="author-card__link"
        state={{ disableScrollToTop: false }}
      >
        <AvatarBox className="author-card__img" data={card?.personal_data} />

        <div className="author-card__text-block">
          <p className="author-card__title">{card?.personal_data?.name}</p>
          <p className="author-card__subtitle">
            {stories(Number(card?.stats?.published_stories))}
          </p>
          <AuthorInfo
            stats={{
              ...card?.stats,
              views: card?.stats?.stories_views,
              likes: card?.stats?.stories_likes,
            }}
          />
        </div>
      </Link>
    </li>
  );
}

export default AuthorCard;
