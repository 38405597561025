import { useContext } from 'react';
import { motion } from 'framer-motion';
import './PreferencesList.css';
import { TAP_BTN_SCALE } from '../../../../assets/utils/constants';
import { DataContext } from '../../../../assets/contexts/dataContext';

function PreferencesList({ data, handleSelect, selected, isList = false }) {
  const { lang } = useContext(DataContext);

  return (
    <ul
      className={`preferences-list ${
        isList ? 'preferences-list_align_left' : ''
      }`}
    >
      {data?.map((category) => (
        <li className="preferences-list__item" key={category._id}>
          {isList ? (
            <div className="preferences-list__btn">
              {category.title?.[lang]}
            </div>
          ) : (
            <motion.button
              className="preferences-list__btn"
              type="button"
              onClick={() => handleSelect(category)}
              initial={{ backgroundColor: 'var(--website-background-theme)' }}
              animate={{
                backgroundColor: selected?.some(
                  (item) => item._id === category._id
                )
                  ? 'var(--main-active-secondary)'
                  : 'var(--website-background-theme)',
              }}
              whileHover={{ opacity: 0.8 }}
              whileTap={{ scale: TAP_BTN_SCALE }}
            >
              {category.title?.[lang]}
            </motion.button>
          )}
        </li>
      ))}
    </ul>
  );
}

export default PreferencesList;
