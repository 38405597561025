import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Story.css';
import {
  READER_MAIN_LINK,
  CATALOG_PAGE_STORIES_LINK,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import { useImageLoader } from '../../../../assets/hooks/useImageLoader';

const StoryLink = ({ data, onClose, isImgLoading }) => {
  return (
    <Link
      to={`/${CATALOG_PAGE_STORIES_LINK}/${READER_MAIN_LINK}/${data?._id}`}
      className="search-dropdown-story"
      onClick={onClose}
      state={{ disableScrollToTop: false }}
    >
      <motion.div
        className="search-dropdown-story__container search-dropdown-story__container_with-border"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
      >
        <img
          className={`search-dropdown-story__image ${
            isImgLoading ? 'skeleton-loader' : ''
          }`}
          src={data?.meta?.cover?.urls?.orig}
          alt={data?.meta?.cover?.description ?? data?.title}
          loading="lazy"
        />
        <div className="search-dropdown-story__info">
          <p className="search-dropdown-story__subtitle">
            {data?.author?.personal_data?.name}
          </p>
          <p className="search-dropdown-story__title">{data?.title}</p>
        </div>
      </motion.div>
    </Link>
  );
};

const StoryBox = ({ data, isImgLoading }) => {
  return (
    <div className="search-dropdown-story">
      <div className="search-dropdown-story__container">
        <img
          className={`search-dropdown-story__image ${
            isImgLoading ? 'skeleton-loader' : ''
          }`}
          src={data?.meta?.cover?.urls?.orig}
          alt={data?.meta?.cover?.description ?? data?.title}
          loading="lazy"
        />
        <div className="search-dropdown-story__info">
          <p className="search-dropdown-story__subtitle">
            {data?.author?.personal_data?.name}
          </p>
          <p className="search-dropdown-story__title">{data?.title}</p>
        </div>
      </div>
    </div>
  );
};

function Story({ isLink, ...props }) {
  const { isImgLoading } = useImageLoader(props?.meta?.cover?.urls?.orig);

  return isLink ? (
    <StoryLink isImgLoading={isImgLoading} {...props} />
  ) : (
    <StoryBox isImgLoading={isImgLoading} {...props} />
  );
}

export default Story;
