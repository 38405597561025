import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import './Creator.css';
import CreatorHeading from './CreatorHeading/CreatorHeading';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import CatalogPageTemplate from '../Catalog/CatalogPageTemplate/CatalogPageTemplate';
import { TranslationContext } from '../../assets/contexts/translationContext';
import GalleryCard from '../MainPage/SwiperGallery/GalleryCard/GalleryCard';
import {
  CATEGORIES_TYPE,
  CREATOR_ID_TYPE_KEY,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  CATALOG_DESKTOP_CARDS_NUMBER,
  CATALOG_TABLET_CARDS_NUMBER,
  CATALOG_MOBILE_CARDS_NUMBER,
} from '../../assets/utils/constants';
import CategoriesList from '../MainPage/CategoriesList/CategoriesList';
import { UserContext } from '../../assets/contexts/userContext';
import ConfirmEmailBlock from '../Desk/ConfirmEmailBlock/ConfirmEmailBlock';
import mainApi from '../../assets/api/MainApi';
import useParseApiError from '../../assets/hooks/useParseApiError';
import useWindowSize from '../../assets/hooks/useWindowSize';

const contentVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

function Creator() {
  const {
    mainPage: {
      authorsCards: { stories: storiesText },
    },
    creatorPage: { categories: categoriesText, noCategories },
  } = useContext(TranslationContext);
  const { user, isUserLoading } = useContext(UserContext);
  const isEmailVerified = user?.reg_data?.stages?.is_email_verified;
  const { creator_id } = useParams();
  const { width } = useWindowSize();

  const [creator, setCreator] = useState(null);
  const [stories, setStories] = useState(null);
  const [hasMoreStories, setHasMoreStories] = useState(false);
  const [categories, setCategories] = useState(null);
  const [isCreatorPreloader, setIsCreatorPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [CATEGORIES_TYPE]: true,
  });
  const [isStoriesPreloader, setIsStoriesPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const [storiesError, setStoriesError] = useState(undefined);
  const [categoriesError, setCategoriesError] = useState(undefined);
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    if (!creator_id || isUserLoading) return;

    getCreator();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creator_id, isUserLoading, user]);

  function getCreator() {
    setIsCreatorPreloader((prev) => ({ ...prev, [PRELOADER_MAIN_TYPE]: true }));
    mainApi
      .getExactCreator({
        key: CREATOR_ID_TYPE_KEY,
        value: creator_id,
      })
      .then((res) => {
        setCreator(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsCreatorPreloader((prev) => ({
          ...prev,
          [PRELOADER_MAIN_TYPE]: false,
        }));
      });
  }

  function getCategories() {
    setIsCreatorPreloader((prev) => ({ ...prev, [CATEGORIES_TYPE]: true }));
    setCategories(null);
    setCategoriesError(undefined);
  }

  function getStories() {
    const last_id = stories?.[stories.length - 1]?._id;
    const limit =
      width > DESKTOP_BREAKPOINT
        ? CATALOG_DESKTOP_CARDS_NUMBER
        : width > MOBILE_BREAKPOINT
        ? CATALOG_TABLET_CARDS_NUMBER
        : CATALOG_MOBILE_CARDS_NUMBER;

    setIsStoriesPreloader((prev) => ({ ...prev, [PRELOADER_MAIN_TYPE]: true }));
    mainApi
      .getAllStories({ limit, last_id, author_id: creator_id })
      .then((res) => {
        setStories(res.data);
        setHasMoreStories(res.is_more);
      })
      .catch((err) => {
        setStoriesError(parseApiError(err));
      })
      .finally(() => {
        setIsStoriesPreloader((prev) => ({
          ...prev,
          [PRELOADER_MAIN_TYPE]: false,
        }));
      });
  }

  return (
    <motion.section
      className="creator"
      initial="initial"
      animate="animate"
      exit="initial"
    >
      {user && !isEmailVerified && <ConfirmEmailBlock />}
      <AnimatePresence mode="wait" initial={false}>
        {isCreatorPreloader[PRELOADER_MAIN_TYPE] ? (
          <motion.div
            className="creator__preloader"
            variants={contentVariants}
            key="preloader"
          >
            <MiniPreloader />
          </motion.div>
        ) : (
          <motion.div
            className="creator__content"
            variants={contentVariants}
            key="content"
          >
            {creator && (
              <>
                <CreatorHeading setData={setCreator} {...creator} />

                <CatalogPageTemplate
                  title={storiesText(Number(creator?.stats?.published_stories))}
                  data={stories}
                  getData={getStories}
                  isPreloader={isStoriesPreloader}
                  hasMore={hasMoreStories}
                  apiError={storiesError}
                  isBlock
                >
                  {stories?.map((card) => (
                    <GalleryCard
                      key={card._id}
                      card={card}
                      isCreatorPage
                      isGrid
                    />
                  ))}
                </CatalogPageTemplate>

                {/* <CategoriesList
                  block={{
                    frontend_data: { title: { [lang]: categoriesText } },
                  }}
                  data={categories}
                  isPreloader={isCreatorPreloader[CATEGORIES_TYPE]}
                  noDataText={noCategories}
                  showAll
                />
                <ErrorMessage error={categoriesError} isApiError /> */}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.section>
  );
}

export default Creator;
