import Cookies from 'js-cookie';
import {
  DEFAULT_DATA_LIMIT,
  MAIN_URL,
  OAUTH_GOOGLE_TYPE,
} from '../utils/constants';

class MainApi {
  _checkResponse(res) {
    if (res.ok) {
      return res.status === 204 ? res : res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.status === 204 ? res : res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 401 && res.detail === 'Signature has expired') {
          console.log('ss');
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  generateCSRF() {
    return fetch(`${MAIN_URL}/generate-csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(mainApi._checkResponse);
  }

  refreshJWT() {
    return fetch(`${MAIN_URL}/auth/refresh-jwt`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then(mainApi._checkResponse);
  }

  login({ email, password, csrf_cookie_value }) {
    return fetch(`${MAIN_URL}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Remember-Me': true,
        'X-CSRF-TOKEN': csrf_cookie_value,
      },
      body: JSON.stringify({ email, password }),
    }).then(mainApi._checkResponse);
  }

  logout() {
    return fetch(`${MAIN_URL}/auth/logout`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.logout,
        params: {},
      })
    );
  }

  getMe() {
    return fetch(`${MAIN_URL}/auth/get-me`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMe,
        params: {},
      })
    );
  }

  signup({ email, password, name, date_of_birth, csrf_cookie_value }) {
    return fetch(`${MAIN_URL}/auth/register/signup`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_cookie_value,
      },
      body: JSON.stringify({
        language: 'en',
        email,
        password,
        name,
        date_of_birth,
      }),
    }).then(mainApi._checkResponse);
  }

  resendVerificationEmail() {
    return fetch(`${MAIN_URL}/auth/register/resend-email`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.resendVerificationEmail,
        params: {},
      })
    );
  }

  oauthRegister({ credential, type = OAUTH_GOOGLE_TYPE, csrf_cookie_value }) {
    let params = {};
    if (type) params.type = type;

    return fetch(
      `${MAIN_URL}/auth/oauth/register?` + new URLSearchParams(params),
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': csrf_cookie_value,
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
        body: JSON.stringify({ credential }),
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.oauthRegister,
        params: { credential, type, csrf_cookie_value },
      })
    );
  }

  oauthRemove({ type = OAUTH_GOOGLE_TYPE }) {
    let params = {};
    if (type) params.type = type;

    return fetch(
      `${MAIN_URL}/auth/oauth/remove?` + new URLSearchParams(params),
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.oauthRemove,
        params: { type },
      })
    );
  }

  changeEmail({ email }) {
    return fetch(`${MAIN_URL}/auth/change-email/main`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ email }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.changeEmail,
        params: { email },
      })
    );
  }

  checkCodeForEmailChange({ code }) {
    return fetch(`${MAIN_URL}/auth/change-email/check-code`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ code }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkCodeForEmailChange,
        params: { code },
      })
    );
  }

  checkCurrentPassword({ current_password }) {
    return fetch(`${MAIN_URL}/auth/password/check`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ current_password }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkCurrentPassword,
        params: { current_password },
      })
    );
  }

  updatePassword({ current_password, new_password }) {
    return fetch(`${MAIN_URL}/auth/password/update`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ current_password, new_password }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.updatePassword,
        params: { current_password, new_password },
      })
    );
  }

  resetPasswordNOAuth({ email }) {
    return fetch(`${MAIN_URL}/auth/forgot-password/main-not-logged-in`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }).then(mainApi._checkResponse);
  }

  resetPasswordWithAuth() {
    return fetch(`${MAIN_URL}/auth/forgot-password/main`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.resetPasswordWithAuth,
        params: {},
      })
    );
  }

  changePassword({ password, token, return_url = null }) {
    return fetch(`${MAIN_URL}/auth/forgot-password/change`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password, token, return_url }),
    }).then(mainApi._checkResponse);
  }

  editUserName({ username }) {
    return fetch(`${MAIN_URL}/auth/register/set-username`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({
        set_data: { username: username || null },
        unset_data: { username: !Boolean(username) },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editUserName,
        params: { username },
      })
    );
  }

  editPersonalData({
    name,
    date_of_birth,
    avatar,
    unset_avatar = false,
    category_ids,
    unset_categories = false,
  }) {
    return fetch(`${MAIN_URL}/auth/register/edit-personal-data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({
        set_data: {
          language: 'en',
          name: name || null,
          date_of_birth: date_of_birth || null,
          avatar: avatar || null,
          category_ids: category_ids || null,
        },
        unset_data: { avatar: unset_avatar, category_ids: unset_categories },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editPersonalData,
        params: {
          name,
          date_of_birth,
          avatar,
          unset_avatar,
          category_ids,
          unset_categories,
        },
      })
    );
  }

  async uploadImage({ data, type }) {
    let params = {};
    if (type) params.type = type;

    return fetch(`${MAIN_URL}/images/upload?` + new URLSearchParams(params), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: data,
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.uploadImage,
        params: { data, type },
      })
    );
  }

  getAllBlocks({ limit = DEFAULT_DATA_LIMIT, last_id }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;

    return fetch(
      `${MAIN_URL}/main-page/get-all-blocks?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(mainApi._checkResponse);
  }

  getExactBlock({ key, value }) {
    let params = {};
    if (key) params.key = key;
    if (value) params.value = value;

    return fetch(
      `${MAIN_URL}/main-page/get-exact?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(mainApi._checkResponse);
  }

  getMainPage() {
    return fetch(`${MAIN_URL}/main-page/get-main-page`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMainPage,
        params: {},
      })
    );
  }

  getMainPageExact(data) {
    let params = {};
    if (data.key) params.key = data.key;
    if (data.value) params.value = data.value;
    if (data['pagination.cursor'])
      params['pagination.cursor'] = data['pagination.cursor'];
    if (data['pagination.data'])
      params['pagination.data'] = JSON.stringify(data['pagination.data']);
    if (data.input_args) params.input_args = JSON.stringify(data.input_args);

    return fetch(
      `${MAIN_URL}/main-page/get-main-page-exact?` +
        new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMainPageExact,
        params: data,
      })
    );
  }

  getAllCategories() {
    return fetch(`${MAIN_URL}/categories/get-all`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(mainApi._checkResponse);
  }

  getTopCategories() {
    return fetch(`${MAIN_URL}/categories/get-top`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(mainApi._checkResponse);
  }

  getExactCategory({ key, value }) {
    let params = {};
    if (key) params.key = key;
    if (value) params.value = value;

    return fetch(
      `${MAIN_URL}/categories/get-exact?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(mainApi._checkResponse);
  }

  getTopTags() {
    return fetch(`${MAIN_URL}/tags/get-top`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(mainApi._checkResponse);
  }

  getExactTag({ key, value }) {
    let params = {};
    if (key) params.key = key;
    if (value) params.value = value;

    return fetch(`${MAIN_URL}/tags/get-exact?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(mainApi._checkResponse);
  }

  getAllCreators({ limit, last_id }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;

    return fetch(`${MAIN_URL}/authors/get-all?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllCreators,
        params: { limit, last_id },
      })
    );
  }

  getExactCreator({ key, value }) {
    let params = {};
    if (key) params.key = key;
    if (value) params.value = value;

    return fetch(
      `${MAIN_URL}/authors/get-exact?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getExactCreator,
        params: { key, value },
      })
    );
  }

  getAllStories({
    limit = DEFAULT_DATA_LIMIT,
    last_id,
    category_id,
    tag,
    author_id,
  }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (category_id) params.category_id = category_id;
    if (tag) params.tag = tag;
    if (author_id) params.author_id = author_id;

    return fetch(`${MAIN_URL}/books/get-all?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllStories,
        params: { limit, last_id, category_id, tag, author_id },
      })
    );
  }

  getStoriesByReadMarks({
    limit = DEFAULT_DATA_LIMIT,
    last_id,
    last_update,
    is_completed = false,
  }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (last_update) params.last_update = last_update;
    if (is_completed) params.is_completed = is_completed;

    return fetch(
      `${MAIN_URL}/books/get-by-read-marks?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getStoriesByReadMarks,
        params: { limit, last_id, last_update, is_completed },
      })
    );
  }

  getExactStory({ key, value, as_owner = false }) {
    let params = {};
    if (key) params.key = key;
    if (value) params.value = value;
    if (as_owner) params.as_owner = as_owner;

    return fetch(`${MAIN_URL}/books/get-exact?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getExactStory,
        params: { key, value, as_owner },
      })
    );
  }

  getStoryFile({ _id, file_id }) {
    let params = {};
    if (_id) params._id = _id;
    if (file_id) params.file_id = file_id;

    return fetch(
      `${MAIN_URL}/books/get-book-file?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getStoryFile,
        params: { _id, file_id },
      })
    );
  }

  buyStory({ _id }) {
    return fetch(`${MAIN_URL}/books/buy`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ _id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.buyStory,
        params: { _id },
      })
    );
  }

  setReadMark({ _id, value, is_completed = false }) {
    return fetch(`${MAIN_URL}/books/set-read-mark`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ _id, value, is_completed }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setReadMark,
        params: { _id, value, is_completed },
      })
    );
  }

  getAllComments({ limit = DEFAULT_DATA_LIMIT, last_id, _id, reply_to }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (_id) params._id = _id;
    if (reply_to) params.reply_to = reply_to;

    return fetch(
      `${MAIN_URL}/books/comments/get-all?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllComments,
        params: { limit, last_id, _id, reply_to },
      })
    );
  }

  addComment({ _id, text, reply_to }) {
    return fetch(`${MAIN_URL}/books/comments/add`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ _id, text, reply_to }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.addComment,
        params: { _id, text, reply_to },
      })
    );
  }

  editComment({ _id, text }) {
    return fetch(`${MAIN_URL}/books/comments/update`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ _id, text }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editComment,
        params: { _id, text },
      })
    );
  }

  deleteComment({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/books/comments/delete?` + new URLSearchParams(params),
      {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteComment,
        params: { _id },
      })
    );
  }

  getAllAiStories({ limit = DEFAULT_DATA_LIMIT, last_id }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;

    return fetch(`${MAIN_URL}/ai/get-all?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllAiStories,
        params: { limit, last_id },
      })
    );
  }

  getExactAiStory({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(`${MAIN_URL}/ai/get-exact?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getExactAiStory,
        params: { _id },
      })
    );
  }

  createStory({ text }) {
    return fetch(`${MAIN_URL}/ai/create-story`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ text }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createStory,
        params: { text },
      })
    );
  }

  getLikes({
    limit = DEFAULT_DATA_LIMIT,
    last_id,
    content_type,
    utc_datetime,
  }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;
    if (content_type) params.content_type = content_type;
    if (utc_datetime) params.utc_datetime = utc_datetime;

    return fetch(
      `${MAIN_URL}/likes/get-all-personal?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getLikes,
        params: { limit, last_id, content_type, utc_datetime },
      })
    );
  }

  setLike({ _id, content_type, value = true }) {
    return fetch(`${MAIN_URL}/likes/set`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
      body: JSON.stringify({ _id, content_type, value }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setLike,
        params: { _id, content_type, value },
      })
    );
  }

  search({
    limit = DEFAULT_DATA_LIMIT,
    string,
    content_types,
    page,
    scroll_id,
  }) {
    let params = {};
    if (limit) params.limit = limit;
    if (string) params.string = string;
    if (content_types) params.content_types = content_types;
    if (page) params.page = page;
    if (scroll_id) params.scroll_id = scroll_id;

    return fetch(`${MAIN_URL}/search/query?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
        'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.search,
        params: { limit, string, content_types, page, scroll_id },
      })
    );
  }

  getHistory({
    limit = DEFAULT_DATA_LIMIT,
    last_id,
    content_type,
    utc_datetime,
  }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (content_type) params.content_type = content_type;
    if (utc_datetime) params.utc_datetime = utc_datetime;

    return fetch(
      `${MAIN_URL}/history/get-all-personal?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ACCESS-CSRF-TOKEN': Cookies.get('csrf_access_token'),
          'X-REFRESH-CSRF-TOKEN': Cookies.get('csrf_refresh_token'),
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getHistory,
        params: { limit, last_id, content_type, utc_datetime },
      })
    );
  }
}

const mainApi = new MainApi();

export default mainApi;
