import { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import './TagsList.css';
import { RightArrowIcon } from '../../../assets/icons/icons';
import {
  CATALOG_PAGE_TAGS_LINK,
  TAG_TYPE,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TAP_BTN_SCALE,
} from '../../../assets/utils/constants';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

const dividerColors = [
  '--tags-list-divider-color-primary',
  '--tags-list-divider-color-secondary',
  '--tags-list-divider-color-tertiary',
];

const tagColors = [
  [
    '--tags-list-color-primary',
    '--tags-list-color-secondary',
    '--tags-list-color-tertiary',
  ],
  [
    '--tags-list-color-quaternary',
    '--tags-list-color-quinary',
    '--tags-list-color-senary',
  ],
  [
    '--tags-list-color-septenary',
    '--tags-list-color-octonary',
    '--tags-list-color-nonary',
  ],
];

const linkHoverAnimation = {
  scale: 1.01,
};

const linkTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function TagsList({ data, showAll, isPreloader, noDataText }) {
  const { width } = useWindowSize();
  const isMobileWidth = width <= MOBILE_BREAKPOINT;
  const isTabletWidth = width <= TABLET_BREAKPOINT;
  const firstLineRef = useRef(null);
  const secondLineRef = useRef(null);
  const thirdLineRef = useRef(null);
  const fourthLineRef = useRef(null);
  const fifthLineRef = useRef(null);
  const refs = [
    firstLineRef,
    secondLineRef,
    thirdLineRef,
    fourthLineRef,
    fifthLineRef,
  ];
  const visibleRefs = isMobileWidth ? refs : refs.slice(0, 3);
  const [visibleTags, setVisibleTags] = useState([]);

  useEffect(() => {
    if (data?.length === 0 || showAll) return;

    const padding = isMobileWidth ? 32 : isTabletWidth ? 39 : 52;
    const linkBtnWidth = isMobileWidth ? 46 : isTabletWidth ? 60 : 76;

    const updateVisibleLinks = () => {
      const tagsListToShow = [];
      let tags = [...data];

      visibleRefs.forEach((ref, i) => {
        if (ref?.current) {
          const lastPadding = visibleRefs.length - 1 === i ? linkBtnWidth : 0;
          const maxAllowedWidth = ref.current.offsetWidth - lastPadding;
          if (maxAllowedWidth <= 0) return;
          let totalWidth = 0;
          const tagsToShow = [];

          tags.forEach((tag) => {
            const tagElement = document.createElement('div');
            tagElement.className = 'tags-list__tag';
            tagElement.style.visibility = 'hidden';
            tagElement.style.position = 'absolute';
            tagElement.innerText = tag.slug;
            document.body.appendChild(tagElement);
            const tagWidth = tagElement.offsetWidth + padding;
            document.body.removeChild(tagElement);

            if (totalWidth + tagWidth <= maxAllowedWidth) {
              tagsToShow.push(tag);
              totalWidth += tagWidth;
            }
          });

          tagsListToShow.push(tagsToShow);
          tags = tags.filter((tag) =>
            tagsToShow.every((t) => t._id !== tag._id)
          );
        }
      });

      const tagsWithColor = addColor(tagsListToShow);
      setVisibleTags(tagsWithColor);
    };

    updateVisibleLinks();

    window.addEventListener('resize', updateVisibleLinks);
    return () => window.removeEventListener('resize', updateVisibleLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width]);

  useEffect(() => {
    if (!showAll) return;

    setVisibleTags([]);
    const tagsWithColor = addColorToAllTags(data);
    setVisibleTags(tagsWithColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll, data]);

  function addColor(data) {
    if (!data) return [];

    const coloredList = data.map((list, i) => {
      const colorList = isMobileWidth ? tagColors.flat() : tagColors[i];
      if (list?.length === 0 || !colorList || colorList?.length === 0)
        return list;

      const desktopDivider = Math.round(list.length / colorList.length);
      const mobileDivider = Math.round(data.flat().length / colorList.length);
      const divider = isMobileWidth ? mobileDivider : desktopDivider;

      return list.map((tag, j) => {
        const prevListsLength = i === 0 ? 0 : data.slice(0, i).flat().length;
        const index = isMobileWidth ? j + prevListsLength : j;
        const color = colorList[Math.floor(index / divider)];
        const lastColor = colorList[colorList.length - 1];

        return {
          ...tag,
          color: color ? `var(${color})` : `var(${lastColor})`,
        };
      });
    });

    return coloredList;
  }

  function addColorToAllTags(data) {
    if (!data) return [];

    const colorList = tagColors.flat();
    const divider = Math.round(data.length / colorList.length);

    const coloredList = data.map((item, i) => {
      const color = colorList[Math.floor(i / divider)];
      const lastColor = colorList[colorList.length - 1];
      return {
        ...item,
        color: color ? `var(${color})` : `var(${lastColor})`,
      };
    });

    return coloredList;
  }

  return (
    <section className="tags-list">
      {showAll ? (
        <ul className="tags-list__content">
          <div className="tags-list__title-box">
            <p className="tags-list__title">#</p>
          </div>

          {isPreloader ? (
            <MiniPreloader />
          ) : visibleTags?.length > 0 ? (
            visibleTags.map((tag) => (
              <motion.li
                key={tag._id}
                className="tags-list__tag-box"
                style={{
                  '--divider-color': `var(--tags-list-divider-color-tertiary)`,
                }}
                whileHover={linkHoverAnimation}
                whileTap={linkTapAnimation}
              >
                <Link
                  to={`/${CATALOG_PAGE_TAGS_LINK}/${TAG_TYPE}/${tag.slug}`}
                  className="tags-list__tag-link"
                  state={{ disableScrollToTop: false }}
                >
                  <p className="tags-list__tag" style={{ color: tag.color }}>
                    {tag.slug}
                  </p>
                  <span className="tags-list__tag-count">
                    {tag.stats?.published_stories}
                  </span>
                </Link>
              </motion.li>
            ))
          ) : (
            <p className="tags-list__tag">{noDataText}</p>
          )}
        </ul>
      ) : (
        visibleRefs.map((ref, i) => (
          <div className="tags-list__line-box" key={i}>
            {i === 0 && (
              <div className="tags-list__title-box">
                <p className="tags-list__title">#</p>
              </div>
            )}

            <ul className="tags-list__line" ref={ref}>
              {visibleTags[i]?.map((tag, j) => (
                <li
                  key={tag._id}
                  className="tags-list__tag-box"
                  style={{ '--divider-color': `var(${dividerColors[i]})` }}
                >
                  <motion.div
                    className="tags-list__tag-wrapper"
                    whileHover={linkHoverAnimation}
                    whileTap={linkTapAnimation}
                  >
                    <Link
                      to={`/${CATALOG_PAGE_TAGS_LINK}/${TAG_TYPE}/${tag.slug}`}
                      className="tags-list__tag-link"
                      state={{ disableScrollToTop: false }}
                    >
                      <p
                        className="tags-list__tag"
                        style={{ color: tag.color }}
                      >
                        {tag.slug}
                      </p>
                      <span className="tags-list__tag-count">
                        {tag.stats?.published_stories}
                      </span>
                    </Link>
                  </motion.div>

                  {i === visibleTags.length - 1 &&
                    visibleTags[i]?.length === j + 1 && (
                      <motion.div
                        className="tags-list__arrow-wrapper"
                        whileHover={{ scale: 1.03 }}
                        whileTap={linkTapAnimation}
                      >
                        <Link
                          to={`/${CATALOG_PAGE_TAGS_LINK}`}
                          className="tags-list__arrow-box"
                          state={{ disableScrollToTop: false }}
                        >
                          <RightArrowIcon
                            mainClassName="tags-list__arrow"
                            fillClassName="tags-list__arrow-fill"
                          />
                        </Link>
                      </motion.div>
                    )}
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </section>
  );
}

export default TagsList;
