import { useContext, useState } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  CATALOG_DESKTOP_SMALL_CARDS_NUMBER,
  CATALOG_TABLET_SMALL_CARDS_NUMBER,
  CATALOG_MOBILE_SMALL_CARDS_NUMBER,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
} from '../../../assets/utils/constants';
import CatalogPageTemplate from '../CatalogPageTemplate/CatalogPageTemplate';
import GalleryCard from '../../MainPage/SwiperGallery/GalleryCard/GalleryCard';
import './AiPage.css';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import mainApi from '../../../assets/api/MainApi';
import useParseApiError from '../../../assets/hooks/useParseApiError';

function AiPage() {
  const {
    aiPage: { title, noStories },
  } = useContext(TranslationContext);
  const { width } = useWindowSize();
  const [data, setData] = useState(null);
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const [hasMore, setHasMore] = useState(false);
  const [apiError, setApiError] = useState(undefined);
  const { parseApiError } = useParseApiError();

  function getStories(preloaderType) {
    const last_id = data?.[data.length - 1]?._id;
    const limit =
      width > DESKTOP_BREAKPOINT
        ? CATALOG_DESKTOP_SMALL_CARDS_NUMBER
        : width > MOBILE_BREAKPOINT
        ? CATALOG_TABLET_SMALL_CARDS_NUMBER
        : CATALOG_MOBILE_SMALL_CARDS_NUMBER;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .getAllAiStories({ limit, last_id })
      .then((res) => {
        setData((prev) =>
          preloaderType === PRELOADER_MORE_TYPE
            ? prev.concat(res.data)
            : res.data
        );
        setHasMore(res.is_more);
      })
      .catch((err) => {
        setApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  return (
    <CatalogPageTemplate
      title={title}
      data={data}
      getData={getStories}
      noDataText={noStories}
      isPreloader={isPreloader}
      hasMore={hasMore}
      apiError={apiError}
      isSmallCards
    >
      {data?.map((card, i) => (
        <GalleryCard
          key={card._id}
          card={card}
          index={i}
          isGrid
          isSmallCards
          isAi
        />
      ))}
    </CatalogPageTemplate>
  );
}

export default AiPage;
