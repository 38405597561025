import { useCallback, useEffect } from 'react';
import { ESCAPE_KEY, KEY_UP_EVENT_TYPE } from '../utils/constants';

export default function useEscapeKey(handleClose, stopPropagation = false) {
  const handleEscKey = useCallback((evt) => {
    if (stopPropagation) {
      evt.stopImmediatePropagation();
      return;
    }

    if (evt.key === ESCAPE_KEY && handleClose) {
      handleClose();
    }
  }, [handleClose, stopPropagation]);

  useEffect(() => {
    document.addEventListener(KEY_UP_EVENT_TYPE, handleEscKey, false);

    return () => {
      document.removeEventListener(KEY_UP_EVENT_TYPE, handleEscKey, false);
    };
  }, [handleEscKey]);
}
