import { useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import './GoogleLoginBtn.css';
import mainApi from '../../../../../assets/api/MainApi';
import { OAUTH_GOOGLE_TYPE } from '../../../../../assets/utils/constants';
import useParseApiError from '../../../../../assets/hooks/useParseApiError';
import { UserContext } from '../../../../../assets/contexts/userContext';

function GoogleLoginBtn({ setIsPreloader, showError }) {
  const { setUser } = useContext(UserContext);
  const { parseApiError } = useParseApiError();

  function oauthRegister({ credentialResponse, token }) {
    mainApi
      .oauthRegister({
        credential: credentialResponse.credential,
        type: OAUTH_GOOGLE_TYPE,
        csrf_cookie_value: token?.csrf_token || null,
      })
      .then((res) => {
        setUser(res);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [OAUTH_GOOGLE_TYPE]: false }));
      });
  }

  const handleLoginWithGoogleSuccess = (credentialResponse) => {
    setIsPreloader((prev) => ({ ...prev, [OAUTH_GOOGLE_TYPE]: true }));
    mainApi
      .generateCSRF()
      .then((token) => {
        oauthRegister({ credentialResponse, token });
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 404:
            oauthRegister({ credentialResponse });
            break;

          default:
            showError(parseApiError(err));
            setIsPreloader((prev) => ({ ...prev, [OAUTH_GOOGLE_TYPE]: false }));
        }
      });
  };

  const handleLoginWithGoogleError = (error) => {
    console.log(error);
    showError(parseApiError(error));
  };

  return (
    <GoogleLogin
      onSuccess={handleLoginWithGoogleSuccess}
      onError={handleLoginWithGoogleError}
      shape="pill"
      ux_mode="popup"
      theme="outline"
      size="medium"
      useOneTap
    />
  );
}

export default GoogleLoginBtn;
