import { useEffect, useRef, useState, useContext } from 'react';
import { useInView } from 'framer-motion';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { RhombIcon } from '../../../assets/icons/icons';
import './AuthorsCards.css';
import AnimatedAuthorCard from './AnimatedAuthorCard/AnimatedAuthorCard';
import AuthorCard from './AuthorCard/AuthorCard';
import useCycle from '../../../assets/hooks/useCycle';
import { MOBILE_BREAKPOINT } from '../../../assets/utils/constants';
import { DataContext } from '../../../assets/contexts/dataContext';

function AuthorsCards({ data, block }) {
  const { frontend_data } = block;

  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const cardsRef = useRef(null);
  const cardsInView = useInView(cardsRef, { amount: 0.5 });
  const [visibleCards, setVisibleCards] = useState(data);
  const [activeCard, toggleActiveCard] = useCycle([
    ...Array.from({ length: visibleCards.length }, (_, i) => i),
  ]);

  useEffect(() => {
    const divider = width > 1155 ? 155 : 180;
    const containerWidth = cardsRef?.current?.clientWidth;
    const cardsNumber = containerWidth
      ? Math.floor(containerWidth / divider)
      : 0;
    setVisibleCards(data.slice(0, cardsNumber));
  }, [width, data]);

  useEffect(() => {
    if (!cardsInView) return; // Do not set up the interval if not in view

    const interval = setInterval(() => {
      toggleActiveCard();
    }, 8000);

    return () => clearInterval(interval); // Cleanup on unmount or when cardsInView changes
  }, [cardsInView, toggleActiveCard]);

  function handleCardClick(i) {
    toggleActiveCard(i);
    // Optionally, reset the timer by clearing and setting up the interval again
    // This requires more complex state management, such as using useRef to store the interval ID
  }

  return (
    <section className="authors-cards" ref={cardsRef}>
      <div className="authors-cards__heading">
        <h2 className="authors-cards__title">{frontend_data?.title?.[lang]}</h2>
        <div className="authors-cards__line">
          <RhombIcon
            mainClassName="authors-cards__line-icon authors-cards__line-icon_position_left"
            fillClassName="authors-cards__line-icon-fill"
          />
          <RhombIcon
            mainClassName="authors-cards__line-icon authors-cards__line-icon_position_right"
            fillClassName="authors-cards__line-icon-fill"
          />
        </div>
      </div>

      {width > MOBILE_BREAKPOINT ? (
        <ul className="authors-cards__content-box">
          {visibleCards.map((card, i) => (
            <AnimatedAuthorCard
              card={card}
              activeCard={activeCard}
              i={i}
              length={visibleCards.length}
              handleCardClick={handleCardClick}
              key={card._id}
            />
          ))}
        </ul>
      ) : (
        <div className="authors-cards__content-box">
          <ul className="authors-cards__list">
            {data.map((card) => (
              <AuthorCard card={card} key={card._id} />
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}

export default AuthorsCards;
