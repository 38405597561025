import { useContext, useEffect, useState } from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import mainApi from '../../../assets/api/MainApi';
import {
  STORIES_TYPE,
  STORIES_API_TYPE,
  CONTENT_TYPES_NAV_LIST,
  CREATORS_TYPE,
  CREATORS_API_TYPE,
  AI_TYPE,
  CATEGORIES_TYPE,
  TAGS_TYPE,
  DESK_DESKTOP_CARDS_NUMBER,
  DESK_TABLET_CARDS_NUMBER,
  DESKTOP_BREAKPOINT,
  DESK_HISTORY_LINK,
  CONTENT_TRANSITION_DURATION,
  BASE_TIMING_FUNCTION,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
} from '../../../assets/utils/constants';
import './History.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { UserContext } from '../../../assets/contexts/userContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import ConfirmEmailBlock from '../ConfirmEmailBlock/ConfirmEmailBlock';
import DeskGrid from '../DeskGrid/DeskGrid';
import GalleryCard from '../../MainPage/SwiperGallery/GalleryCard/GalleryCard';
import CreatorCard from '../../Catalog/CreatorsPage/CreatorCard/CreatorCard';
import ContentTypesNav from '../ContentTypesNav/ContentTypesNav';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';

function History() {
  const { user } = useContext(UserContext);
  const isEmailVerified = user?.reg_data?.stages?.is_email_verified;
  const isAllDataProvided = user?.reg_data?.stages?.is_all_auth_data_provided;
  const {
    desk: {
      history: { noHistory },
    },
  } = useContext(TranslationContext);
  const location = useLocation();
  const path = location.pathname.split('/')[3];

  const [data, setData] = useState({
    [STORIES_API_TYPE]: null,
    [CREATORS_API_TYPE]: null,
    [AI_TYPE]: null,
    [CATEGORIES_TYPE]: null,
    [TAGS_TYPE]: null,
  });
  const [dataType, setDataType] = useState(
    CONTENT_TYPES_NAV_LIST.find((item) => item.path === path)?.type
  );
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const [hasMore, setHasMore] = useState(false);
  const [apiError, showApiError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  const { width } = useWindowSize();
  const cardsNumber =
    width > DESKTOP_BREAKPOINT
      ? DESK_DESKTOP_CARDS_NUMBER
      : DESK_TABLET_CARDS_NUMBER;

  useEffect(() => {
    if (!dataType) return;
    getHistory(PRELOADER_MAIN_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  function getHistory(preloaderType) {
    const list = data[dataType];
    const last_id = list?.[list.length - 1]?._id;
    const utc_datetime = list?.[list.length - 1]?.utc_datetime_history;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .getHistory({
        content_type: dataType,
        last_id: last_id || '',
        limit: cardsNumber,
        utc_datetime,
      })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          [dataType]:
            preloaderType === PRELOADER_MORE_TYPE
              ? prev[dataType].concat(res.data)
              : res.data,
        }));
        setHasMore(res.is_more);
      })
      .catch((err) => {
        showApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  return (
    <section className="history">
      {isEmailVerified && isAllDataProvided ? (
        <>
          <ContentTypesNav
            data={CONTENT_TYPES_NAV_LIST}
            baseLink={DESK_HISTORY_LINK}
            setDataType={setDataType}
          />

          <AnimatePresence mode="wait" initial={false}>
            <motion.div
              className="history__content"
              key={path}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: CONTENT_TRANSITION_DURATION,
                ease: BASE_TIMING_FUNCTION,
              }}
            >
              <Routes location={location} key={location.pathname}>
                <Route index element={<Navigate to={STORIES_TYPE} />} />
                <Route
                  path={STORIES_TYPE}
                  element={
                    <DeskGrid
                      noDataText={noHistory}
                      data={data[STORIES_API_TYPE]}
                      isPreloader={isPreloader}
                      hasMore={hasMore}
                      getData={getHistory}
                      apiError={apiError}
                    >
                      {data[STORIES_API_TYPE]?.map((card) => (
                        <GalleryCard
                          key={card._id}
                          card={card}
                          isSmallCards
                          isGrid
                          isFavorites
                        />
                      ))}
                    </DeskGrid>
                  }
                />
                <Route
                  path={CREATORS_TYPE}
                  element={
                    <DeskGrid
                      noDataText={noHistory}
                      data={data[CREATORS_API_TYPE]}
                      isPreloader={isPreloader}
                      hasMore={hasMore}
                      getData={getHistory}
                      apiError={apiError}
                    >
                      {data[CREATORS_API_TYPE]?.map((card) => (
                        <CreatorCard {...card} key={card._id} />
                      ))}
                    </DeskGrid>
                  }
                />
                <Route
                  path={AI_TYPE}
                  element={
                    <DeskGrid
                      noDataText={noHistory}
                      data={data[AI_TYPE]}
                      isPreloader={isPreloader}
                      hasMore={hasMore}
                      getData={getHistory}
                      apiError={apiError}
                    >
                      {data[AI_TYPE]?.map((card) => (
                        <GalleryCard
                          key={card._id}
                          card={card}
                          isSmallCards
                          isGrid
                          isAi
                          isFavorites
                        />
                      ))}
                    </DeskGrid>
                  }
                />
                <Route
                  path={CATEGORIES_TYPE}
                  element={
                    <DeskGrid
                      noDataText={noHistory}
                      data={data[CATEGORIES_TYPE]}
                      isPreloader={isPreloader}
                      hasMore={hasMore}
                      getData={getHistory}
                      apiError={apiError}
                    >
                      {data[CATEGORIES_TYPE]?.map((card) => (
                        <li className="desk-grid__item" key={card._id}></li>
                      ))}
                    </DeskGrid>
                  }
                />
                <Route
                  path={TAGS_TYPE}
                  element={
                    <DeskGrid
                      noDataText={noHistory}
                      data={data[TAGS_TYPE]}
                      isPreloader={isPreloader}
                      hasMore={hasMore}
                      getData={getHistory}
                      apiError={apiError}
                    >
                      {data[TAGS_TYPE]?.map((card) => (
                        <li className="desk-grid__item" key={card._id}></li>
                      ))}
                    </DeskGrid>
                  }
                />
              </Routes>
            </motion.div>
          </AnimatePresence>
        </>
      ) : (
        <ConfirmEmailBlock />
      )}
    </section>
  );
}

export default History;
