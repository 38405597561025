import { useContext } from 'react';
import TagsList from '../../MainPage/TagsList/TagsList';
import CatalogPageTemplate from '../CatalogPageTemplate/CatalogPageTemplate';
import './TagsPage.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { TAGS_TYPE } from '../../../assets/utils/constants';
import { DataContext } from '../../../assets/contexts/dataContext';

function TagsPage() {
  const {
    tagsPage: { noTags },
  } = useContext(TranslationContext);
  const { tags, isDataLoading } = useContext(DataContext);

  return (
    <CatalogPageTemplate isCustomPage>
      <TagsList
        noDataText={noTags}
        data={tags}
        isPreloader={isDataLoading[TAGS_TYPE]}
        showAll
      />
    </CatalogPageTemplate>
  );
}

export default TagsPage;
