import { useContext } from 'react';
import { motion } from 'framer-motion';
import AchievementTooltip from '../AchievementTooltip/AchievementTooltip';
import './Achievement.css';
import { PopupContext } from '../../../assets/contexts/popupContex';
import { ACHIEVEMENT_TOOLTIP_TYPE } from '../../../assets/utils/constants';
import { useImageLoader } from '../../../assets/hooks/useImageLoader';
import { DataContext } from '../../../assets/contexts/dataContext';

function Achievement({ image, title, description, _id }) {
  const { lang } = useContext(DataContext);
  const { handlePopupOpen, handlePopupClose } = useContext(PopupContext);
  const imageUrl = image?.urls?.orig;
  const { isImgLoading } = useImageLoader(imageUrl);

  function handleTooltipOpen() {
    handlePopupOpen(`${ACHIEVEMENT_TOOLTIP_TYPE}-${_id}`);
  }

  function handleTooltipClose() {
    handlePopupClose(`${ACHIEVEMENT_TOOLTIP_TYPE}-${_id}`);
  }

  return (
    <motion.li
      className="achievement"
      onHoverStart={handleTooltipOpen}
      onHoverEnd={handleTooltipClose}
      onMouseLeave={handleTooltipClose}
    >
      <div className="achievement__content" onClick={handleTooltipOpen}>
        <img
          className={`achievement__icon ${
            isImgLoading ? 'skeleton-loader' : ''
          }`}
          src={imageUrl}
          alt={title[lang]}
          loading="lazy"
        />
        <p className="achievement__title">- {title[lang]} -</p>
      </div>

      <AchievementTooltip {...{ image, title, description, _id }} />
    </motion.li>
  );
}

export default Achievement;
