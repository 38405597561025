import { useContext, useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import './AccountsList.css';
import AuthAccount from './AuthAccount/AuthAccount';
import { UserContext } from '../../../../assets/contexts/userContext';
import { OAUTH_GOOGLE_TYPE } from '../../../../assets/utils/constants';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import mainApi from '../../../../assets/api/MainApi';
import useParseApiError from '../../../../assets/hooks/useParseApiError';

function AccountsList() {
  const {
    desk: {
      profile: {
        accountsList: { google },
      },
    },
  } = useContext(TranslationContext);
  const {
    user: {
      reg_data: { oauth },
    },
    setUser,
  } = useContext(UserContext);
  const [isPreloader, setIsPreloader] = useState({});
  const { parseApiError } = useParseApiError();
  const [googleError, showGoogleError] = useAutoDismissError();

  const logoutWithGoogle = () => {
    setIsPreloader((prev) => ({ ...prev, [OAUTH_GOOGLE_TYPE]: true }));
    mainApi
      .oauthRemove({ type: OAUTH_GOOGLE_TYPE })
      .then(() => {
        googleLogout();
        setUser((prev) => ({
          ...prev,
          reg_data: {
            ...prev.reg_data,
            oauth: prev.reg_data.oauth.filter(
              (type) => type !== OAUTH_GOOGLE_TYPE
            ),
          },
        }));
      })
      .catch((err) => {
        showGoogleError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [OAUTH_GOOGLE_TYPE]: false }));
      });
  };

  return (
    <div className="accounts-list">
      <AuthAccount
        title={google.title}
        subtitle={google.subtitle}
        onLogout={logoutWithGoogle}
        isConnected={oauth.includes(OAUTH_GOOGLE_TYPE)}
        isPreloader={isPreloader[OAUTH_GOOGLE_TYPE]}
        type={OAUTH_GOOGLE_TYPE}
        setIsPreloader={setIsPreloader}
        showError={showGoogleError}
        error={googleError}
      />
    </div>
  );
}

export default AccountsList;
