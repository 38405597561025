import { useEffect, useState, useContext } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import './Reader.css';
import ReaderHeading from './ReaderHeading/ReaderHeading';
import ReaderControls from './ReaderControls/ReaderControls';
import ReaderContent from './ReaderContent/ReaderContent';
import CommentsDropdown from './CommentsDropdown/CommentsDropdown';
import useWindowSize from '../../assets/hooks/useWindowSize';
import {
  READER_DESCTOP_PAGE_SIZE,
  READER_TABLET_PAGE_SIZE,
  READER_MOBILE_PAGE_SIZE,
  READER_LARGE_SCREEN_PAGE_SIZE,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  AI_LINK,
  STORIES_API_TYPE,
  AI_API_TYPE,
  AUTH_POPUP_TYPE,
  AUTH_LOGIN_LINK,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
  CONFIRM_POPUP_TYPE,
  FREE_ACCESS_TYPE,
  DATA_ID_TYPE_KEY,
  PAID_ACCESS_TYPE,
  OWNER_ACCESS_TYPE,
  READ_MARK_KEY,
} from '../../assets/utils/constants';
import { UserContext } from '../../assets/contexts/userContext';
import { PopupContext } from '../../assets/contexts/popupContex';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { parseStoryHTML } from '../../assets/utils/utils';

function Reader() {
  const { story_id } = useParams();
  const { user, isUserLoading } = useContext(UserContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const { width } = useWindowSize();

  const [data, setData] = useState(null);
  const [currentFileInfo, setCurrentFileInfo] = useState(null);
  const [content, setContent] = useState([]);
  const [visibleContent, setVisibleContent] = useState(null);
  const [parsedAiData, setParsedAiData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isPreloader, setIsPreloader] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const read_mark_param = searchParams.get(READ_MARK_KEY);
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);

  const pageSize =
    width <= MOBILE_BREAKPOINT
      ? READER_MOBILE_PAGE_SIZE
      : width <= TABLET_BREAKPOINT
      ? READER_TABLET_PAGE_SIZE
      : width <= DESKTOP_BREAKPOINT
      ? READER_DESCTOP_PAGE_SIZE
      : READER_LARGE_SCREEN_PAGE_SIZE;

  useEffect(() => {
    if (!story_id || isUserLoading) return;

    if (isAiStories) {
      getAiStory();
    } else {
      getStory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isUserLoading, story_id, isAiStories]);

  useEffect(() => {
    if (
      !width ||
      isAiStories ||
      !data ||
      content?.length === 0 ||
      !read_mark_param
    )
      return;

    const totalChars = data.published_total_words;
    const totalPagesNumber = Math.ceil(totalChars / pageSize);
    const read_mark = data.possession_data?.read_mark || 0;
    const read_mark_value = Number(read_mark_param);
    const words_read_value = data.possession_data?.words_read || 0;
    const words_read = read_mark_value
      ? read_mark_value === read_mark
        ? words_read_value
        : content.reduce((acc, block, index) => {
            const integer_part = Math.floor(read_mark_value);
            const decimal_part = read_mark_value - integer_part;

            if (index < integer_part) {
              return acc + block.words_count;
            }

            if (index === integer_part) {
              return acc + block.words_count * decimal_part;
            }

            return acc;
          }, 0)
      : words_read_value;

    let page = 0;
    for (let i = pageSize; i < words_read; i += pageSize) {
      page++;
    }

    setCurrentPage(page);
    setTotalPages(totalPagesNumber);
    if (currentPage >= totalPagesNumber) setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, data, isAiStories, content, read_mark_param]);

  useEffect(() => {
    if (!content || isAiStories) return;

    const pageStartChar = currentPage * pageSize;
    const pageEndChar = pageStartChar + pageSize;

    const visibleBlocks = content
      .filter(
        (block) =>
          block.start_char < pageEndChar && block.end_char > pageStartChar
      )
      .map((item) => {
        let blocks = [...item.data.data];

        if (item.start_char < pageStartChar) {
          blocks = blocks.filter(
            (item) => item.block_start_char >= pageStartChar
          );
        }

        if (item.end_char > pageEndChar) {
          blocks = blocks.filter((item) => item.block_start_char < pageEndChar);
        }

        return { ...item, data: { ...item.data, data: blocks } };
      })
      .filter((block) => block.data.data.length > 0);

    setVisibleContent(visibleBlocks);
  }, [pageSize, content, isAiStories, currentPage]);

  useEffect(() => {
    if (!data || isAiStories || !visibleContent) return;
    const lastBlock = visibleContent[visibleContent.length - 1];

    if (lastBlock?.content_id >= currentFileInfo?.end - 10) {
      const nextFile = data.published_version.find(
        (item) => item.start === currentFileInfo.end + 1
      );

      if (nextFile) {
        const file_id = nextFile?.file_id;
        getStoryFile({ _id: data._id, file_id });
        setCurrentFileInfo(nextFile);
      }
    }
  }, [data, isAiStories, visibleContent, currentFileInfo]);

  function getStory() {
    setIsPreloader(true);
    mainApi
      .getExactStory({ key: DATA_ID_TYPE_KEY, value: story_id })
      .then((res) => {
        const possession_data = res.possession_data;
        const version = res.published_version;
        const read_mark = read_mark_param || possession_data?.read_mark || 0;
        const access_type = possession_data?.access_type;
        const currentFile = version?.find(
          (item) => item.start <= read_mark && item.end >= read_mark
        );

        setData(res);
        setCurrentFileInfo(currentFile);
        setIsLiked(user ? res.is_favourite : false);
        setIsAvailable(
          access_type === FREE_ACCESS_TYPE ||
            access_type === PAID_ACCESS_TYPE ||
            access_type === OWNER_ACCESS_TYPE ||
            !res.price
        );

        if (!read_mark_param) {
          setSearchParams((prev) => {
            prev.set(READ_MARK_KEY, read_mark);
            return prev;
          });
        }

        getStoryFile({ _id: res._id, file_id: currentFile.file_id });
      })
      .catch((err) => {
        console.log(err);
        setIsPreloader(false);
      });
  }

  function getStoryFile({ _id, file_id }) {
    mainApi
      .getStoryFile({ _id, file_id })
      .then((file) => {
        let startChar = 0;
        const blocksInfo = file.map((block) => {
          let blockStartChar = startChar;

          const textInfo = block.data.data.map((item) => {
            let blockItemStartChar = startChar;
            let blockItemEndChar = startChar + item.text.length;

            const newItem = {
              ...item,
              block_start_char: blockItemStartChar,
              block_end_char: blockItemEndChar,
            };

            blockItemStartChar = blockItemEndChar;
            return newItem;
          });

          startChar += block.words_count;

          return {
            ...block,
            data: { ...block.data, data: textInfo },
            start_char: blockStartChar,
            end_char: startChar,
          };
        });

        setContent((prev) => [...prev, ...blocksInfo]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function getAiStory() {
    setIsPreloader(true);
    mainApi
      .getExactAiStory({ _id: story_id })
      .then((res) => {
        const story = parseStoryHTML(res.data, res.data.length);
        setData(res);
        setParsedAiData(story);
        setIsLiked(user ? res.is_favourite : false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  const handlePageChange = (page) => {
    window.scrollTo({ top: 0 });
    const newPage = page - 1;
    setCurrentPage(newPage);
  };

  function handleLikeClick(evt) {
    evt.stopPropagation();
    if (!user) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
    } else if (!user?.reg_data?.stages?.is_email_verified) {
      handlePopupOpen(CONFIRM_POPUP_TYPE);
    } else if (!user?.reg_data?.stages?.is_all_auth_data_provided) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_SIGNUP_ONE_MORE_STEP_LINK);
    } else {
      const content_type = isAiStories ? AI_API_TYPE : STORIES_API_TYPE;
      mainApi
        .setLike({ _id: data._id, content_type, value: !isLiked })
        .then(() => {
          setIsLiked(!isLiked);
          setData((prev) => ({
            ...prev,
            stats: {
              ...prev.stats,
              likes: !isLiked
                ? Number(prev.stats.likes) + 1
                : Number(prev.stats.likes) - 1,
            },
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <section className="reader">
      {isPreloader ? (
        <MiniPreloader />
      ) : (
        <>
          {data && (
            <ReaderHeading
              isFirstPage={currentPage === 0}
              isLiked={isLiked}
              onLikeClick={handleLikeClick}
              {...data}
            />
          )}

          {data && (isAiStories ? parsedAiData : visibleContent) && (
            <>
              <ReaderContent
                _id={data._id}
                price={data.price}
                description={data.meta?.description}
                author_id={!isAiStories ? data.author._id : undefined}
                data={isAiStories ? parsedAiData : visibleContent}
                totalPages={totalPages}
                currentPage={currentPage + 1}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                isFirstPage={currentPage === 0}
              />

              {isAvailable && !isAiStories && totalPages > 0 && (
                <ReaderControls
                  isLiked={isLiked}
                  onLikeClick={handleLikeClick}
                  currentPage={currentPage + 1}
                  totalCount={totalPages}
                  onPageChange={handlePageChange}
                  isCommentsAvailable={data.is_published && data.is_valid}
                />
              )}

              <CommentsDropdown story_id={data._id} />
            </>
          )}
        </>
      )}
    </section>
  );
}

export default Reader;
