import { useState, useEffect, useContext } from 'react';
import './MainPage.css';
import SwiperGallery from './SwiperGallery/SwiperGallery';
import {
  AUTHORS_BLOCK_TYPE,
  BANNERS_BLOCK_TYPE,
  BOOKS_BLOCK_TYPE,
  STORIES_BLOCK_TYPE,
  CATEGORIES_BLOCK_TYPE,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
  PROMO_BLOCK_TYPE,
  TAGS_BLOCK_TYPE,
} from '../../assets/utils/constants';
import { UserContext } from '../../assets/contexts/userContext';
import TagsList from './TagsList/TagsList';
import CategoriesList from './CategoriesList/CategoriesList';
import Promo from './Promo/Promo';
import AuthorsCards from './AuthorsCards/AuthorsCards';
import BannersList from './BannersList/BannersList';
import ContinueBlock from './ContinueBlock/ContinueBlock';
import mainApi from '../../assets/api/MainApi';
import { getRandomId } from '../../assets/utils/utils';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

function MainPage() {
  const { user, isUserLoading } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [feed, setFeed] = useState(null);
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });

  useEffect(() => {
    if (isUserLoading) return;
    getMainPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isUserLoading]);

  useEffect(() => {
    if (!data) return;

    setFeed(
      !user
        ? [
            ...data.slice(0, 4),
            {
              block: {
                data_type: PROMO_BLOCK_TYPE,
                _id: getRandomId(),
              },
            },
            ...data.slice(4),
          ]
        : data
    );
  }, [data, user]);

  function getMainPage() {
    setIsPreloader((prev) => ({ ...prev, [PRELOADER_MAIN_TYPE]: true }));
    mainApi
      .getMainPage()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [PRELOADER_MAIN_TYPE]: false }));
      });
  }

  return (
    <main className="main-page">
      {isPreloader[PRELOADER_MAIN_TYPE] ? (
        <MiniPreloader />
      ) : (
        <div className="main-page__feed">
          {feed?.map((item) => {
            const {
              block: { data_type, _id, slug },
              data,
            } = item;

            if (data_type === BANNERS_BLOCK_TYPE && data.length > 0) {
              return <BannersList key={_id || slug} {...item} />;
            }

            if (
              data_type === STORIES_BLOCK_TYPE &&
              data.some((card) => !card.possession_data?.is_completed)
            ) {
              return <ContinueBlock key={_id} _id={_id} data={data} />;
            }

            if (data_type === BOOKS_BLOCK_TYPE && data.length > 0) {
              return <SwiperGallery key={_id || slug} {...item} />;
            }

            if (data_type === CATEGORIES_BLOCK_TYPE && data.length > 0) {
              return <CategoriesList key={_id || slug} {...item} />;
            }

            if (data_type === AUTHORS_BLOCK_TYPE && data.length > 0) {
              return <AuthorsCards key={_id || slug} {...item} />;
            }

            if (data_type === TAGS_BLOCK_TYPE && data.length > 0) {
              return <TagsList key={_id || slug} {...item} />;
            }

            if (data_type === PROMO_BLOCK_TYPE) {
              return <Promo key={_id} />;
            }

            return null;
          })}
        </div>
      )}
    </main>
  );
}

export default MainPage;
