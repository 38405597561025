import { useContext } from 'react';
import {
  ACHIEVEMENT_TOOLTIP_TYPE,
  MOBILE_BREAKPOINT,
} from '../../../assets/utils/constants';
import Popup from '../../Popup/Popup';
import './AchievementTooltip.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import LineDivider from '../../LineDivider/LineDivider';
import { useImageLoader } from '../../../assets/hooks/useImageLoader';
import { DataContext } from '../../../assets/contexts/dataContext';

function AchievementTooltip({ image, title, description, _id }) {
  const {
    creatorPage: { achievements },
  } = useContext(TranslationContext);
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const imageUrl = image?.urls?.orig;
  const { isImgLoading } = useImageLoader(imageUrl);

  return (
    <Popup
      popupName={`${ACHIEVEMENT_TOOLTIP_TYPE}-${_id}`}
      dropdown
      tooltip
      fitContent
    >
      <div className="achievement-tooltip">
        {width <= MOBILE_BREAKPOINT && (
          <p className="achievement-tooltip__subtitle">{achievements}</p>
        )}
        <img
          className={`achievement-tooltip__icon ${
            isImgLoading ? 'skeleton-loader' : ''
          }`}
          src={imageUrl}
          alt={title[lang]}
          loading="lazy"
        />
        <div className="achievement-tooltip__content">
          {width > MOBILE_BREAKPOINT && (
            <p className="achievement-tooltip__subtitle">{achievements}</p>
          )}
          <p className="achievement-tooltip__title">{title[lang]}</p>
          {width <= MOBILE_BREAKPOINT && (
            <div className="achievement-tooltip__divider-box">
              <LineDivider noAnimation rhombColor="var(--neutral-active)" />
            </div>
          )}
          <p
            className="achievement-tooltip__description"
            dangerouslySetInnerHTML={{ __html: description[lang] }}
          />
        </div>
      </div>
    </Popup>
  );
}

export default AchievementTooltip;
