import { useContext, useState, useEffect } from 'react';
import './AiStories.css';
import { TranslationContext } from '../../assets/contexts/translationContext';
import SwiperGallery from '../MainPage/SwiperGallery/SwiperGallery';
import { getRandomId } from '../../assets/utils/utils';
import {
  AI_PAGE_CARDS_DESKTOP_LIMIT,
  AI_PAGE_CARDS_TABLET_LIMIT,
  AI_TYPE,
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from '../../assets/utils/constants';
import useWindowSize from '../../assets/hooks/useWindowSize';
import AiBlock from './AiBlock/AiBlock';
import bg from '../../assets/images/ai/bg.webp';
import bgMobile from '../../assets/images/ai/bg-mobile.webp';
import { DataContext } from '../../assets/contexts/dataContext';
import mainApi from '../../assets/api/MainApi';
import { UserContext } from '../../assets/contexts/userContext';

function AiStories() {
  const {
    aiStories: { title, span, swiperTitle },
  } = useContext(TranslationContext);
  const { user, isUserLoading } = useContext(UserContext);
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const [stories, setStories] = useState(null);

  useEffect(() => {
    if (isUserLoading) return;

    const limit =
      width > DESKTOP_BREAKPOINT
        ? AI_PAGE_CARDS_DESKTOP_LIMIT
        : AI_PAGE_CARDS_TABLET_LIMIT;

    mainApi
      .getAllAiStories({ limit })
      .then((res) => {
        setStories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [width, isUserLoading, user]);

  return (
    <div className="ai-stories">
      <h1 className="ai-stories__title">
        <span className="ai-stories__title_type_color">{span}</span> {title}
      </h1>

      <AiBlock />

      {stories?.length > 0 && (
        <SwiperGallery
          className="ai-stories__swiper"
          data={stories}
          block={{
            _id: getRandomId(),
            slug: AI_TYPE,
            frontend_data: {
              is_get_all: true,
              is_ai: true,
              title: { [lang]: swiperTitle },
            },
          }}
          is_more={true}
        />
      )}

      <div className="ai-stories__bg-box">
        <div className="ai-stories__bg-box-wrapper">
          {width && (
            <img
              className="ai-stories__bg"
              src={width > MOBILE_BREAKPOINT ? bg : bgMobile}
              alt=""
            />
          )}
          <div className="ai-stories__circle ai-stories__circle_position_top-left" />
          <div className="ai-stories__circle ai-stories__circle_position_top-right" />
          <div className="ai-stories__circle ai-stories__circle_position_bottom" />
        </div>
      </div>
    </div>
  );
}

export default AiStories;
