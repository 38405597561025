import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Tag.css';
import {
  TAP_BTN_SCALE,
  CATALOG_PAGE_TAGS_LINK,
  TAG_TYPE,
} from '../../../../assets/utils/constants';

const TagLink = ({ data, onClose }) => {
  return (
    <Link
      to={`/${CATALOG_PAGE_TAGS_LINK}/${TAG_TYPE}/${data?.slug}`}
      className="search-dropdown-tag"
      onClick={onClose}
      state={{ disableScrollToTop: false }}
    >
      <motion.span
        className="search-dropdown-tag__text"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: TAP_BTN_SCALE }}
      >
        #{data?.slug}
      </motion.span>
    </Link>
  );
};

const TagBox = ({ data }) => {
  return (
    <div className="search-dropdown-tag">
      <span className="search-dropdown-tag__text">#{data?.slug}</span>
    </div>
  );
};

function Tag({ isLink, ...props }) {
  return isLink ? <TagLink {...props} /> : <TagBox {...props} />;
}

export default Tag;
