import { useContext, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import './SwiperGallery.css';
import {
  ArrowInCircleIcon,
  CirclesIcon,
  RhombIcon,
  RightArrowIcon,
} from '../../../assets/icons/icons';
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  CATALOG_PAGE_MAIN_LINK,
  STORIES_TYPE,
} from '../../../assets/utils/constants';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import GalleryCard from './GalleryCard/GalleryCard';
import SwiperWrapper from './SwiperWrapper/SwiperWrapper';
import gradient from '../../../assets/images/swiper/gradient.webp';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { DataContext } from '../../../assets/contexts/dataContext';

function SwiperGallery({ className, block, data, is_more }) {
  const { _id, slug, frontend_data, input_args } = block;
  const { is_get_all, is_top, is_reader, is_ai, title } = frontend_data;

  const {
    mainPage: {
      gallery: { seeAll },
    },
  } = useContext(TranslationContext);
  const { lang } = useContext(DataContext);
  const { width } = useWindowSize();
  const dotsRef = useRef(null);
  const [dotsNumber, setDotsNumber] = useState(0);
  const isSmallCards = is_top || is_ai;
  const withMoreBtn = is_get_all && is_more;
  const linkToAll = is_reader
    ? slug
    : `/${CATALOG_PAGE_MAIN_LINK}/${is_ai ? slug : `${STORIES_TYPE}/${slug}`}`;

  useEffect(() => {
    if (!is_top) return;

    const divider = width > MOBILE_BREAKPOINT ? 35 : 14;
    const containerWidth = dotsRef?.current?.clientWidth;
    const dotsNumber = containerWidth
      ? Math.floor(containerWidth / divider)
      : 0;
    setDotsNumber(dotsNumber);
  }, [dotsRef, width, is_top]);

  return (
    <section
      className={`swiper-gallery ${className ?? ''}`}
      style={{ '--dots-list-length': dotsNumber }}
    >
      {title && (
        <Link
          className={`swiper-gallery__heading ${
            is_top ? 'swiper-gallery__heading_type_top' : ''
          } ${is_reader ? 'swiper-gallery__heading_type_reader' : ''} ${
            withMoreBtn ? 'swiper-gallery__heading_type_more' : ''
          }`}
          to={withMoreBtn ? linkToAll : undefined}
          style={{
            cursor: withMoreBtn ? 'pointer' : 'default',
            pointerEvents: withMoreBtn ? 'auto' : 'none',
            userSelect: withMoreBtn ? 'auto' : 'none',
          }}
          state={{ disableScrollToTop: false }}
        >
          <h2
            className={`swiper-gallery__title ${
              is_top ? 'swiper-gallery__title_color_top' : ''
            } ${is_reader || is_ai ? 'swiper-gallery__title_size_small' : ''}`}
          >
            {title[lang].replace(/%v/, input_args ? '' : '')}
          </h2>

          {withMoreBtn && (
            <div
              className={`swiper-gallery__arrow-box ${
                is_reader || is_ai ? 'swiper-gallery__arrow-box_size_small' : ''
              }`}
            >
              <RightArrowIcon
                mainClassName={`swiper-gallery__arrow ${
                  is_reader || is_ai ? 'swiper-gallery__arrow_size_small' : ''
                }`}
                fillClassName="swiper-gallery__arrow-fill"
              />
            </div>
          )}

          {((!is_top && !is_reader) || is_ai) && (
            <div className="swiper-gallery__line">
              <RhombIcon
                mainClassName="swiper-gallery__line-icon swiper-gallery__line-icon_position_left"
                fillClassName="swiper-gallery__line-icon-fill"
              />
              <RhombIcon
                mainClassName="swiper-gallery__line-icon swiper-gallery__line-icon_position_right"
                fillClassName="swiper-gallery__line-icon-fill"
              />
            </div>
          )}

          {is_top && (
            <ul className="swiper-gallery__dots" ref={dotsRef}>
              {Array.from({ length: dotsNumber * 3 }, (_, index) => (
                <li key={index} className="swiper-gallery__dot" />
              ))}
            </ul>
          )}
        </Link>
      )}

      <SwiperWrapper
        _id={_id}
        gradient={is_reader || is_ai ? undefined : gradient}
        isReader={is_reader}
        isSmallCards={isSmallCards}
        spaceBetweenOptions={{
          default: 8,
          [DESKTOP_BREAKPOINT + 1]: isSmallCards ? 32 : 16,
          [TABLET_BREAKPOINT + 1]: isSmallCards ? 20 : 16,
          [MOBILE_BREAKPOINT + 1]: 12,
        }}
      >
        {data?.map((card, i) => (
          <SwiperSlide className="swiper-block__slide" key={card._id}>
            <GalleryCard
              card={card}
              index={i}
              isTop={is_top}
              isAi={is_ai}
              isSmallCards={isSmallCards}
            />
          </SwiperSlide>
        ))}

        {withMoreBtn && !is_reader && !is_ai && (
          <SwiperSlide className="swiper-block__slide">
            <Link
              to={linkToAll}
              className={`swiper-gallery__more-box ${
                isSmallCards ? 'swiper-gallery__more-box_size_small' : ''
              }`}
              state={{ disableScrollToTop: false }}
            >
              <CirclesIcon
                mainClassName={`swiper-gallery__more-icon swiper-gallery__more-icon_position_left ${
                  isSmallCards ? 'swiper-gallery__more-icon_size_small' : ''
                }`}
                fillClassName="swiper-gallery__more-icon-fill"
                strokeClassName="swiper-gallery__more-icon-stroke"
              />
              <ArrowInCircleIcon
                mainClassName="swiper-gallery__more-arrow"
                fillClassName="swiper-gallery__more-arrow-fill"
              />
              <p className="swiper-gallery__more-text">{seeAll}</p>
              <CirclesIcon
                mainClassName={`swiper-gallery__more-icon swiper-gallery__more-icon_position_right ${
                  isSmallCards ? 'swiper-gallery__more-icon_size_small' : ''
                }`}
                fillClassName="swiper-gallery__more-icon-fill"
                strokeClassName="swiper-gallery__more-icon-stroke"
              />
            </Link>
          </SwiperSlide>
        )}
      </SwiperWrapper>
    </section>
  );
}

export default SwiperGallery;
