import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FillBookmarkIcon, RightArrowIcon } from '../../../assets/icons/icons';
import './CreatorHeading.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  CATALOG_PAGE_CREATORS_LINK,
  TAP_BTN_SCALE,
  AUTH_POPUP_TYPE,
  AUTH_LOGIN_LINK,
  CREATORS_API_TYPE,
  AUTH_SIGNUP_ONE_MORE_STEP_LINK,
  CONFIRM_POPUP_TYPE,
  CREATOR_BG_BREAKPOINT,
} from '../../../assets/utils/constants';
import AvatarBox from '../../AvatarBox/AvatarBox';
import AuthorInfo from '../../MainPage/AuthorsCards/AuthorInfo/AuthorInfo';
import { UserContext } from '../../../assets/contexts/userContext';
import { PopupContext } from '../../../assets/contexts/popupContex';
import Achievement from '../Achievement/Achievement';
import mainApi from '../../../assets/api/MainApi';
import { useImageLoader } from '../../../assets/hooks/useImageLoader';
import bg from '../../../assets/images/creator/creator-bg.webp';
import bgMobile from '../../../assets/images/creator/creator-bg-mobile.webp';
import useWindowSize from '../../../assets/hooks/useWindowSize';

const btnHoverAnimation = { scale: 1.02 };
const btnTapAnimation = { scale: TAP_BTN_SCALE };

function CreatorHeading({
  meta,
  personal_data,
  stats,
  is_favourite,
  achievements,
  _id,
  setData,
}) {
  const { width } = useWindowSize();
  const {
    creatorPage: { backBtn, addToFavorites, removeFromFavorites },
  } = useContext(TranslationContext);
  const { handlePopupOpen } = useContext(PopupContext);
  const { user } = useContext(UserContext);
  const hasAchievements =
    achievements?.length > 0 &&
    achievements.some((achievement) => Boolean(achievement));
  const [isFavorites, setIsFavorites] = useState(user ? is_favourite : false);

  const imgUrl = width > CREATOR_BG_BREAKPOINT ? bg : bgMobile;
  const { isImgLoading } = useImageLoader(imgUrl);

  function handleFavoritesClick(evt) {
    evt.stopPropagation();
    if (!user) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_LOGIN_LINK);
    } else if (!user?.reg_data?.stages?.is_email_verified) {
      handlePopupOpen(CONFIRM_POPUP_TYPE);
    } else if (!user?.reg_data?.stages?.is_all_auth_data_provided) {
      handlePopupOpen(AUTH_POPUP_TYPE, AUTH_SIGNUP_ONE_MORE_STEP_LINK);
    } else {
      mainApi
        .setLike({ _id, content_type: CREATORS_API_TYPE, value: !isFavorites })
        .then(() => {
          setIsFavorites(!isFavorites);
          setData((prev) => ({
            ...prev,
            stats: {
              ...prev.stats,
              likes: !isFavorites
                ? Number(prev.stats.likes) + 1
                : Number(prev.stats.likes) - 1,
            },
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div
      className="creator-heading"
      style={{ marginBottom: hasAchievements ? '40px' : '0' }}
    >
      <Link
        className="creator-heading__link-btn"
        to={`/${CATALOG_PAGE_CREATORS_LINK}`}
        state={{ disableScrollToTop: false }}
      >
        <motion.div
          className="creator-heading__btn"
          whileHover={btnHoverAnimation}
          whileTap={btnTapAnimation}
        >
          <div className="creator-heading__icon-box">
            <RightArrowIcon
              mainClassName="creator-heading__back-icon"
              fillClassName="creator-heading__back-icon-fill"
            />
          </div>
          <span className="creator-heading__btn-text">{backBtn}</span>
        </motion.div>
      </Link>

      <div className="creator-heading__content">
        <AvatarBox className="creator-heading__avatar" data={personal_data} />
        <p className="creator-heading__title">{personal_data?.name}</p>
        <AuthorInfo
          stats={{
            ...stats,
            views: stats?.stories_views,
            likes: stats?.stories_likes,
          }}
          isMedium
        />
      </div>

      <motion.button
        className="creator-heading__btn creator-heading__btn_position_right"
        type="button"
        onClick={handleFavoritesClick}
        whileHover={btnHoverAnimation}
        whileTap={btnTapAnimation}
      >
        <div
          className={`creator-heading__icon-box creator-heading__icon-box_type_color ${
            isFavorites ? 'creator-heading__icon-box_active' : ''
          }`}
        >
          <FillBookmarkIcon
            mainClassName="creator-heading__bookmark-icon"
            fillClassName={`creator-heading__bookmark-icon-fill ${
              isFavorites ? 'creator-heading__bookmark-icon-fill_active' : ''
            }`}
          />
        </div>
        <AnimatePresence mode="wait" initial={false}>
          <motion.span
            className="creator-heading__btn-text creator-heading__btn-text_position_right"
            key={isFavorites ? removeFromFavorites : addToFavorites}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {isFavorites ? removeFromFavorites : addToFavorites}
          </motion.span>
        </AnimatePresence>
      </motion.button>

      {hasAchievements && (
        <ul className="creator-heading__achievements">
          {achievements?.map(
            (achievement) =>
              achievement && (
                <Achievement {...achievement} key={achievement._id} />
              )
          )}
        </ul>
      )}

      <div className="creator-heading__bg-wrapper">
        <img
          className={`creator-heading__bg ${
            isImgLoading
              ? 'skeleton-loader creator-heading__skeleton-loader'
              : ''
          }`}
          src={imgUrl}
          alt={meta?.cover?.description ?? ''}
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default CreatorHeading;
