import { useRef, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import './DeskGrid.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import {
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
} from '../../../assets/utils/constants';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

function DeskGrid({
  children,
  noDataText,
  data,
  isPreloader,
  hasMore,
  getData,
  apiError,
}) {
  const {
    labels: { loadMore },
  } = useContext(TranslationContext);
  const moreBtnRef = useRef(null);

  useEffect(() => {
    // Настраиваем Intersection Observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore) getData(PRELOADER_MORE_TYPE);
      },
      { root: null, rootMargin: '0px', threshold: 1.0 }
    );

    const moreBtnRefCurrent = moreBtnRef?.current;
    if (moreBtnRefCurrent) {
      observer.observe(moreBtnRefCurrent);
      return () => observer.unobserve(moreBtnRefCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, data]);

  return (
    <div className="desk-grid">
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          className="desk-grid__content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={
            isPreloader[PRELOADER_MAIN_TYPE]
              ? 'preloader'
              : data?.length > 0
              ? 'data'
              : 'no-data'
          }
        >
          <ErrorMessage error={apiError} isApiError />
          {isPreloader[PRELOADER_MAIN_TYPE] ? (
            <MiniPreloader />
          ) : data?.length > 0 ? (
            <ul className="desk-grid__list">{children}</ul>
          ) : (
            <p className="desk-grid__text">{noDataText}</p>
          )}
        </motion.div>
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {hasMore && (
          <motion.button
            ref={moreBtnRef}
            className="desk-grid__more-button"
            type="button"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ opacity: isPreloader[PRELOADER_MORE_TYPE] ? 1 : 0 }}
          >
            {isPreloader[PRELOADER_MORE_TYPE] ? <MiniPreloader /> : loadMore}
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
}

export default DeskGrid;
