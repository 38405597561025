import { useContext, useEffect, useState } from 'react';
import validator from 'validator';
import AuthFormWrapper from '../AuthFormWrapper/AuthFormWrapper';
import AuthInput from '../AuthInput/AuthInput';
import './RecoveryPassword.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { PopupContext } from '../../../assets/contexts/popupContex';
import useAutoDismissError from '../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../assets/hooks/useParseApiError';
import mainApi from '../../../assets/api/MainApi';
import {
  AUTH_LOGIN_LINK,
  AUTH_POPUP_TYPE,
  AUTH_RECOVERY_TYPE,
} from '../../../assets/utils/constants';

const initialValues = {
  email: '',
};

const initialValuesValidity = {
  email: { validState: false, errorMessage: '' },
};

function RecoveryPassword() {
  const {
    auth: {
      recoveryPassword: { title, buttonText, linkText, successText },
    },
    labels: { email, ok },
    errors: { invalidEmail, fieldRequired },
  } = useContext(TranslationContext);
  const { handlePopupClose } = useContext(PopupContext);
  const [values, setValues] = useState(initialValues);
  const [valuesValidity, setValuesValidity] = useState(initialValuesValidity);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [step, setStep] = useState(1);
  const [error, showError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  useEffect(() => {
    setIsFormValid(
      Object.values(valuesValidity).every(({ validState }) => validState)
    );
  }, [valuesValidity]);

  function handleChange(e) {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    if (!value) {
      setValuesValidity({
        ...valuesValidity,
        [name]: { validState: false, errorMessage: fieldRequired },
      });
    }

    if (value.length >= 2) {
      if (validator.isEmail(value)) {
        setValuesValidity({
          ...valuesValidity,
          [name]: { validState: true, errorMessage: '' },
        });
      } else {
        setValuesValidity({
          ...valuesValidity,
          [name]: {
            validState: false,
            errorMessage: !e.target.validity.valid
              ? e.target.validationMessage
              : invalidEmail,
          },
        });
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsPreloader(true);
    mainApi
      .resetPasswordNOAuth({ email: values.email })
      .then(() => {
        setStep(step + 1);
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  function closePopup() {
    handlePopupClose(AUTH_POPUP_TYPE);
  }

  return (
    <AuthFormWrapper
      title={title}
      buttonText={step === 1 ? buttonText : ok}
      linkText={step === 1 ? linkText : ''}
      id={AUTH_RECOVERY_TYPE}
      onSubmit={step === 1 ? handleSubmit : closePopup}
      link={step === 1 ? AUTH_LOGIN_LINK : undefined}
      isFormValid={step === 1 ? isFormValid : true}
      isPreloader={isPreloader}
      error={error}
      successText={successText}
      step={step}
    >
      <AuthInput
        type="email"
        label={email}
        name="email"
        inputMode="email"
        autoComplete="email"
        value={values.email}
        onChange={handleChange}
        error={valuesValidity.email}
      />
    </AuthFormWrapper>
  );
}

export default RecoveryPassword;
