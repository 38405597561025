import { useContext, useState } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import {
  DESKTOP_BREAKPOINT,
  CATALOG_DESKTOP_CARDS_NUMBER,
  MOBILE_BREAKPOINT,
  CATALOG_TABLET_CARDS_NUMBER,
  CATALOG_MOBILE_CARDS_NUMBER,
  PRELOADER_MAIN_TYPE,
  PRELOADER_MORE_TYPE,
} from '../../../assets/utils/constants';
import CatalogPageTemplate from '../CatalogPageTemplate/CatalogPageTemplate';
import './CreatorsPage.css';
import CreatorCard from './CreatorCard/CreatorCard';
import mainApi from '../../../assets/api/MainApi';
import useParseApiError from '../../../assets/hooks/useParseApiError';

function CreatorsPage() {
  const {
    creatorsPage: { title, noCreators },
  } = useContext(TranslationContext);
  const { width } = useWindowSize();
  const [data, setData] = useState(null);
  const [isPreloader, setIsPreloader] = useState({
    [PRELOADER_MAIN_TYPE]: true,
    [PRELOADER_MORE_TYPE]: false,
  });
  const [hasMore, setHasMore] = useState(false);
  const [apiError, setApiError] = useState(undefined);
  const { parseApiError } = useParseApiError();

  function getCreators(preloaderType) {
    const last_id = data?.[data.length - 1]?._id;
    const limit =
      width > DESKTOP_BREAKPOINT
        ? CATALOG_DESKTOP_CARDS_NUMBER
        : width > MOBILE_BREAKPOINT
        ? CATALOG_TABLET_CARDS_NUMBER
        : CATALOG_MOBILE_CARDS_NUMBER;

    setIsPreloader((prev) => ({ ...prev, [preloaderType]: true }));
    mainApi
      .getAllCreators({ limit, last_id })
      .then((res) => {
        setData((prev) =>
          preloaderType === PRELOADER_MORE_TYPE
            ? prev.concat(res.data)
            : res.data
        );
        setHasMore(res.is_more);
      })
      .catch((err) => {
        setApiError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloader((prev) => ({ ...prev, [preloaderType]: false }));
      });
  }

  return (
    <CatalogPageTemplate
      title={title}
      data={data}
      getData={getCreators}
      noDataText={noCreators}
      isPreloader={isPreloader}
      hasMore={hasMore}
      apiError={apiError}
    >
      {data?.map((card) => (
        <CreatorCard {...card} key={card._id} />
      ))}
    </CatalogPageTemplate>
  );
}

export default CreatorsPage;
