import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './AnimatedAuthorCard.css';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import AvatarBox from '../../../AvatarBox/AvatarBox';
import { CREATOR_LINK } from '../../../../assets/utils/constants';
import AuthorInfo from '../AuthorInfo/AuthorInfo';

const cardVariants = {
  hidden: { width: '144px' },
  show: {
    width: '100%',
    transition: { when: 'beforeChildren' },
  },
};

function AnimatedAuthorCard({ card, activeCard, i, length, handleCardClick }) {
  const {
    mainPage: {
      authorsCards: { stories },
    },
  } = useContext(TranslationContext);
  const isLastCard = i === length - 1;

  const textBlockVariants = {
    hidden: { opacity: 0, width: '0' },
    show: {
      opacity: 1,
      width: isLastCard ? 'calc(100% + 72px)' : 'calc(100% + 144px)',
    },
  };

  return (
    <motion.li
      className="animated-author-card"
      variants={cardVariants}
      initial={activeCard === i ? 'show' : 'hidden'}
      animate={activeCard === i ? 'show' : 'hidden'}
    >
      <Link
        to={`/${CREATOR_LINK}/${card?._id}`}
        className="animated-author-card__link"
        onClick={(e) => activeCard !== i && e.preventDefault()}
        state={{ disableScrollToTop: false }}
      >
        <button
          className="animated-author-card__btn"
          type="button"
          onClick={() => handleCardClick(i)}
          style={{ zIndex: length - i }}
        >
          <AvatarBox data={card?.personal_data} />
        </button>

        <AnimatePresence initial={false}>
          {activeCard === i && (
            <motion.div
              className="animated-author-card__text-block"
              variants={textBlockVariants}
            >
              <div
                className={`animated-author-card__text-box ${
                  isLastCard ? 'animated-author-card__text-box_type_last' : ''
                }`}
              >
                <p className="animated-author-card__title">
                  {card?.personal_data?.name}
                </p>
                <p className="animated-author-card__subtitle">
                  {stories(Number(card?.stats?.published_stories))}
                </p>
                <AuthorInfo
                  stats={{
                    ...card?.stats,
                    views: card?.stats?.stories_views,
                    likes: card?.stats?.stories_likes,
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </Link>
    </motion.li>
  );
}

export default AnimatedAuthorCard;
