import LineDivider from '../../../LineDivider/LineDivider';
import './AiContentBlock.css';

function AiContentBlock({ data }) {
  return (
    <div className="ai-content-block">
      {data.map((element) => {
        const renderContent = (content) => {
          if (typeof content === 'string') {
            return <span>{content}</span>;
          }

          return content.map((child, index) => {
            switch (child.type) {
              case 'text':
                return renderContent(child.content);
              case 'em':
                return (
                  <em key={index}>
                    {child.children.map((c) => renderContent(c.content))}
                  </em>
                );
              case 'b':
                return (
                  <b key={index}>
                    {child.children.map((c) => renderContent(c.content))}
                  </b>
                );
              default:
                return null;
            }
          });
        };

        switch (element.type) {
          case 'h2':
            return (
              <>
                <div className="ai-content-block__line">
                  <LineDivider noCenterRhomb noAnimation />
                </div>
                <h2
                  className="ai-content-block__title text-element-title text-element-title_size_l"
                  key={element.key}
                >
                  {element.children.map((child) =>
                    renderContent(child.content)
                  )}
                </h2>
              </>
            );

          case 'h3':
            return (
              <h3
                className="ai-content-block__title text-element-title text-element-title_size_m"
                key={element.key}
              >
                {element.children.map((child) => renderContent(child.content))}
              </h3>
            );

          case 'h4':
            return (
              <h4
                className="ai-content-block__title text-element-title text-element-title_size_s"
                key={element.key}
              >
                {element.children.map((child) => renderContent(child.content))}
              </h4>
            );

          case 'p':
            return (
              <p
                className="ai-content-block__text text-element-text"
                key={element.key}
              >
                {element.children.map((child) => {
                  if (child.type === 'text') {
                    return renderContent(child.content);
                  }
                  return renderContent([child]);
                })}
              </p>
            );

          default:
            return null;
        }
      })}
    </div>
  );
}

export default AiContentBlock;
