import { useEffect, useContext } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Paywall from '../Paywall/Paywall';
import ContentBlock from './ContentBlock/ContentBlock';
import MoreStories from '../MoreStories/MoreStories';
import './ReaderContent.css';
import {
  PARAGRAPH_BLOCK,
  AI_LINK,
  READ_MARK_KEY,
} from '../../../assets/utils/constants';
import AiContentBlock from './AiContentBlock/AiContentBlock';
import mainApi from '../../../assets/api/MainApi';
import { UserContext } from '../../../assets/contexts/userContext';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function ReaderContent({
  _id,
  author_id,
  price,
  description,
  data,
  totalPages,
  currentPage,
  isAvailable,
  setIsAvailable,
}) {
  const {
    reader: { introduction },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const [, setSearchParams] = useSearchParams();
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname.includes(AI_LINK) || state?.background?.pathname.includes(AI_LINK);

  useEffect(() => {
    if (isAiStories) return;

    const start_block = data?.[0];
    const start_block_items = start_block?.data?.data;
    const block_words_count =
      start_block_items?.[start_block_items.length - 1]?.block_end_char -
      start_block_items?.[0]?.block_start_char;
    const read_mark_decimal = 1 - block_words_count / start_block?.words_count;
    const read_mark = Number(`${start_block?.content_id}.${read_mark_decimal}`);

    if (read_mark) {
      setSearchParams((prev) => {
        prev.set(READ_MARK_KEY, read_mark);
        return prev;
      });
    }

    if (!user || !read_mark) return;

    mainApi
      .setReadMark({
        _id,
        value: read_mark,
        is_completed: currentPage === totalPages,
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user]);

  return (
    <div className="reader-content">
      {description && currentPage === 1 && (
        <div className="reader-content__description content-block">
          <h2 className="reader-content__description-title text-element-title text-element-title_size_l">
            {introduction}
          </h2>
          <p className="reader-content__description-text text-element-text">
            {description}
          </p>
        </div>
      )}

      {isAiStories ? (
        <AiContentBlock data={data.elements} />
      ) : (
        data?.map((item, i) => {
          if (!isAvailable && price !== null && i === 0)
            return (
              <Paywall
                _id={_id}
                price={price}
                totalPages={totalPages}
                text={
                  item?.data?.find((item) => item.type === PARAGRAPH_BLOCK)
                    ?.children[0]?.text
                }
                setIsAvailable={setIsAvailable}
                key={item?._id}
              />
            );

          if (!isAvailable && price !== null && i > 0) return null;

          return (
            <ContentBlock
              key={item?._id}
              elem={item?.data}
              isFirstBlock={i === 0}
            />
          );
        })
      )}

      {currentPage === totalPages && !isAiStories && (
        <MoreStories _id={_id} author_id={author_id} />
      )}
    </div>
  );
}

export default ReaderContent;
