import { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { EyeIcon, FeatherIcon, RhombIcon } from '../../assets/icons/icons';
import './Footer.css';
import {
  FOOTER_CATEGORIES_ROW_COUNT,
  FOOTER_NAV_LIST,
  SOCIAL_LINKS,
  TAP_BTN_SCALE,
  USER_CREATOR_TYPE,
  AI_LINK,
  CATALOG_PAGE_CATEGORIES_LINK,
  CATEGORY_TYPE,
  CATEGORIES_TYPE,
  CATALOG_PAGE_MAIN_LINK,
} from '../../assets/utils/constants';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { TranslationContext } from '../../assets/contexts/translationContext';
import { UserContext } from '../../assets/contexts/userContext';
import { DataContext } from '../../assets/contexts/dataContext';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

const btnHoverAnimation = {
  opacity: 0.8,
};

const btnTapAnimation = {
  scale: TAP_BTN_SCALE,
};

function Footer() {
  const {
    services: { becomeCreator, copyright },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { lang, categories, isDataLoading } = useContext(DataContext);
  const { width } = useWindowSize();
  const categoriesRef = useRef(null);
  const [data, setData] = useState([]);
  const { pathname, state } = useLocation();
  const isAiStories =
    pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK) ||
    state?.background?.pathname
      .replace('/', '')
      .replace(`${CATALOG_PAGE_MAIN_LINK}/`, '')
      .startsWith(AI_LINK);
  const shouldShowBecomeCreatorBtn =
    !user || user?.reg_data?.role?.name !== USER_CREATOR_TYPE;

  useEffect(() => {
    if (!categories) return;

    const divider = width <= 740 ? 150 : width <= 1140 ? 180 : 220;
    const containerWidth = categoriesRef?.current?.clientWidth;
    const columns = containerWidth ? Math.floor(containerWidth / divider) : 0;
    setData(categories.slice(0, columns * FOOTER_CATEGORIES_ROW_COUNT));
  }, [width, categories]);

  return (
    <footer
      className="footer"
      style={{ '--category-rows': FOOTER_CATEGORIES_ROW_COUNT }}
    >
      <div className="footer__container">
        <div className="footer__content">
          <ul className="footer__links-list">
            <li className="footer__link-item">
              <Link
                className="footer__logo-link"
                to="/"
                state={{ disableScrollToTop: false }}
              >
                <motion.div
                  className="footer__icon-link footer__icon-link_inverted"
                  whileHover={btnHoverAnimation}
                  whileTap={btnTapAnimation}
                >
                  <EyeIcon
                    mainClassName="footer__logo-icon"
                    fillClassName="footer__logo-icon-fill"
                  />
                </motion.div>
              </Link>
            </li>

            {FOOTER_NAV_LIST.map((item) => (
              <li className="footer__link-item" key={item.label[lang]}>
                <Link
                  className="footer__link"
                  to={item.path}
                  state={{ disableScrollToTop: false }}
                >
                  <motion.div
                    className="footer__btn"
                    whileHover={btnHoverAnimation}
                    whileTap={btnTapAnimation}
                  >
                    {item.label[lang]}
                  </motion.div>
                </Link>
              </li>
            ))}

            {shouldShowBecomeCreatorBtn && (
              <li className="footer__link-item footer__link-item_type_desktop">
                <motion.button
                  className="footer__btn footer__btn_inverted"
                  type="button"
                  whileHover={btnHoverAnimation}
                  whileTap={btnTapAnimation}
                >
                  <FeatherIcon
                    mainClassName="footer__author-icon"
                    fillClassName="footer__author-icon-fill"
                  />
                  {becomeCreator}
                </motion.button>
              </li>
            )}
          </ul>

          <div className="footer__content-block">
            <p className="footer__copyright">{copyright}</p>
            <ul className="footer__links-list footer__links-list_type_social">
              {SOCIAL_LINKS.map((item) => (
                <li className="footer__link-item" key={item.label}>
                  <motion.a
                    className="footer__icon-link"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    whileHover={btnHoverAnimation}
                    whileTap={btnTapAnimation}
                  >
                    {item.icon({
                      mainClassName: 'footer__link-icon',
                      backgroundClassName: `footer__link-icon-background ${
                        isAiStories
                          ? 'footer__link-icon-background_theme_dark'
                          : ''
                      }`,
                      fillClassName: `footer__link-icon-fill ${
                        isAiStories ? 'footer__link-icon-fill_theme_dark' : ''
                      }`,
                      strokeClassName: `footer__link-icon-stroke ${
                        isAiStories ? 'footer__link-icon-stroke_theme_dark' : ''
                      }`,
                    })}
                  </motion.a>
                </li>
              ))}
              {shouldShowBecomeCreatorBtn && (
                <li className="footer__link-item footer__link-item_type_mobile">
                  <motion.button
                    className="footer__btn footer__btn_inverted"
                    type="button"
                    whileHover={btnHoverAnimation}
                    whileTap={btnTapAnimation}
                  >
                    <FeatherIcon
                      mainClassName="footer__author-icon"
                      fillClassName="footer__author-icon-fill"
                    />
                    {becomeCreator}
                  </motion.button>
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className="footer__divider">
          <RhombIcon
            mainClassName="footer__divider-icon footer__divider-icon_position_left"
            fillClassName="footer__divider-icon-fill"
          />
          <RhombIcon
            mainClassName="footer__divider-icon footer__divider-icon_position_right"
            fillClassName="footer__divider-icon-fill"
          />
        </div>

        <div className="footer__categories-box" ref={categoriesRef}>
          {isDataLoading[CATEGORIES_TYPE] ? (
            <MiniPreloader />
          ) : (
            <ul className="footer__categories-list">
              {data?.map((item) => (
                <li className="footer__category-item" key={item._id}>
                  <Link
                    className="footer__category-link"
                    to={`/${CATALOG_PAGE_CATEGORIES_LINK}/${CATEGORY_TYPE}/${item.slug}`}
                    state={{ disableScrollToTop: false }}
                  >
                    <motion.p
                      className="footer__category-text"
                      whileHover={btnHoverAnimation}
                      whileTap={btnTapAnimation}
                    >
                      {item.title?.[lang]}
                    </motion.p>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
