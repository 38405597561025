import { useContext, useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { motion, AnimatePresence } from 'framer-motion';
import AvatarBox from '../../../AvatarBox/AvatarBox';
import './CommentItem.css';
import { TranslationContext } from '../../../../assets/contexts/translationContext';
import {
  BASE_TIMING_FUNCTION,
  COMMENT_FORM_POPUP_TYPE,
  CONFIRM_POPUP_TYPE,
  MOBILE_BREAKPOINT,
  NEW_COMMENT_ANIMATION_DURATION,
  TAP_BTN_SCALE,
} from '../../../../assets/utils/constants';
import CommentsList from '../CommentsList/CommentsList';
import CommentForm from '../CommentForm/CommentForm';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { PopupContext } from '../../../../assets/contexts/popupContex';
import CommentFormPopup from '../CommentFormPopup/CommentFormPopup';
import { UserContext } from '../../../../assets/contexts/userContext';
import ConfirmPopup from '../../../ConfirmPopup/ConfirmPopup';
import mainApi from '../../../../assets/api/MainApi';
import useAutoDismissError from '../../../../assets/hooks/useAutoDismissError';
import useParseApiError from '../../../../assets/hooks/useParseApiError';

const contentVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const newCommentVariants = {
  initial: { backgroundColor: 'var(--comments-color-primary)' },
  animate: {
    backgroundColor: 'var(--website-background-theme)',
    transition: {
      duration: NEW_COMMENT_ANIMATION_DURATION,
      ease: BASE_TIMING_FUNCTION,
    },
  },
};

function CommentItem({
  story_id,
  _id,
  author,
  text,
  child_comments,
  child_reply_to,
  parent_reply_to,
  utc_datetime,
  comments,
  nested,
  setComments,
  branchId,
  scrollToComment,
  isCommentFormPopup = false,
  isInputOpen,
  handleInputOpen,
  handleInputClose,
  getData,
  isPreloader,
  newCommentIds,
  setNewCommentIds,
}) {
  const {
    reader: {
      comments: {
        reply,
        cancel,
        edit,
        remove,
        confirmPopupTitle,
        deletedComment,
      },
    },
  } = useContext(TranslationContext);
  const { user } = useContext(UserContext);
  const { handlePopupOpen, handlePopupClose } = useContext(PopupContext);
  const { width } = useWindowSize();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeletePreloader, setIsDeletePreloader] = useState(false);
  const [deleteError, showDeleteError] = useAutoDismissError();
  const { parseApiError } = useParseApiError();

  const reply_to = comments
    .find((comment) => comment._id === parent_reply_to)
    ?.child_comments?.data?.find((comment) => comment._id === child_reply_to)
    ?.author?.personal_data?.name;

  const date_now = moment();
  const comment_date = moment(utc_datetime);
  const isEditable = utc_datetime
    ? date_now.diff(comment_date, 'days') <= 3
    : true;

  const isNewComment = newCommentIds.includes(_id);
  const commentRef = useRef(null);
  const [isCommentInView, setIsCommentInView] = useState(false);

  useEffect(() => {
    const currentRef = commentRef.current;

    if (isNewComment && currentRef) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsCommentInView(true);
            setTimeout(() => {
              setNewCommentIds((prev) =>
                prev.filter((commentId) => commentId !== _id)
              );
            }, (NEW_COMMENT_ANIMATION_DURATION + 0.5) * 1000);
            observer.unobserve(currentRef);
          }
        },
        { threshold: 0.5 }
      );

      observer.observe(currentRef);
      return () => observer.unobserve(currentRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewComment]);

  function openInput() {
    handleInputOpen(_id);
  }

  function closeInput() {
    handleInputClose(_id);
    setTimeout(() => setIsEditMode(false), 200);
  }

  function openFormPopup() {
    handlePopupOpen(`${COMMENT_FORM_POPUP_TYPE}-${_id}`);
  }

  function closeFormPopup() {
    handlePopupClose(`${COMMENT_FORM_POPUP_TYPE}-${_id}`);
    setTimeout(() => setIsEditMode(false), 200);
  }

  function openConfirmPopup() {
    handlePopupOpen(`${CONFIRM_POPUP_TYPE}-${_id}`);
  }

  function handleDeleteComment() {
    setIsDeletePreloader(true);
    mainApi
      .deleteComment({ _id })
      .then(() => {
        setComments((prevComments) =>
          prevComments.map((comment) => {
            if (comment._id === _id) {
              return { ...comment, text: null };
            } else if (comment.child_comments?.data?.length > 0) {
              return {
                ...comment,
                child_comments: {
                  ...comment.child_comments,
                  data: comment.child_comments.data.map((child) => {
                    if (child._id === _id) {
                      return { ...child, text: null };
                    }
                    return child;
                  }),
                },
              };
            }
            return comment;
          })
        );
        handlePopupClose(`${CONFIRM_POPUP_TYPE}-${_id}`);
      })
      .catch((err) => {
        showDeleteError(parseApiError(err));
      })
      .finally(() => {
        setIsDeletePreloader(false);
      });
  }

  return (
    <>
      <motion.li
        className={`comment ${nested ? 'comment_nested' : ''} ${
          isCommentFormPopup ? 'comment_type_popup' : ''
        }`}
        id={_id}
        variants={isNewComment ? newCommentVariants : {}}
        initial="initial"
        animate={isCommentInView && isNewComment ? 'animate' : 'initial'}
      >
        <div
          className={`comment__wrapper ${
            nested ? 'comment__wrapper_nested' : ''
          }`}
        >
          <div className="comment__avatar-box">
            <AvatarBox
              className={`comment__avatar ${
                nested ? 'comment__avatar_size_small' : ''
              }`}
              data={author?.personal_data}
            />

            {(isCommentFormPopup ||
              (!isCommentFormPopup && isInputOpen[_id])) && (
              <>
                <motion.div
                  className={`comment__dot ${
                    nested ? 'comment__dot_size_small' : ''
                  } ${isCommentFormPopup ? 'comment__dot_type_popup' : ''}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                />
                <motion.div
                  className={`comment__dot-line ${
                    isCommentFormPopup ? 'comment__dot-line_type_popup' : ''
                  }`}
                  initial={{
                    height: isCommentFormPopup ? 'calc(100% - 30px)' : 0,
                  }}
                  animate={{
                    height: isCommentFormPopup
                      ? 'calc(100% - 30px)'
                      : 'calc(100% - 50px)',
                  }}
                  transition={{ duration: 2 }}
                />
              </>
            )}
          </div>

          <motion.div
            className="comment__content"
            initial="initial"
            animate="animate"
            exit="initial"
          >
            <div className="comment__heading" ref={commentRef}>
              <p className="comment__title">{author?.personal_data?.name}</p>
              <p className="comment__date">{moment(utc_datetime).fromNow()}</p>
            </div>

            <p
              className={`comment__text ${
                !text ? 'comment__text_type_deleted' : ''
              }`}
            >
              <span className="comment__text_type_color">
                {child_reply_to ? `@${reply_to} ` : ''}
              </span>
              {text ?? deletedComment}
            </p>

            {isCommentFormPopup ? (
              <div className="comment__btns">
                <motion.button
                  className={`comment__button ${
                    nested ? 'comment__button_size_small' : ''
                  }`}
                  type="button"
                  onClick={closeFormPopup}
                  style={{ color: 'var(--second-active-primary)' }}
                  whileTap={{ scale: TAP_BTN_SCALE }}
                >
                  {cancel}
                </motion.button>
              </div>
            ) : (
              <AnimatePresence mode="wait">
                {isInputOpen[_id] ? (
                  <motion.div
                    className="comment__form"
                    variants={contentVariants}
                  >
                    {width > MOBILE_BREAKPOINT && (
                      <CommentForm
                        story_id={story_id}
                        comment_id={_id}
                        placeholder={reply}
                        nested={true}
                        level={nested ? 2 : 1}
                        author={author}
                        onClose={closeInput}
                        setComments={setComments}
                        branchId={branchId}
                        scrollToComment={scrollToComment}
                        isInputOpen={isInputOpen}
                        text={text}
                        isEditMode={isEditMode}
                        setNewCommentIds={setNewCommentIds}
                      />
                    )}
                    <div className="comment__border" />
                  </motion.div>
                ) : (
                  <motion.div
                    className={`comment__btns ${
                      nested ? 'comment__btns_size_small' : ''
                    } ${text === null ? 'comment__btns_hidden' : ''}`}
                    variants={contentVariants}
                  >
                    <motion.button
                      className={`comment__button ${
                        nested ? 'comment__button_size_small' : ''
                      }`}
                      type="button"
                      onClick={
                        width > MOBILE_BREAKPOINT ? openInput : openFormPopup
                      }
                      initial={{
                        backgroundColor: isNewComment
                          ? 'transparent'
                          : 'var(--website-background-theme)',
                        color: 'var(--tag-color-secondary)',
                      }}
                      whileHover={{
                        backgroundColor: 'var(--comments-color-primary)',
                        color: 'var(--second-active-primary)',
                      }}
                      whileTap={{ scale: TAP_BTN_SCALE }}
                      style={{
                        pointerEvents: text === null ? 'none' : 'auto',
                        userSelect: text === null ? 'none' : 'auto',
                      }}
                      disabled={text === null}
                    >
                      {reply}
                    </motion.button>

                    {user?._id === author?._id && isEditable && (
                      <>
                        <motion.button
                          className={`comment__button ${
                            nested ? 'comment__button_size_small' : ''
                          }`}
                          type="button"
                          onClick={() => {
                            setIsEditMode(true);
                            width > MOBILE_BREAKPOINT
                              ? openInput()
                              : openFormPopup();
                          }}
                          initial={{
                            backgroundColor: isNewComment
                              ? 'transparent'
                              : 'var(--website-background-theme)',
                            color: 'var(--tag-color-secondary)',
                          }}
                          whileHover={{
                            backgroundColor: 'var(--comments-color-primary)',
                            color: 'var(--second-active-primary)',
                          }}
                          whileTap={{ scale: TAP_BTN_SCALE }}
                          style={{
                            pointerEvents: text === null ? 'none' : 'auto',
                            userSelect: text === null ? 'none' : 'auto',
                          }}
                          disabled={text === null}
                        >
                          {edit}
                        </motion.button>

                        <motion.button
                          className={`comment__button comment__button_type_remove ${
                            nested ? 'comment__button_size_small' : ''
                          }`}
                          type="button"
                          onClick={openConfirmPopup}
                          initial={{
                            backgroundColor: isNewComment
                              ? 'transparent'
                              : 'var(--website-background-theme)',
                            color: 'var(--main-active-secondary)',
                          }}
                          whileHover={{
                            backgroundColor: 'var(--main-active-tertiary)',
                            color: 'var(--main-active-primary)',
                          }}
                          whileTap={{ scale: TAP_BTN_SCALE }}
                          style={{
                            pointerEvents: text === null ? 'none' : 'auto',
                            userSelect: text === null ? 'none' : 'auto',
                          }}
                          disabled={text === null}
                        >
                          {remove}
                        </motion.button>
                      </>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </motion.div>
        </div>

        {child_comments?.data?.length > 0 && (
          <CommentsList
            story_id={story_id}
            data={child_comments.data}
            nested={true}
            comments={comments}
            setComments={setComments}
            branchId={branchId}
            scrollToComment={scrollToComment}
            isInputOpen={isInputOpen}
            handleInputOpen={handleInputOpen}
            handleInputClose={handleInputClose}
            getData={getData}
            hasMore={child_comments.is_more}
            isPreloader={isPreloader}
            newCommentIds={newCommentIds}
            setNewCommentIds={setNewCommentIds}
          />
        )}
      </motion.li>

      {!isCommentFormPopup && (
        <CommentFormPopup
          story_id={story_id}
          comment_id={_id}
          utc_datetime={utc_datetime}
          nested={nested}
          level={nested ? 2 : 1}
          author={author}
          comments={comments}
          onClose={closeFormPopup}
          setComments={setComments}
          branchId={branchId}
          scrollToComment={scrollToComment}
          text={text}
          isEditMode={isEditMode}
          newCommentIds={newCommentIds}
          setNewCommentIds={setNewCommentIds}
        />
      )}

      <ConfirmPopup
        _id={_id}
        title={confirmPopupTitle}
        onConfirm={handleDeleteComment}
        error={deleteError}
        isPreloader={isDeletePreloader}
      />
    </>
  );
}

export default CommentItem;
