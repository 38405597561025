import { useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './ProfileFormButtons.css';
import { TranslationContext } from '../../../../../assets/contexts/translationContext';
import AuthButton from '../../../../Auth/AuthButton/AuthButton';
import { CloseIcon } from '../../../../../assets/icons/icons';
import { TAP_BTN_SCALE } from '../../../../../assets/utils/constants';

function ProfileFormButtons({
  name,
  isEditMode,
  isPreloader,
  isDisabled,
  onSubmit,
  handleOpenEditMode,
  handleCancelEditMode,
  isNotAllowEdit,
}) {
  const {
    labels: { save, edit },
  } = useContext(TranslationContext);

  return (
    <div className="profile-form-buttons">
      <AnimatePresence mode="popLayout" initial={false}>
        {name !== 'avatar' && (
          <motion.div
            className="profile-form-buttons__btn-box"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key={isEditMode ? 'save' : 'edit'}
          >
            {isEditMode ? (
              <AuthButton
                label={save}
                isPreloader={isPreloader}
                isDisabled={isDisabled}
                onClick={(e) => onSubmit(e, name)}
                formId="profile"
                isTypeSubmit
                isMobileSmall
              />
            ) : (
              <AuthButton
                label={edit}
                isDisabled={isNotAllowEdit}
                onClick={() => handleOpenEditMode(name)}
                isMobileSmall
              />
            )}
          </motion.div>
        )}

        {name === 'avatar' && isEditMode && (
          <motion.div
            className="profile-form-buttons__btn-box"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="avatar"
          >
            <AuthButton
              label={save}
              isPreloader={isPreloader}
              isDisabled={isDisabled}
              onClick={(e) => onSubmit(e, name)}
              formId="profile"
              isTypeSubmit
              isMobileSmall
            />
          </motion.div>
        )}

        {isEditMode && (
          <motion.button
            className="profile-form-buttons__close-btn"
            type="button"
            onClick={() => handleCancelEditMode(name)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: TAP_BTN_SCALE }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="close"
          >
            <CloseIcon
              mainClassName="profile-form-buttons__close-icon"
              fillClassName="profile-form-buttons__close-icon-fill"
            />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ProfileFormButtons;
