import { useContext } from 'react';
import CatalogPageTemplate from '../CatalogPageTemplate/CatalogPageTemplate';
import './CategoriesPage.css';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { CATEGORIES_TYPE } from '../../../assets/utils/constants';
import CategoriesList from '../../MainPage/CategoriesList/CategoriesList';
import { DataContext } from '../../../assets/contexts/dataContext';

function CategoriesPage() {
  const {
    categoriesPage: { title, noCategories },
  } = useContext(TranslationContext);
  const { topCategories, isDataLoading, lang } = useContext(DataContext);

  return (
    <CatalogPageTemplate isCustomPage>
      <CategoriesList
        block={{ frontend_data: { title: { [lang]: title } } }}
        noDataText={noCategories}
        data={topCategories}
        isPreloader={isDataLoading[CATEGORIES_TYPE]}
        showAll
      />
    </CatalogPageTemplate>
  );
}

export default CategoriesPage;
